import axios from "axios";
import * as Constants from '../../constants';

class NomenclatureSerice {    

    static async getNomenclatureById(id, token) {
        try {
            const response = await axios.get(`${Constants.NOMENCLATURES_GET_NOMENCLATURE_BY_ID + id}`,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async addEditNomenclature(id, data, token) {
        try {
            const url = id ? `${Constants.NOMENCLATURES_UPDATE_URL + id}` : Constants.NOMENCLATURES_ADD_URL; 
            const headers = {
                headers: {Authorization: `Bearer ${token}`}
            };
            const response = id ? await axios.put(`${url}`, data, headers) : await axios.post(`${url}`, data, headers);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async deleteNomenclature(id, token) {
        try {
            const response = await axios.delete(`${Constants.NOMENCLATURES_DELETE_URL + id}`,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}

export default NomenclatureSerice;
