import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import $ from 'jquery';
import isInt from 'validator/es/lib/isInt';
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/dist/themes/base/jquery-ui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5';
import {useNavigate, useLocation} from "react-router-dom";
import CustomerSerice from '../../../service/CustomerService';
import Utils from '../../../utils/Utils';
import * as Constants from '../../../../constants';
import TabsComponent from './TabsComponent';
import UserService from '../../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faInstitution, faPencil, faPlus, faSitemap, faStickyNote, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useGenericMessages } from '../../../../contexts/messages/genericMessageContext';
import { isEmpty } from 'validator';

function CustomersPage() {
	const { addGenericMessage, clearGenericMessages } = useGenericMessages();
	const navigate = useNavigate();
	const location = useLocation();
	const [filterData, setFilterData] = useState({
		filterId: '',
		filterName: '',
		filterCustomerTypes: [],
		filterCustomerType: '',
		filterBusinessCategories: [],
		filterBusinessCategory: '',
		filterStatus: '',
		filterStatusList: [],
		filterSupplierTypesList: [],
		filterSupplierType: '',
		filterSalesMethods: [],
		filterSalesMethod: '',
		filterPaymentMethods: [],
		filterPaymentMethod: '',
		filterContactPerson: '',
		filterCountries: [],
		filterCountry: ''		
	});
	const ORDER_COLUMN = 0;
	const tabId = 0;
	const filterNS = 'customers';
	const filterRetailsNS = 'retails';

	useEffect(() => {		
		fetchFilterData();	
		
		const dataUrl = document.getElementById('headerLinkCustomers')?.dataset.url;
		if (Constants.CUSTOMERS_PAGE_URL !== dataUrl) {
			navigate(dataUrl);
			return;			
		}

		Utils.setActiveNav('headerLinkCustomers');				
		$( "#customersTabs" ).tabs({
			active: 0
		});		
		
		const dataTable = initDataTable();
		
		return () => {
			try {
				dataTable.destroy();	
			} catch (error) {}			
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps		
	}, [navigate]);
		
	const fetchFilterData = async () => {
		try {
			const response = await CustomerSerice.getCustomersFilters(await Utils.getToken(), filterNS);
			if (response?.statusCode === 200) {
				setFilterData(response);
			}
		} catch (error) {
		  console.error('Error fetching filter data:', error);
			if (Utils.isNetworkError(error)) {
				addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
				UserService.logout();
				navigate(Constants.LOGIN_PAGE_URL);
				return;
			}
			UserService.handleSessionTimeOutError(error);
		}
	};

	const initDataTable = async () => {
		const token = await Utils.getToken();
		try {			
			if ($.fn.DataTable.isDataTable("#datatable")) {
				$('#datatable').DataTable().clear().destroy();
			}
			$.fn.dataTable.ext.errMode = 'throw';
			const dataTable = $('#datatable').DataTable({
				serverSide : true,
				processing : true,
				searching: false,
				autoWidth: false,
				order: [[ Utils.getDataTableValue(filterNS, 'orderColumn') ?? ORDER_COLUMN, Utils.getDataTableValue(filterNS, 'order') ??  Constants.DATATABLE_ORDER_ASC ]],
				lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
				displayStart: Utils.getDataTableValue(filterNS, 'start') ?? Constants.DATATABLE_START, 
	    		pageLength: Utils.getDataTableValue(filterNS, 'pageLength') ?? Constants.DATATABLE_PAGE_LENGTH,
				ajax : {
					url : Constants.CUSTOMERS_LIST_URL,
					type : 'POST',
					beforeSend: function(xhr) {
						xhr.setRequestHeader("Authorization", "Bearer " + token);
						$('#tabs-' + tabId).css('display','block');
					},
					statusCode: {
						403: function() { 	
							UserService.sessionTimeOut();							
						}					
					},
					error: function(XMLHttpRequest, textStatus, errorThrown) {
						console.log(textStatus);
					},
					dataSrc: function ( json ) {
						Utils.setDataTableValue(filterNS, 'start', json.start);							
						Utils.setDataTableValue(filterNS, 'pageLength', json.pageLength);
						Utils.setDataTableValue(filterNS, 'orderColumn', json.orderColumn);
						Utils.setDataTableValue(filterNS, 'order', json.order);
						return json.data;
					},
					data: {
						filterId: Utils.getFilterValue(filterNS, 'filterId'),
						filterName: Utils.getFilterValue(filterNS, 'filterName'),
						filterCustomerType: Utils.getFilterValue(filterNS, 'filterCustomerType'),						
						filterBusinessCategory: Utils.getFilterValue(filterNS, 'filterBusinessCategory'),
						filterStatus: Utils.getFilterValue(filterNS, 'filterStatus'),
						filterSupplierType: Utils.getFilterValue(filterNS, 'filterSupplierType'),
						filterSalesMethod: Utils.getFilterValue(filterNS, 'filterSalesMethod'),
						filterPaymentMethod: Utils.getFilterValue(filterNS, 'filterPaymentMethod'),
						filterContactPerson: Utils.getFilterValue(filterNS, 'filterContactPerson'),
						filterCountry: Utils.getFilterValue(filterNS, 'filterCountry')
					}
				},
				columnDefs: [
					{
						"targets": 0, //id.
						"className": "text-right",
						"createdCell": (td, cellData, rowData, row, col) =>							
							createRoot(td).render(	
								<button className='table-cell-id' onClick={() => {return handleReadAction(cellData)}}>{cellData}</button>
							) 
					}, 
					{
						"targets": 1, //name.
						"className": "text-center"            
					},					
					{
						"targets": 2, //vat.
						"className": "text-center"                                             
					},
					{
						"targets": 3, //country.
						"className": "text-center"                                             
					},
					{
						"targets": 4, //customer type.
						"className": "text-center"                                             
					},
					{
						"targets": 5, //business category.
						"className": "text-center"                                             
					},
					{
						"targets": 6, //status.
						"className": "text-center"                                             
					},
					{
						"targets": 7, //payment method.
						"className": "text-center"                                             
					},
					{
						"targets": 8, //Sell out reporting & frequency.
						"className": "text-center"                                             
					},
					{						
						"createdCell": (td, cellData, rowData, row, col) =>							
							createRoot(td).render(	
								<div className='list-icons'>
									<div className='cell-action-dropdown-button dropdown' title='Retails'>
										<FontAwesomeIcon icon={faSitemap} className='circle-icon list-icon-default' />
										<div className="dropdown-content">
											<button title='Add Retail' onClick={() => { return handleAddRetailAction(cellData) }} ><div><FontAwesomeIcon icon={faPlus}/></div><div>Add</div></button>
											<button title='View All Retails' onClick={() => { return handleViewAllRetailsAction(cellData) }} ><div><FontAwesomeIcon icon={faEye} /></div><div>View All</div></button>
										</div>
									</div>
									<div className='cell-action-dropdown-button dropdown' title='Customer'>
										<FontAwesomeIcon icon={faInstitution} className='circle-icon list-icon-default' />
										<div className="dropdown-content">
											<button title='Preview Customer' className='dropdown-link-green dropdown-link-read' onClick={() => { return handleReadAction(cellData) }} ><div><FontAwesomeIcon icon={faStickyNote} /></div><div>Preview</div></button>
											<button title='Edit Customer' className='dropdown-link-yellow' onClick={() => { return handleEditAction(cellData) }} ><div><FontAwesomeIcon icon={faPencil} /></div><div>Edit</div></button>
											<button title='Delete Customer' className='dropdown-link-red' onClick={() => { return handleDeleteAction(cellData, rowData[1]) }} ><div><FontAwesomeIcon icon={faTrashAlt} /></div><div>Delete</div></button>
										</div>
									</div>
								</div>
							)
						,
					"className": "text-right dt-nowrap list-cell-actions",
					"targets": 9,
					"width": "120px",
					"orderable": false
				}// operations				
				]				
			});
			return dataTable;
		} catch (error) {
		  console.error('Error applying filters:', error);
		}
	}

	const handleAddRetailAction = (id) => {
		clearGenericMessages();
		navigate(`${Constants.RETAILS_PAGE_URL + Constants.ADD_URL}?customerId=${id}`);
	};
	const handleViewAllRetailsAction = (id) => {
		clearGenericMessages();
		// Reset Current Retails Value;
		Utils.setDataTableValue(filterRetailsNS, 'start', Constants.DATATABLE_START);
		Utils.setDataTableValue(filterRetailsNS, 'pageLength', Constants.DATATABLE_PAGE_LENGTH);
		Utils.setDataTableValue(filterRetailsNS, 'orderColumn', 1);
		Utils.setDataTableValue(filterRetailsNS, 'order', Constants.DATATABLE_ORDER_ASC);
		Utils.setFilterValue(filterRetailsNS, 'filterId', '');
		Utils.setFilterValue(filterRetailsNS, 'filterName', '');		
		Utils.setFilterValue(filterRetailsNS, 'filterCountry', '');
		Utils.setFilterValue(filterRetailsNS, 'filterAddress', '');
		// Set customer filter prepopulated.
		Utils.setFilterValue(filterRetailsNS, 'filterCustomer', id);	
		$('#headerLinkCustomers').attr('data-url', Constants.RETAILS_PAGE_URL);	
		//document.getElementById('headerLinkCustomers')?.dataset.url = Constants.RETAILS_PAGE_URL;
		navigate(Constants.RETAILS_PAGE_URL);
	};
	const handleReadAction = (id) => {
		clearGenericMessages();
		navigate(location.pathname + Constants.READ_URL + id);
	};
	const handleEditAction = (id) => {
		clearGenericMessages();
		navigate(location.pathname + Constants.UPDATE_URL + id);
	};
	const handleDeleteAction = async (id, name) => {
		try {
			// Prompt for confirmation before deleting the customer
			const confirmDelete = window.confirm(`Are you sure you want to delete "${name}"?`);
	  
			if (confirmDelete) {
				Utils.showPageLoader();
			  	const response = await CustomerSerice.deleteCustomer(id, await Utils.getToken());
				addGenericMessage(Utils.buildMessage(response, 15));
				Utils.hidePageLoader();
				return await initDataTable();
			}
		  } catch (error) {
			console.error('Error deleting customer:', error);
			if (Utils.isNetworkError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 10));
                UserService.logout(); 
                navigate(Constants.LOGIN_PAGE_URL);
                return;
            }			
			if (!UserService.handleSessionTimeOutError(error)) {
				addGenericMessage(Utils.buildErrorMessage({message: 'An error occurred while deleting customer!'}));
			}
		  }
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		Utils.setFilterValue(filterNS, name, value);
		setFilterData((prevFilterData) => ({
			...prevFilterData,
			[name]: value
		}));			
	};

	const handleFilterSubmit = async (e) => {
		e.preventDefault();
		if (!isEmpty(filterData.filterId + '') && !isInt(filterData.filterId + '')) {
			alert('Please enter number for "№" field.');
			return;
		}	
		return await initDataTable();
	};

	const navigateToAddCustomerPage = (e) => {
		clearGenericMessages();
		navigate(location.pathname + Constants.ADD_URL);
	}

  	return (
		<div>
			<div className="customers main-wrapper list-page">
				<div className="dt-add-button-wrapper"> 
					<div className='tabs-action-buttons filter-search-button'><button onClick={() => { navigateToAddCustomerPage() }} className="ibtn ibtn-full" alt=" Add Customer " title=" Add Customer "> + Add Customer </button></div>
				</div> 
				<div id="customersTabs">
					<TabsComponent/>					
					<div id={'tabs-' + Constants.TAB_CUSTOMERS_CUSTOMERS}>
						<div>
							<div className="card-body">
							<form onSubmit={handleFilterSubmit}>
								<div className="search-filter">
									<div className="filter-field">№ <input name="filterId" value={filterData.filterId || ''} onChange={handleInputChange} type="text" className="filter-field-xxs"/><span/></div>
									<div className="filter-field">Name <input name="filterName" value={filterData.filterName || ''} onChange={handleInputChange} type="text" className="filter-field-xl" placeholder='or VAT'/><span/></div>																
									<div className="filter-field">Type
										<select name="filterCustomerType" value={filterData.filterCustomerType || ''} onChange={handleInputChange}>
											<option key="" value="">All</option>
											{filterData.filterCustomerTypes.map((type) => (
												<option key={type.id} value={type.id}>{type.name}</option>
											))} 
										</select><span/>
									</div>	
									<div className="filter-field">Category
										<select name="filterBusinessCategory" value={filterData.filterBusinessCategory || ''} onChange={handleInputChange}>
											<option key="" value="">All</option>
											{filterData.filterBusinessCategories.map((category) => ( (category.id >= 0) &&
												<option key={category.id} value={category.id}>{category.name}</option>
											))} 
										</select><span/>
									</div>		
									<div className="filter-field">Status
										<select name="filterStatus" value={filterData.filterStatus || ''} onChange={handleInputChange}>
											<option key="" value="">All</option>
											{filterData.filterStatusList.map((status) => ( (status.id >= 0) &&
												<option key={status.id} value={status.id}>{status.name}</option>
											))} 
										</select><span/>
									</div>	
									<div className="filter-field">Supplier Type
										<select name="filterSupplierType" value={filterData.filterSupplierType || ''} onChange={handleInputChange}>
											<option key="" value="">All</option>
											{filterData.filterSupplierTypesList.map((supplier) => (
												<option key={supplier} value={supplier}>{supplier}</option>
											))}
										</select><span/>
									</div>							
									<div className="filter-field">Sales strategy
										<select name="filterSalesMethod" value={filterData.filterSalesMethod || ''} onChange={handleInputChange}>
											<option key="" value="">All</option>
											{filterData.filterSalesMethods.map((saleMethod) => (
												<option key={saleMethod} value={saleMethod}>{saleMethod}</option>
											))}
										</select><span/>
									</div>							
									<div className="filter-field">Payment Method
										<select name="filterPaymentMethod" value={filterData.filterPaymentMethod || ''} onChange={handleInputChange}>
											<option key="" value="">All</option>
											{filterData.filterPaymentMethods.map((paymentMethod) => (
												<option key={paymentMethod} value={paymentMethod}>{paymentMethod}</option>
											))}
										</select><span/>
									</div>	
									<div className="filter-field">Contact Person <input name="filterContactPerson" value={filterData.filterContactPerson || ''} onChange={handleInputChange} type="text" className="filter-field-xl" placeholder='name or email or phone'/><span/></div>
									<div className="filter-field">Country
										<select name="filterCountry" value={filterData.filterCountry || ''} onChange={handleInputChange} className='filter-country-dropdown'>
											<option key="" value="">All</option>
											{filterData.filterCountries.map((country) => (
												<option key={country.id} value={country.id}>{country.title}</option>
											))}
										</select><span/>
									</div>								
									<div className='filter-search-button'><button className="ibtn ibtn-full" type="submit">Search</button></div>
								</div>	
							</form>
								<table className="table table-bordered" id="datatable">
									<thead>
										<tr>
											<th className="text-center">№</th>
											<th>Customer / Company</th>
											<th>VAT №</th>											
											<th>Type</th>
											<th>Category</th>
											<th>Status</th>
											<th>Country</th>
											<th>Payment Method</th>
											<th>Sell out reporting & frequency</th>
											<th className='list-header-actions-label'>Actions</th>
										</tr>
									</thead>
									<tbody></tbody>
								</table>
							</div>
						</div>
					</div>
					<div id={'tabs-' + Constants.TAB_CUSTOMERS_RETAILS}></div>
				</div>
			</div>
		</div>	
	);
}

export default CustomersPage;