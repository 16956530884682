import Utils from '../utils/Utils';

class DownloadListService {
   
    static async downloadList(url, type, parameters, fileName, token) {  
        await fetch(`${url}?type=${type}${parameters ? '&' + parameters : ''}`, {
            method: 'GET',
            responseType: 'blob',
            headers: {Authorization: `Bearer ${token}`}
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create a blob URL and create a link element to trigger the download
            const blobUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = blobUrl;
            a.download = fileName + '_' + Utils.getDateAndTimeForFile() + '.' + type;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
            alert(error);
            console.error('Error fetching file:', error);
            throw error;
        }); 
    };
    

    // static async getList(url, parameters, token) {
    //     try {
    //         await axios.get(url + parameters,
    //         {
    //             headers: {Authorization: `Bearer ${token}`}
    //         },
    //         {
    //             responseType: 'blob'
    //         })            
    //         // .then((response) => {
    //         //     console.log(response.data);
    //         //     // Create a blob URL and create a link element to trigger the download
    //         //     const blobUrl = window.URL.createObjectURL(response.data);
    //         //     const a = document.createElement('a');
    //         //     a.style.display = 'none';
    //         //     a.href = blobUrl;
    //         //     a.download = 'sample.xlsx'; // Set the desired file name with .xlsx extension
    //         //     document.body.appendChild(a);
    //         //     a.click();
    //         //     window.URL.revokeObjectURL(blobUrl);
    //         // })                             
    //     } catch (error) {
    //         throw error;
    //     }
    // };
}

export default DownloadListService;
