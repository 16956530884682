import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import * as Constants from '../../constants';

const GenericMessage = ({ message = '', statusCode = 200, timeout = 0, count= 0, handleDismiss = null }) => {
  useEffect(() => {
    if (timeout > 0 && handleDismiss) {
      const timer = setTimeout(() => {
        handleDismiss();
      }, timeout * 1000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout])

  const dismissGenericMessage = (e) => {
    e.preventDefault();
    handleDismiss(e);
  }
  // Split message in messages per brake.
  const messageLines = message.split('<br/>');

  return messageLines?.length && messageLines?.length > 0 && (    
        <div className={'message-body' + (statusCode  === 200 ? ' message-body-success' : '')}>
            <div className="icon-holder"><FontAwesomeIcon icon={statusCode === 200 ? faCheckCircle : faExclamationCircle} /></div>
            <div className="messages-wrapper">{
              messageLines.map((msg, index) => (
                <div key={index}>{msg}</div>
              ))}
            </div>
            {count === 0 &&
            <div className="message-close-icon" onClick={dismissGenericMessage}><FontAwesomeIcon icon={faTimesCircle}/></div>
            }
        </div>
  )
}

const GenericMessagesWrapper = ({ children }) => {
  const listPage = Constants.LIST_PAGES_URLS.includes(window.location.pathname); 
  return children?.length && (
    <div id="messageBlock">
      <div className={'message-block-wrapper' + (listPage ? ' message-block-wrapper-list-page' : '')}>
        <div id="messageBlockChildred" data-count={children.length} className={'message-block' + (listPage ? ' message-block-list-page' : '')}>
        {children}
        </div>
      </div>
    </div>
  )
}

export { GenericMessage, GenericMessagesWrapper }