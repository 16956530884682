import React, { useRef, useState, useEffect } from 'react';
import $ from 'jquery';
import isEmail from 'validator/es/lib/isEmail';
import { useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import UserService from '../../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdBadge, faPhone, faEnvelope, faHouse, faUser, faIdCard, faInstitution, faClock, faMortarBoard, faUserGroup, faInfoCircle, faChevronUp, faKey, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import Utils from '../../../utils/Utils';
import * as Constants from '../../../../constants';
import { useGenericMessages } from '../../../../contexts/messages/genericMessageContext';

function UpdateUser() {
  const { addGenericMessage, clearGenericMessages } = useGenericMessages();
  const [userInfo, setUserInfo] = useState(null);	 
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [chevronIcon, setChevronIcon] = useState({closed: true});
  const [isReadMode, setReadMode] = useState(false);
  const { userId } = useParams();
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const location = useLocation(); 
  const isEditProfile = location.pathname.indexOf(Constants.EDIT_PROFILE_PAGE_URL) !== -1;
  const emailRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: '',
    phone: '',
    address: '',
    position: '',
    customerId: '',
    customer: { id: ''},
    status: ''
  });
  const [customersData, setCustomersData] = useState({});

  useEffect(() => {
    if (location.pathname.indexOf('read') !== -1) {
      setReadMode(true);
    }	
    const fetchUserInfo = async () => {
      const userData = await Utils.getUserData();
      setUserInfo(userData);      
      setShowUpdateBtn(isEditProfile || parseInt(userId, 10) !== Constants.GLOBAL_ADMIN_USER_ID || Constants.GLOBAL_ADMIN_USER_ID === userData.id);
      await fetchUserData(userId);
    };
    fetchUserInfo();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]); 

  const fetchUserData = async (userId) => {    
    try {
      const response = isEditProfile || !userId ? await UserService.getProfileForEdit(await Utils.getToken(), true) : await UserService.getUserById(userId, await Utils.getToken());
      hideLoader();
      if (response?.statusCode === 200) {
        const { id, name, email, role, phone, address, position, status, createdAt, updatedAt } = response;
        const customerId = response.customer.id;       
        setFormData({ id, name, email, role, phone, address, position, customerId, customer: { id: customerId}, status, createdAt, updatedAt })        
        setCustomersData(response.customers);  
      } else {
        clearGenericMessages();
        addGenericMessage(Utils.buildMessage(response, 10));      
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      hideLoader();
      clearGenericMessages();
      if (Utils.isNetworkError(error)) {
          addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
          UserService.logout();
          navigate(Constants.LOGIN_PAGE_URL);
          return;
      }
      if (!UserService.handleSessionTimeOutError(error)) {
        addGenericMessage(Utils.buildErrorMessage({message: 'Error fetching user data.'}, 10));
        if (!!searchParams.get('returnUrl')?.length) {
          navigate(searchParams.get('returnUrl'));
        } else {
          navigate(Constants.HOME_PAGE_URL);
        }      
      } 
    }
  };

  const handleInputChange = (e) => {
    if (isReadMode) return false;
    const { name, value } = e.target;
    setFormData((prevformData) => ({
      ...prevformData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {   
    if (isReadMode) return false;     
    e.preventDefault();
    clearGenericMessages();
    if (!isEmail(formData.email)) {
      addGenericMessage(Utils.buildErrorMessage({message: 'Please enter valid email.'}, 10));
      emailRef.current.focus();
      emailRef.current.style.border = '1px solid #cc0000'; 
      scrollToTop();
      return;
    }
    showLoader();
    try {
        formData.customer.id = formData.customerId;
        const response = isEditProfile || !userId ? await UserService.updateMyProfile(formData, await Utils.getToken()) : await UserService.updateUser(userId, formData, await Utils.getToken());
        hideLoader();        
        addGenericMessage(Utils.buildMessage(response, 10));
        if (response?.statusCode === 200) {
          if (!isEditProfile) {
            navigate(Constants.USER_MANAGEMENT_PAGE_URL);
          } else {
            // Update time.
            formData.updatedAt = response.updatedAt;
          }
        }
        scrollToTop();    
    } catch (error) {
      console.error('Error updating user profile:', error);
      hideLoader();
      if (Utils.isNetworkError(error)) {
        addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
        UserService.logout(); 
        navigate(Constants.LOGIN_PAGE_URL);
        return;
      }      
      if (!UserService.handleSessionTimeOutError(error)) {
				addGenericMessage(Utils.buildErrorMessage({message: 'Error updating user profile!'}));
        scrollToTop();
			}
    }
  };

  const handleBack = async (e) => {
    clearGenericMessages();
    navigate(Constants.USER_MANAGEMENT_PAGE_URL);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    clearGenericMessages();
    navigate(Constants.UPDATE_USER_PAGE_URL + userId);
    setReadMode(false);
  };

  const showLoader = () => {
      $('#profileComponentLoader').show();
      $('#profileComponentFader').show();
  };

  const hideLoader = () => {
      $('#profileComponentLoader').hide();
      $('#profileComponentFader').hide();
  };

  const handleMouseOver = (id) => {        
    $(`#field${id}Icon`).css('color', '#2c95ff');
    if ('Metadata' === id) {
        $(`#field${id}Icon`).css('color', '#fff');
        $(`#field${id}IconChevron`).css('color', '#fff');
    }  
  };
  const handleMouseOut = (id) => {
      $(`#field${id}Icon`).css('color', '#999');
      if ('Metadata' === id) {
          $(`#field${id}IconChevron`).css('color', '#2c95ff');
          if (!chevronIcon.closed) {
              $(`#field${id}Icon`).css('color', '#fff');
              $(`#field${id}IconChevron`).css('color', '#fff');
          }
      }  
  };
  const toggleMetadata = () => {
      $('#metadataContents').slideToggle();     
      if (chevronIcon.closed) {
          $('#metadataLabel').attr('class', 'form-fields-metadata-label form-fields-metadata-label-active');
          $('#fieldMetadataIconChevron').css('color', '#fff');
          $('#fieldMetadataIconChevron').css('transform', 'rotate(180deg)');
      } else {
          $('#metadataLabel').attr('class', 'form-fields-metadata-label');
          $('#fieldMetadataIconChevron').css('transform', '');
      }            
      setChevronIcon((chevronIcon.closed) ? {closed: false} : {closed: true});  
  }

  const changePassword = (e) => {
    navigate(Constants.CHANGE_PASSWORD_PAGE_URL);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    }); 
  }

  return (
    <div>
        <div id="updateProfilePageContainer" className='form-page-container-l'>   
            <div className='form-body without-side'>
                <div className="row">
                    <div className="img-holder">
                        <div className="bg"></div>
                        <div className="info-holder">
                            <img src="/images/login_bg_graphic.svg" alt="" />
                        </div>
                    </div>
                    <div className="form-holder">
                        <div className="form-content">
                            <div className="form-items">
                                <div className="form-icon"> 
                                    <div className="icon-holder">
                                        <FontAwesomeIcon icon={faUser} />
                                    </div>
                                </div>
                                <h3 className="form-title-center">{isReadMode ? 'Preview' : 'Update'} User Profile</h3>
                                <div className='form-body-fields-wrapper'>
                                  <form onSubmit={handleSubmit}>
                                    <div id='profileComponentLoader' className='component-loader'></div>
                                    <div id='profileComponentFader' className='component-fader'></div>
                                    <div className='form-body-fields'>
                                        <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Name')} onMouseOut={() => handleMouseOut('Name')}><FontAwesomeIcon id="fieldNameIcon" icon={faIdCard} />Name *:<br/><div className='field-value'><input className="form-control" type="text" name="name" value={formData?.name || ''} onChange={handleInputChange} required /></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Email')} onMouseOut={() => handleMouseOut('Email')}><FontAwesomeIcon id="fieldEmailIcon" icon={faEnvelope} />Email *:<br/><div className='field-value'><input ref={emailRef} className="form-control" type="text" name="email" value={formData?.email || ''} onChange={handleInputChange} required /></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Phone')} onMouseOut={() => handleMouseOut('Phone')}><FontAwesomeIcon id="fieldPhoneIcon" icon={faPhone} />Phone:<br/><div className='field-value'><input className="form-control" type="text" name="phone" value={formData?.phone || ''} onChange={handleInputChange} /></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Address')} onMouseOut={() => handleMouseOut('Address')}><FontAwesomeIcon id="fieldAddressIcon" icon={faHouse} />Address:<br/><div className='field-value'><textarea className="form-control" name="address" value={formData?.address || ''} onChange={handleInputChange} /></div></div>                                            
                                        </div>
                                        <div className='form-body-col2'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Position')} onMouseOut={() => handleMouseOut('Position')}><FontAwesomeIcon id="fieldPositionIcon" icon={faMortarBoard} />Position:<br/><div className='field-value'><input className="form-control" type="text" name="position" value={formData?.position || ''} onChange={handleInputChange} /></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Role')} onMouseOut={() => handleMouseOut('Role')}><FontAwesomeIcon id="fieldRoleIcon" icon={faUserGroup} />Role *:<br/>
                                              {
                                                userInfo?.isAdmin && !isEditProfile &&
                                                <div className='field-value'>
                                                  <select name="role" className='form-control' value={formData.role} onChange={handleInputChange}>
                                                    {userInfo?.isGlobalAdmin &&
                                                    <option value={Constants.GLOBAL_ADMIN_ROLE}>{Constants.GLOBAL_ADMIN_ROLE}</option>
                                                    }
                                                    <option value={Constants.VIP_TRADING_ADMIN_ROLE}>{Constants.VIP_TRADING_ADMIN_ROLE}</option>
                                                    {/* <option value={Constants.CUSTOMER_ADMIN_ROLE}>{Constants.CUSTOMER_ADMIN_ROLE}</option> */}
                                                    <option value={Constants.USER_ROLE}>{Constants.USER_ROLE}</option>            
                                                  </select>
                                                </div>
                                              }
                                              {
                                                (isEditProfile || !userInfo?.isAdmin) &&
                                                <div className='field-value field-value-text-only'>{formData.role}</div>
                                              }                                              
                                            </div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Customer')} onMouseOut={() => handleMouseOut('Customer')}><FontAwesomeIcon id="fieldCustomerIcon" icon={faInstitution} />Customer *:<br/>
                                              {
                                                userInfo?.isAdmin && customersData != null && !isEditProfile &&
                                                <div className='field-value'>
                                                  <select name="customerId" className='form-control' value={formData.customerId} onChange={handleInputChange}>
                                                    {Object.values(customersData).map((customer) => (
                                                      <option key={customer.id} value={customer.id}>{customer.name}</option>
                                                    ))}                  
                                                  </select> 
                                                </div>
                                              }  
                                              {
                                                (isEditProfile || (!userInfo?.isAdmin && formData?.customerId)) &&
                                                <div className='field-value field-value-text-only'>
                                                {Object.values(customersData).filter((customer) => { return customer.id === formData.customerId; }).map((customer) => (
                                                  <span key={ customer.id }><strong>{customer.name}</strong></span>
                                                ))}   
                                                </div>
                                              }

                                            </div>
                                            { isEditProfile &&
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Password')} onMouseOut={() => handleMouseOut('Password')}><FontAwesomeIcon id="fieldPasswordIcon" icon={faKey} />Password:<br/><div className='form-button form-button-inline'><button className="ibtn ibtn-full" type="button" onClick={(e) => changePassword()}>Change Password</button></div></div>
                                            }
                                            {
                                              !isEditProfile &&
                                              <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Status')} onMouseOut={() => handleMouseOut('Status')}><FontAwesomeIcon id="fieldStatusIcon" icon={faToggleOn} />Status *:<br/>
                                              <div className='field-value'>
                                                <select name="status" className='form-control' value={formData.status} onChange={handleInputChange}>
                                                  <option value={Constants.USER_STATUS_INACTIVE}>Inactive</option>
                                                  <option value={Constants.USER_STATUS_ACTIVE}>Active</option>            
                                                </select>
                                              </div>
                                          </div>   
                                            }
                                        </div>
                                        <div className='form-body-col-full'>
                                          <div className='form-fields-metadata'>
                                            <div id="metadataLabel" className='form-fields-metadata-label' onClick={() => { toggleMetadata() }}  onMouseOver={() => handleMouseOver('Metadata')} onMouseOut={() => handleMouseOut('Metadata')}><strong><FontAwesomeIcon id="fieldMetadataIcon" icon={faInfoCircle} />Metadata </strong><span className='metadata-chevron-icon'><FontAwesomeIcon id="fieldMetadataIconChevron" icon={faChevronUp} /></span></div>
                                            <div id="metadataContents" className='form-fields-metadata-contents'>
                                                <div onMouseOver={() => handleMouseOver('RecordId')} onMouseOut={() => handleMouseOut('RecordId')}><FontAwesomeIcon id="fieldRecordIdIcon" icon={faIdBadge} />Record Id: <br/><div className=''>{formData?.id}</div></div>
                                                <div onMouseOver={() => handleMouseOver('CreationDate')} onMouseOut={() => handleMouseOut('CreationDate')}><FontAwesomeIcon id="fieldCreationDateIcon" icon={faClock} />Creation Date: <br/><div className='field-value-italic'>{Utils.formatDBDate(formData?.createdAt)}</div></div>
                                                <div onMouseOver={() => handleMouseOver('LastUpdate')} onMouseOut={() => handleMouseOut('LastUpdate')}><FontAwesomeIcon id="fieldLastUpdateIcon" icon={faClock} />Last Update:<br/><div className='field-value-italic'>{Utils.formatDBDate(formData?.updatedAt)}</div></div>
                                            </div>
                                          </div>
                                        </div>
                                        {  
                                          !isReadMode && showUpdateBtn && isEditProfile &&
                                        <div className="form-button">
                                          <button className="ibtn ibtn-full" type="submit">Update</button>
                                        </div>
                                        }
                                        {!isReadMode && showUpdateBtn && !isEditProfile &&
                                        <div className="form-button">
                                          <button className="ibtn ibtn-col1" type="submit">Update</button>
                                          <button className="ibtn-empty ibtn-col2" onClick={(e) => handleBack(e)}>Back</button>   
                                        </div>
                                        }                                                
                                        {
                                          isReadMode &&
                                        <div className="form-button">
                                          <button className="ibtn ibtn-col1" type="submit" onClick={(e) => handleEdit(e)}>Edit</button>
                                          <button className="ibtn-empty ibtn-col2" onClick={(e) => handleBack(e)}>Back</button>                                          
                                        </div>
                                        }                                        
                                    </div>
                                  </form>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default UpdateUser;