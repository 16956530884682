import React, { useRef, useEffect, useState } from 'react';
import $ from 'jquery';
import isInt from 'validator/es/lib/isInt';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import RetailService from '../../../service/RetailService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faClock, faInfoCircle, faIdBadge, faChevronUp, faCube, faInstitution, faGlobe, faShop } from '@fortawesome/free-solid-svg-icons';
import Utils from '../../../utils/Utils';
import * as Constants from '../../../../constants';
import UserService from '../../../service/UserService';
import { useGenericMessages } from '../../../../contexts/messages/genericMessageContext';

function RetailPage() {
    const { addGenericMessage, clearGenericMessages } = useGenericMessages();
    const navigate = useNavigate();
    const { retailId } = useParams();
    const [searchParams] = useSearchParams();
    const inputRef = useRef(null);    
    const activeShopsRef = useRef(null);    
    const totalShopsRef = useRef(null);    
    const location = useLocation();
    const [isReadMode, setReadMode] = useState(false);
    const [chevronIcon, setChevronIcon] = useState({closed: true});      
    const [formData, setFormData] = useState({
        customerId: '',
        customer: { id: ''},
        name: '',        
        activeShops: 0,
        totalShops: 0,
        countryId: '',
        country: { id: ''},
        address: ''
    });
    const [nomenclaturesData, setNomenclaturesData] = useState({});

    useEffect(() => {
        Utils.setActiveNav('headerLinkCustomers');
        if (location.pathname.indexOf('read') !== -1) {
            setReadMode(true);
        }	
        if (!isReadMode) {
            inputRef.current.focus();
        }
        fetchFormData();
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    }, []);

    const fetchFormData = async () => {    
        try {
          showLoader();
          const response = await RetailService.getRetailFormData(await Utils.getToken());
          hideLoader();
          if (response?.statusCode === 200) {
            const {customers, countries} = response;
            setNomenclaturesData({customers, countries});            
            if (retailId) {
              await fetchObjectDataById(retailId);                        
            } else {
                if (!!searchParams.get('customerId')?.length) {
                    if (isInt(searchParams.get('customerId') + '') && searchParams.get('customerId') > 0) {
                        formData.customerId = searchParams.get('customerId');                    
                    }
                } 
            }   
          } else {
              clearGenericMessages();
              addGenericMessage(Utils.buildMessage(response, 10));
              navigate(Constants.CUSTOMERS_PAGE_URL);
          }
        } catch (error) {
            console.error(`Error fetching customer form data:`, error);
            hideLoader();
            if (Utils.isNetworkError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
                UserService.logout(); 
                navigate(Constants.LOGIN_PAGE_URL);
                return;
            }  
            if (!UserService.handleSessionTimeOutError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: `Error fetching customer form data.`}, 5));
            }            
        }
      };

    const fetchObjectDataById = async (retailId) => {
        try {
            showLoader();
            const response = await RetailService.getRetailById(retailId, await Utils.getToken());
            hideLoader();
            if (response?.statusCode === 200) {
                const { id, name, activeShops, totalShops, address, createdAt, updatedAt } = response;
                const customerId = response.customer.id;
                const countryId = response.country.id;                
                setFormData({ id, customerId, customer: { id: customerId}, name, activeShops, totalShops, countryId, country: { id: countryId}, address, createdAt, updatedAt });
            } else {
                clearGenericMessages();
                addGenericMessage(Utils.buildMessage(response, 10));
                navigate(Constants.RETAILS_PAGE_URL);
            }
        } catch (error) {
            console.error(`Error fetching retail information:`, error);
            hideLoader();
            if (Utils.isNetworkError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
                UserService.logout(); 
                navigate(Constants.LOGIN_PAGE_URL);
                return;
            }  
            if (!UserService.handleSessionTimeOutError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: `Error fetching retail information.`}, 5));
            }            
        }
    };
    
    const handleInputChange = (e) => {
        if (isReadMode) return false;
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        if (isReadMode) return false;
        e.preventDefault();             
        clearGenericMessages();
        if (!isInt(formData.activeShops + '') || formData.activeShops < 0) {
            addGenericMessage(Utils.buildErrorMessage({message: 'Please enter number for "Active Shops".'}, 10));
            activeShopsRef.current.focus();
            activeShopsRef.current.style.border = '1px solid #cc0000'; 
            return;
        }
        if (!isInt(formData.totalShops + '') || formData.totalShops < 0) {
            addGenericMessage(Utils.buildErrorMessage({message: 'Please enter number for "Total Shops".'}, 10));
            totalShopsRef.current.focus();
            totalShopsRef.current.style.border = '1px solid #cc0000'; 
            return;
        }
        try {
            showLoader();
            formData.customer.id = formData.customerId;
            formData.country.id = formData.countryId;
            const response = await RetailService.addEditRetail(retailId, formData, await Utils.getToken());
            hideLoader();
            if (response) {                
                if (response?.statusCode === 200) {                    
                    addGenericMessage(Utils.buildMessage(response, 10));
                    navigate(Constants.RETAILS_PAGE_URL);
                } else {               
                    addGenericMessage(Utils.buildMessage(response));
                }
            } else {
                addGenericMessage(Utils.buildErrorMessage({message: `An error occurred while adding retail!`}));
            }            
        } catch (error) {
            console.error(`Error add/edit retail:`, error);
            hideLoader();            
            if (Utils.isNetworkError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
                UserService.logout(); 
                navigate(Constants.LOGIN_PAGE_URL);
                return;
            }
            if (!UserService.handleSessionTimeOutError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: `An error occurred while add/edit retail!`}));
            }
        }
    };

    const showLoader = () => {
        $('#retailLoader').show();
        $('#retailFader').show();
    };
 
    const hideLoader = () => {
        $('#retailLoader').hide();
        $('#retailFader').hide();
    };

    const handleCancel = async (e) => {
        clearGenericMessages();
        navigate(Constants.RETAILS_PAGE_URL);
    };

    const handleEdit = async (e) => {
        clearGenericMessages();
        setReadMode(false);
        navigate(Constants.RETAILS_PAGE_URL + Constants.UPDATE_URL + retailId);
    };

    const handleMouseOver = (id) => {        
        $(`#field${id}Icon`).css('color', '#2c95ff');
        if ('Metadata' === id) {
            $(`#field${id}Icon`).css('color', '#fff');
            $(`#field${id}IconChevron`).css('color', '#fff');
        }  
    };
    const handleMouseOut = (id) => {
        $(`#field${id}Icon`).css('color', '#999');
        if ('Metadata' === id) {
            $(`#field${id}IconChevron`).css('color', '#2c95ff');
            if (!chevronIcon.closed) {
                $(`#field${id}Icon`).css('color', '#fff');
                $(`#field${id}IconChevron`).css('color', '#fff');
            }
        }  
    };
    const toggleMetadata = () => {
        $('#metadataContents').slideToggle();     
        if (chevronIcon.closed) {
            $('#metadataLabel').attr('class', 'form-fields-metadata-label form-fields-metadata-label-active');
            $('#fieldMetadataIconChevron').css('color', '#fff');
            $('#fieldMetadataIconChevron').css('transform', 'rotate(180deg)');
        } else {
            $('#metadataLabel').attr('class', 'form-fields-metadata-label');
            $('#fieldMetadataIconChevron').css('transform', '');
        }            
        setChevronIcon((chevronIcon.closed) ? {closed: false} : {closed: true});  
    }

    return (
        <div>
            <div id="retailPageContainer" className='form-page-container-l form-size-m'>
                <div className='form-body without-side'>
                    <div className="row">
                        <div className="img-holder">
                            <div className="bg"></div>
                            <div className="info-holder">
                                <img src="/images/login_bg_graphic.svg" alt="" />
                            </div>
                        </div>
                        <div className="form-holder">
                            <div className="form-content">
                                <div className="form-items">
                                    <div className="form-icon"> 
                                        <div className="icon-holder">
                                            <FontAwesomeIcon icon={faCube} />
                                        </div>
                                    </div>
                                    <h3 className="form-title-center">{retailId ? (isReadMode ? 'Preview' : 'Edit') : 'Add'} Retail</h3>
                                    <div className='form-body-fields-wrapper'>
                                        <form onSubmit={handleSubmit}>
                                            <div id='retailLoader' className='component-loader' style={{display: 'none'}}></div>
                                            <div id='retailFader' className='component-fader' style={{display: 'none'}}></div>
                                            <div className='form-body-fields'>
                                                <div className='form-body-col1'>
                                                    <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Customer')} onMouseOut={() => handleMouseOut('Customer')}><FontAwesomeIcon id="fieldCustomerIcon" icon={faInstitution} />Customer *:<br/>
                                                {
                                                    nomenclaturesData?.customers &&
                                                        <div className='field-value'>
                                                        <select name="customerId" className='form-control' value={formData.customerId} onChange={handleInputChange}>
                                                            {!retailId &&
                                                            <option value="">-- Not selected --</option>
                                                            }
                                                            {nomenclaturesData.customers.filter((customer) => { return customer.id !== Constants.SYSTEM_CUSTOMER_ID; }).map((customer) => (
                                                            <option key={customer.id} value={customer.id}>{customer.name}</option>
                                                            ))}                  
                                                        </select> 
                                                        </div>
                                                }
                                                    </div>
                                                    <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Name')} onMouseOut={() => handleMouseOut('Name')}><FontAwesomeIcon id="fieldNameIcon" icon={faIdCard} /> Name* :<br/><div className='field-value'><input ref={inputRef} className="form-control" type="text" name="name" value={formData?.name || ''} onChange={handleInputChange} maxLength={200} required readOnly={isReadMode}/></div></div>
                                                    <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Country')} onMouseOut={() => handleMouseOut('Country')}><FontAwesomeIcon id="fieldCountryIcon" icon={faGlobe} />Country*:<br/>
                                                        <div className='field-value'>
                                                            <select name="countryId" className='form-control' value={formData?.countryId || ''} onChange={handleInputChange} required>
                                                                <option value="">-- Not selected --</option>
                                                                {nomenclaturesData?.countries && nomenclaturesData.countries.map((country) => (
                                                                    <option key={country.id} value={country.id}>{country.title}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-body-col2'>
                                                    <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('ActiveShops')} onMouseOut={() => handleMouseOut('ActiveShops')}><FontAwesomeIcon id="fieldActiveShopsIcon" icon={faShop} />Active Shops*:<br/><div className='field-value'><input ref={activeShopsRef} className="form-control" name="activeShops" value={formData?.activeShops || 0} onChange={handleInputChange} maxLength={10} required readOnly={isReadMode}/></div></div>
                                                    <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('TotalShops')} onMouseOut={() => handleMouseOut('TotalShops')}><FontAwesomeIcon id="fieldTotalShopsIcon" icon={faShop} />Total Shops*:<br/><div className='field-value'><input ref={totalShopsRef} className="form-control" name="totalShops" value={formData?.totalShops || 0} onChange={handleInputChange} maxLength={10} required readOnly={isReadMode}/></div></div>
                                                </div>
                                                { retailId &&
                                                <div className='form-body-col-full'>
                                                    <div className='form-fields-metadata'>
                                                        <div id="metadataLabel" className='form-fields-metadata-label' onClick={() => { toggleMetadata() }}  onMouseOver={() => handleMouseOver('Metadata')} onMouseOut={() => handleMouseOut('Metadata')}><strong><FontAwesomeIcon id="fieldMetadataIcon" icon={faInfoCircle} />Metadata </strong><span className='metadata-chevron-icon'><FontAwesomeIcon id="fieldMetadataIconChevron" icon={faChevronUp} /></span></div>
                                                        <div id="metadataContents" className='form-fields-metadata-contents'>
                                                            <div onMouseOver={() => handleMouseOver('RecordId')} onMouseOut={() => handleMouseOut('RecordId')}><FontAwesomeIcon id="fieldRecordIdIcon" icon={faIdBadge} />Record Id: <br/><div className=''>{formData?.id}</div></div>
                                                            <div onMouseOver={() => handleMouseOver('CreationDate')} onMouseOut={() => handleMouseOut('CreationDate')}><FontAwesomeIcon id="fieldCreationDateIcon" icon={faClock} />Creation Date: <br/><div className='field-value-italic'>{Utils.formatDBDate(formData?.createdAt)}</div></div>
                                                            <div onMouseOver={() => handleMouseOver('LastUpdate')} onMouseOut={() => handleMouseOut('LastUpdate')}><FontAwesomeIcon id="fieldLastUpdateIcon" icon={faClock} />Last Update:<br/><div className='field-value-italic'>{Utils.formatDBDate(formData?.updatedAt)}</div></div>
                                                        </div>
                                                    </div>
                                                </div> 
                                                }      
                                                { !isReadMode &&                                     
                                                <div className="form-button">
                                                    <button className="ibtn ibtn-col1" type="submit">Save</button>
                                                    <button className="ibtn-empty ibtn-col2" onClick={(e) => handleCancel(e)}>Cancel</button>
                                                </div>
                                                }
                                                { isReadMode &&   
                                                <div className="form-button">
                                                    <button className="ibtn ibtn-col1" type="submit" onClick={(e) => handleEdit(e)}>Edit</button>
                                                    <button className="ibtn-empty ibtn-col2" onClick={(e) => handleCancel(e)}>Back</button>
                                                </div>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>       
    );
}

export default RetailPage;