import React, {useRef, useState, useEffect} from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import UserService from '../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faKey } from '@fortawesome/free-solid-svg-icons'
import Utils from '../../utils/Utils';
import * as Constants from '../../../constants';
import { useGenericMessages } from '../../../contexts/messages/genericMessageContext';

function LoginPage() {
    const { addGenericMessage, clearGenericMessages } = useGenericMessages();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [searchParams] = useSearchParams(); 
    const [passwordType, setPasswordType] = useState('password');
    const [passwordIcon, setPasswordIcon] = useState(faEyeSlash);
    const navigate = useNavigate();      
    const inputRef = useRef(null); 

    useEffect(() => {
        // We get here when the token is expired, so logout and redirect to login.
		if (searchParams.get('redirect') || searchParams.get('sessiontimeout')) {
            UserService.logout();
            if (searchParams.get('sessiontimeout')) {
                clearGenericMessages();
                addGenericMessage(
                {
                    message: 'Your session has expired!',
                    statusCode: 403,
                    timeout: 30
                });
                navigate('/login');
                return;
            }
            document.location.href = Constants.LOGIN_PAGE_URL;            
		}
        inputRef.current.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const handleToggle = () => {    
        if (passwordType === 'password') {
            setPasswordType('text');
            setPasswordIcon(faEye);
        } else {
            setPasswordType('password');
            setPasswordIcon(faEyeSlash);
        }
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        clearGenericMessages();
        try {
            const userData = await UserService.login(email, password);
            if (userData.token) {
                await Utils.storeUserData(userData);
                if (Utils.isAdmin(userData.role)) {
                    document.location.href = Constants.CUSTOMERS_PAGE_URL;                    
                } else {
                    document.location.href = '/profile';
                }
                //navigate('/profile');
            } else {
                addGenericMessage(Utils.buildErrorMessage({message: userData.error}));                
            }
        } catch (error) {
            console.log(error);
            if (Utils.isNetworkError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
                return;
            }
            addGenericMessage(Utils.buildErrorMessage({message: 'Server does not respond.'}, 5));
        }                
    };   
   
    return (
        <div>
            <div id="loginPageContainer" className='login-page-container'>
                <div className='form-body without-side'>
                    <div className="row">
                        <div className="img-holder">
                            <div className="bg"></div>
                            <div className="info-holder">
                                <img src="/images/login_bg_graphic.svg" alt="" />
                            </div>
                        </div>
                        <div className="form-holder">
                            <div className="form-content">
                                <div className="form-items">
                                    <div className="form-icon"> 
                                        <div className="icon-holder">
                                            <FontAwesomeIcon icon={faKey} />
                                        </div>
                                    </div>
                                    <h3 className="form-title-center">Login to B2BPortal</h3>
                                    <form onSubmit={handleSubmit} id="loginForm">
                                        <input ref={inputRef} className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required autoComplete='E-mail Address' placeholder="E-mail Address" />
                                        <div className='field-value-password'>
                                            <input className="form-control" type={passwordType} value={password} onChange={(e) => setPassword(e.target.value)} required autoComplete='current-password' placeholder="Password" /><span className="field-password-eye" onClick={handleToggle}><FontAwesomeIcon id="fieldPasswordEyeIcon" icon={passwordIcon} /></span>
                                        </div> 
                                        <div className='field-value field-value-link'>
                                            <Link to={Constants.FORGOTTEN_PASSWORD_PAGE_URL}>Forgotten password?</Link>
                                        </div>
                                        <div className="form-button">
                                            <button type="submit" className="ibtn ibtn-full">Login</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;