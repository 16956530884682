export const BASE_URL = process.env.REACT_APP_SERVER_ADDRESS;

// Service End Points.
// Admin
export const ADMIN_URL = `${BASE_URL}/admin`;
// Public
export const PUBLIC_URL = `${BASE_URL}/public`;
// Auth.
export const LOGIN_URL = `${BASE_URL}/auth/login`;
// Users.
export const USERS_LIST_URL = `${ADMIN_URL}/users`;
export const ADD_USER_URL = `${USERS_LIST_URL}/add`;
export const ADD_USER_GET_CUSTOMERS_URL = `${USERS_LIST_URL}/add/get-customers`;
export const EDIT_USER_URL = `${USERS_LIST_URL}/update/`;
export const DELETE_USER_URL = `${USERS_LIST_URL}/delete/`;
export const USERS_DOWNLOAD_LIST_URL = `${USERS_LIST_URL}/download/list`;
export const PROFLE_URL = `${BASE_URL}/adminuser/profile`;
export const EDIT_PROFLE_URL = `${BASE_URL}/adminuser/profile/update`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/adminuser/profile/change-password`;
export const SEND_CHANGE_PASSWORD_REQUEST_URL = `${PUBLIC_URL}/forgotten-password`;
export const GET_USER_BY_FORGOTTEN_PASSWORD_CODE_REQUEST_URL = `${PUBLIC_URL}/forgotten-password/`;
export const FORGOTTEN_PASSWORD_CHANGE_PASSWORD_REQUEST_URL = `${PUBLIC_URL}/forgotten-password/change-password`;
// Customers.
export const CUSTOMERS_LIST_URL = `${ADMIN_URL}/customers`;
export const CUSTOMERS_DOWNLOAD_LIST_URL = `${CUSTOMERS_LIST_URL}/download/list`;
export const RETAILS_LIST_URL = `${ADMIN_URL}/retails`;
export const RETAILS_DOWNLOAD_LIST_URL = `${RETAILS_LIST_URL}/download/list`;
// Nomenclatures.
export const NOMENCLATURES_LIST_URL = `${ADMIN_URL}/nomenclatures`;
export const NOMENCLATURES_GET_NOMENCLATURE_BY_ID = `${NOMENCLATURES_LIST_URL}/`;
export const NOMENCLATURES_ADD_URL = `${NOMENCLATURES_LIST_URL}/add`;
export const NOMENCLATURES_UPDATE_URL = `${NOMENCLATURES_LIST_URL}/update/`;
export const NOMENCLATURES_DELETE_URL = `${NOMENCLATURES_LIST_URL}/delete/`;
export const NOMENCLATURES_DOWNLOAD_LIST_URL = `${NOMENCLATURES_LIST_URL}/download/list`;
export const NOMENCLATURES_COUNTRIES_LIST_URL = `${ADMIN_URL}/nomenclatures/countries`;
export const NOMENCLATURES_COUNTRIES_DOWNLOAD_LIST_URL = `${NOMENCLATURES_COUNTRIES_LIST_URL}/download/list`;
// Settings.
export const SETTINGS_SYSTEM_LIST_URL = `${ADMIN_URL}/settings/system`;
export const SETTINGS_CUSTOMER_MATRIX_LIST_URL = `${ADMIN_URL}/settings/customer-matrix`;
export const SETTINGS_SYSTEM_GET_SETTING_BY_ID = `${SETTINGS_SYSTEM_LIST_URL}/`;

// URLs suffix.
export const ADD_URL = '/add';
export const READ_URL = '/read/';
export const UPDATE_URL = '/update/';
export const DELETE_URL = '/delete/';

// Internal Pages.
export const LOGIN_PAGE_URL = '/login';
export const HOME_PAGE_URL = '/home';
export const PROFILE_PAGE_URL = '/profile';

export const EDIT_PROFILE_PAGE_URL = `${PROFILE_PAGE_URL}/edit`;
export const CHANGE_PASSWORD_PAGE_URL = `${PROFILE_PAGE_URL}/change-password`;
export const FORGOTTEN_PASSWORD_PAGE_URL = '/forgotten-password';
export const FORGOTTEN_PASSWORD_CHANGE_PASSWORD_PAGE_URL = '/forgotten-password/change-password/';
export const REPORTS_PAGE_URL = '/admin/reports';
export const CUSTOMERS_PAGE_URL = '/admin/customers';
export const CUSTOMER_PAGE_URL = '/admin/customer';
export const ADD_CUSTOMER_PAGE_URL = CUSTOMERS_PAGE_URL + ADD_URL;
export const UPDATE_CUSTOMER_PAGE_URL = CUSTOMERS_PAGE_URL + UPDATE_URL;
export const DELETE_CUSTOMER_PAGE_URL = CUSTOMERS_PAGE_URL + DELETE_URL;
export const RETAILS_PAGE_URL = '/admin/retails';
export const RETAIL_PAGE_URL = '/admin/retail';
export const ADD_RETAIL_PAGE_URL = RETAILS_PAGE_URL + ADD_URL;
export const UPDATE_RETAIL_PAGE_URL = RETAILS_PAGE_URL + UPDATE_URL;
export const DELETE_RETAIL_PAGE_URL = RETAILS_PAGE_URL + DELETE_URL;
export const PRODUCTS_PAGE_URL = '/admin/products';
export const USER_MANAGEMENT_PAGE_URL = '/admin/user-management';
export const ADD_USER_PAGE_URL = USER_MANAGEMENT_PAGE_URL + ADD_URL;
export const READ_USER_PAGE_URL = USER_MANAGEMENT_PAGE_URL + READ_URL;
export const UPDATE_USER_PAGE_URL = USER_MANAGEMENT_PAGE_URL + UPDATE_URL;
export const NOMENCLATURES_PAGE_URL = '/admin/nomenclatures';
export const NOMENCLATURES_ADD_NOMENCLATURE_PAGE_URL = `${NOMENCLATURES_PAGE_URL}/add`;
export const NOMENCLATURES_READ_NOMENCLATURE_PAGE_URL = `${NOMENCLATURES_PAGE_URL}/read/`;
export const NOMENCLATURES_UPDATE_NOMENCLATURE_PAGE_URL = `${NOMENCLATURES_PAGE_URL}/update/`;
export const NOMENCLATURES_COUNTRIES_PAGE_URL = `${NOMENCLATURES_PAGE_URL}/countries`;
export const SETTINGS_SYSTEM_PAGE_URL = '/admin/settings/system';
export const SETTINGS_SYSTEM_READ_SETTING_PAGE_URL = SETTINGS_SYSTEM_PAGE_URL + READ_URL;
export const SETTINGS_SYSTEM_UPDATE_SETTING_PAGE_URL = SETTINGS_SYSTEM_PAGE_URL + UPDATE_URL;
export const SETTINGS_CUSTOMER_MATRIX_PAGE_URL = '/admin/settings/customer-matrix/';

export const SESSION_TIMEOUT_PAGE_URL = '/login?sessiontimeout=true';

// Nomenclature Types;
export const NOMENCLATURE_TYPE_CUSTOMER_TYPE = 1;
export const NOMENCLATURE_TYPE_BUSINESS_CATEGORY = 2;
export const NOMENCLATURE_TYPE_STORE_PLACEMENT = 3;
export const NOMENCLATURE_TYPE_ONLINE_RATIO = 4;
export const NOMENCLATURE_TYPE_SUPPLIER = 5;
export const NOMENCLATURE_TYPE_COUNTRIES = 6;

export const NOMENCLATURE_CUSTOMER_TYPES_CONTEXT = 'customer-types';
export const NOMENCLATURE_BUSINESS_CATEGORIES_CONTEXT = 'business-categories';
export const NOMENCLATURE_STORE_PLACEMENTS_CONTEXT = 'store-placements';
export const NOMENCLATURE_ONLINE_RATIOS_CONTEXT = 'online-ratios';
export const NOMENCLATURE_SUPPLIERS_CONTEXT = 'suppliers';
export const NOMENCLATURE_PAGES_DATA = [
    {
        tabId: 0,
        type: NOMENCLATURE_TYPE_CUSTOMER_TYPE,
        url: NOMENCLATURE_CUSTOMER_TYPES_CONTEXT,
        name: 'Customer Type'
    }, 
    {
        tabId: 1,
        type: NOMENCLATURE_TYPE_BUSINESS_CATEGORY,
        url: NOMENCLATURE_BUSINESS_CATEGORIES_CONTEXT,
        name: 'Business Category'
    },
    {
        tabId: 2,
        type: NOMENCLATURE_TYPE_STORE_PLACEMENT,
        url: NOMENCLATURE_STORE_PLACEMENTS_CONTEXT,
        name: 'Store Placement'
    },
    {
        tabId: 3,
        type: NOMENCLATURE_TYPE_ONLINE_RATIO,
        url: NOMENCLATURE_ONLINE_RATIOS_CONTEXT,
        name: 'Online Ratio'
    },
    {
        tabId: 4,
        type: NOMENCLATURE_TYPE_SUPPLIER,
        url: NOMENCLATURE_SUPPLIERS_CONTEXT,
        name: 'Supplier'
    }
];

// Supported download file types.
export const FILE_TYPE_EXCEL = 'xlsx';
export const FILE_TYPE_CSV = 'csv'

// User.
export const GLOBAL_ADMIN_USER_ID = -1;

// User status.
export const USER_STATUS_INACTIVE = 0;
export const USER_STATUS_ACTIVE = 1;

// Customer.
export const SYSTEM_CUSTOMER_ID = -1;
// Customer status.
export const CUSTOMER_STATUS_INITIAL = 1;
// Supplier Type (VIP Trading)
export const SUPPLIER_DISTRIBUTOR_NAME_VIP_TRADING = 'VIP Trading';
// Roles.
export const GLOBAL_ADMIN_ROLE = 'GLOBAL_ADMIN';
export const VIP_TRADING_ADMIN_ROLE = 'ADMIN';
export const CUSTOMER_ADMIN_ROLE = 'CUSTOMER_ADMIN';
export const USER_ROLE = 'USER';

// Tabs.
// Users.
export const TAB_USERS_ALL = 0;
// Customers.
export const TAB_CUSTOMERS_CUSTOMERS = 0;
export const TAB_CUSTOMERS_RETAILS = 1;
// Nomenclatures. 
export const TAB_NOMENCLATURES_CUSTOMER_TYPES = 0;
export const TAB_NOMENCLATURES_BUSINESS_CATEGORIES = 1;
export const TAB_NOMENCLATURES_STORE_PLACEMENTS = 2;
export const TAB_NOMENCLATURES_ONLINE_RATIOS = 3;
export const TAB_NOMENCLATURES_SUPPLIERS = 4;
export const TAB_NOMENCLATURES_CUSTOMER_COUNTRIES = 5;
// System Settings. 
export const TAB_SETTINGS_SYSTEM = 0;
export const TAB_SETTINGS_CUSTOMER_MATRIX = 1;

// Local Storage Keys.
export const STORAGE_KEY_USER_DATA = 'key';
export const STORAGE_KEY_MESSAGE = 'message';

// List pages urls.
export const LIST_PAGES_URLS = [
    REPORTS_PAGE_URL,
    CUSTOMERS_PAGE_URL,
    RETAILS_PAGE_URL,
    PRODUCTS_PAGE_URL,
    USER_MANAGEMENT_PAGE_URL,
    NOMENCLATURES_PAGE_URL + '/' + NOMENCLATURE_CUSTOMER_TYPES_CONTEXT,
    NOMENCLATURES_PAGE_URL + '/' + NOMENCLATURE_BUSINESS_CATEGORIES_CONTEXT,
    NOMENCLATURES_PAGE_URL + '/' + NOMENCLATURE_STORE_PLACEMENTS_CONTEXT,
    NOMENCLATURES_PAGE_URL + '/' + NOMENCLATURE_ONLINE_RATIOS_CONTEXT,
    NOMENCLATURES_PAGE_URL + '/' + NOMENCLATURE_SUPPLIERS_CONTEXT,
    NOMENCLATURES_COUNTRIES_PAGE_URL,
    SETTINGS_SYSTEM_PAGE_URL    
];


// Datatable.
export const DATATABLE_PAGE_LENGTH = 10;
export const DATATABLE_START = 0;
export const DATATABLE_ORDER_ASC = 'asc';
export const DATATABLE_ORDER_DESC = 'desc';

export const AXIOS_CODE_NETWORK_ERROR = 'ERR_NETWORK';
export const PAGE_TYPE_LIST = 'list';