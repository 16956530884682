import React, { useRef, useEffect, useState } from 'react';
import $ from 'jquery';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import NomenclatureService from '../../../service/NomenclatureService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faIdCard, faClock, faInfoCircle, faIdBadge, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Utils from '../../../utils/Utils';
import * as Constants from '../../../../constants';
import UserService from '../../../service/UserService';
import { useGenericMessages } from '../../../../contexts/messages/genericMessageContext';

function NomenclaturePage() {
    const { addGenericMessage, clearGenericMessages } = useGenericMessages();
    const navigate = useNavigate();
    const { nomenclatureId } = useParams();
    const inputRef = useRef(null);    
    const location = useLocation();
    const nomenclatureName = Utils.getNomenclatureName(location.pathname);
    const nomenclaturesPageURLWithContext = Utils.getNomenclatureUrlWithContext(location.pathname);
    const [isReadMode, setReadMode] = useState(false);
    const [chevronIcon, setChevronIcon] = useState({closed: true});      
    const [formData, setFormData] = useState({
        type: Utils.getNomenclatureType(location.pathname),
        name: '',        
        description: ''
    });

    useEffect(() => {
        Utils.setActiveNav('headerLinkNomenclatures');
        if (location.pathname.indexOf('read') !== -1) {
            setReadMode(true);
        }	
        if (!isReadMode) {
            inputRef.current.focus();
        }
        if (nomenclatureId) {
            fetchObjectDataById(nomenclatureId);                        
        }         
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    }, [nomenclatureId]);

    const fetchObjectDataById = async (nomenclatureId) => {
        try {
            showLoader();
            const response = await NomenclatureService.getNomenclatureById(nomenclatureId, await Utils.getToken());
            hideLoader();
            if (response?.statusCode === 200) {
                const { id, type, name, description, createdAt, updatedAt } = response.nomenclature;
                setFormData({ id, type, name, description, createdAt, updatedAt });
            } else {
                clearGenericMessages();
                addGenericMessage(Utils.buildMessage(response, 10));
                navigate(nomenclaturesPageURLWithContext);
            }
        } catch (error) {
            console.error(`Error fetching ${nomenclatureName} information:`, error);
            hideLoader();
            if (Utils.isNetworkError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
                UserService.logout(); 
                navigate(Constants.LOGIN_PAGE_URL);
                return;
            }  
            if (!UserService.handleSessionTimeOutError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: `Error fetching ${nomenclatureName} information.`}, 5));
            }            
        }
    };
    
    const handleInputChange = (e) => {
        if (isReadMode) return false;
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        if (isReadMode) return false;
        e.preventDefault();             
        clearGenericMessages();
        try {
            showLoader();
            // Call the add method from NomenclatureService
            const response = await NomenclatureService.addEditNomenclature(nomenclatureId, formData, await Utils.getToken());
            hideLoader();
            if (response) {                
                if (response?.statusCode === 200) {
                    // Clear the form fields after successful save.
                    setFormData({
                        name: '',
                        description: ''
                    });
                    addGenericMessage(Utils.buildMessage(response, 10));
                    navigate(nomenclaturesPageURLWithContext);
                } else {               
                    addGenericMessage(Utils.buildMessage(response));
                }
            } else {
                addGenericMessage(Utils.buildErrorMessage({message: `An error occurred while adding ${nomenclatureName}!`}));
            }            
        } catch (error) {
            console.error(`Error add/edit ${nomenclatureName}:`, error);
            hideLoader();            
            if (Utils.isNetworkError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
                UserService.logout(); 
                navigate(Constants.LOGIN_PAGE_URL);
                return;
            }
            if (!UserService.handleSessionTimeOutError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: `An error occurred while add/edit ${nomenclatureName}!`}));
            }
        }
    };

    const showLoader = () => {
        $('#nomenclatureLoader').show();
        $('#nomenclatureFader').show();
    };
 
    const hideLoader = () => {
        $('#nomenclatureLoader').hide();
        $('#nomenclatureFader').hide();
    };

    const handleCancel = async (e) => {
        clearGenericMessages();
        navigate(nomenclaturesPageURLWithContext);
    };

    const handleEdit = async (e) => {
        clearGenericMessages();
        setReadMode(false);
        navigate(nomenclaturesPageURLWithContext + Constants.UPDATE_URL + nomenclatureId);
    };

    const handleMouseOver = (id) => {        
        $(`#field${id}Icon`).css('color', '#2c95ff');
        if ('Metadata' === id) {
            $(`#field${id}Icon`).css('color', '#fff');
            $(`#field${id}IconChevron`).css('color', '#fff');
        }  
    };
    const handleMouseOut = (id) => {
        $(`#field${id}Icon`).css('color', '#999');
        if ('Metadata' === id) {
            $(`#field${id}IconChevron`).css('color', '#2c95ff');
            if (!chevronIcon.closed) {
                $(`#field${id}Icon`).css('color', '#fff');
                $(`#field${id}IconChevron`).css('color', '#fff');
            }
        }  
    };
    const toggleMetadata = () => {
        $('#metadataContents').slideToggle();     
        if (chevronIcon.closed) {
            $('#metadataLabel').attr('class', 'form-fields-metadata-label form-fields-metadata-label-active');
            $('#fieldMetadataIconChevron').css('color', '#fff');
            $('#fieldMetadataIconChevron').css('transform', 'rotate(180deg)');
        } else {
            $('#metadataLabel').attr('class', 'form-fields-metadata-label');
            $('#fieldMetadataIconChevron').css('transform', '');
        }            
        setChevronIcon((chevronIcon.closed) ? {closed: false} : {closed: true});  
    }

    return (
        <div>
            <div id="customerCategoryPageContainer" className='form-page-container'>
                <div className='form-body without-side'>
                    <div className="row">
                        <div className="img-holder">
                            <div className="bg"></div>
                            <div className="info-holder">
                                <img src="/images/login_bg_graphic.svg" alt="" />
                            </div>
                        </div>
                        <div className="form-holder">
                            <div className="form-content">
                                <div className="form-items">
                                    <div className="form-icon"> 
                                        <div className="icon-holder">
                                            <FontAwesomeIcon icon={faTag} />
                                        </div>
                                    </div>
                                    <h3 className="form-title-center">{nomenclatureId ? (isReadMode ? 'Preview' : 'Edit') : 'Add'} {nomenclatureName}</h3>
                                    <div className='form-body-fields-wrapper'>
                                        <form onSubmit={handleSubmit}>
                                            <div id='nomenclatureLoader' className='component-loader' style={{display: 'none'}}></div>
                                            <div id='nomenclatureFader' className='component-fader' style={{display: 'none'}}></div>
                                            <div className='form-body-fields'>
                                                <div className='form-body-col1'>
                                                    <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Name')} onMouseOut={() => handleMouseOut('Name')}><strong><FontAwesomeIcon id="fieldNameIcon" icon={faIdCard} /> Name* :</strong><br/><div className='field-value'><input ref={inputRef} className="form-control" type="text" name="name" value={formData?.name || ''} onChange={handleInputChange} required readOnly={isReadMode}/></div></div>
                                                    <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Description')} onMouseOut={() => handleMouseOut('Description')}><FontAwesomeIcon id="fieldDescriptionIcon" icon={faInfoCircle} />Description:<br/><div className='field-value'><textarea className="form-control" name="description" value={formData?.description || ''} onChange={handleInputChange} maxLength={250} style={{minHeight: '60px'}}/></div></div>
                                                    { nomenclatureId &&
                                                    <div className='form-fields-metadata'>
                                                        <div id="metadataLabel" className='form-fields-metadata-label' onClick={() => { toggleMetadata() }}  onMouseOver={() => handleMouseOver('Metadata')} onMouseOut={(e) => handleMouseOut('Metadata')}><strong><FontAwesomeIcon id="fieldMetadataIcon" icon={faInfoCircle} />Metadata </strong><span className='metadata-chevron-icon'><FontAwesomeIcon id="fieldMetadataIconChevron" icon={faChevronUp} /></span></div>
                                                        <div id="metadataContents" className='form-fields-metadata-contents'>
                                                            <div onMouseOver={() => handleMouseOver('RecordId')} onMouseOut={() => handleMouseOut('RecordId')}><FontAwesomeIcon id="fieldRecordIdIcon" icon={faIdBadge} />Record Id: <br/><div className=''>{formData?.id}</div></div>
                                                            <div onMouseOver={() => handleMouseOver('CreationDate')} onMouseOut={() => handleMouseOut('CreationDate')}><FontAwesomeIcon id="fieldCreationDateIcon" icon={faClock} />Creation Date: <br/><div className='field-value-italic'>{Utils.formatDBDate(formData?.createdAt)}</div></div>
                                                            <div onMouseOver={() => handleMouseOver('LastUpdate')} onMouseOut={() => handleMouseOut('LastUpdate')}><FontAwesomeIcon id="fieldLastUpdateIcon" icon={faClock} />Last Update:<br/><div className='field-value-italic'>{Utils.formatDBDate(formData?.updatedAt)}</div></div>
                                                        </div>
                                                    </div>
                                                    }
                                                </div>       
                                                { !isReadMode &&                                     
                                                <div className="form-button">
                                                    <button className="ibtn ibtn-col1" type="submit">Save</button>
                                                    <button className="ibtn-empty ibtn-col2" onClick={(e) => handleCancel(e)}>Cancel</button>
                                                </div>
                                                }
                                                { isReadMode &&   
                                                <div className="form-button">
                                                    <button className="ibtn ibtn-col1" type="submit" onClick={(e) => handleEdit(e)}>Edit</button>
                                                    <button className="ibtn-empty ibtn-col2" onClick={(e) => handleCancel(e)}>Back</button>
                                                </div>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>       
    );
}

export default NomenclaturePage;