// App.js
import React, {useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HeaderComponent from './components/common/HeaderComponent';
import LoginPage from './components/pages/auth/LoginPage';
import RegistrationPage from './components/pages/auth/RegistrationPage';
import FooterComponent from './components/common/FooterComponent';
import UpdateUser from './components/pages/admin/users/UpdateUser';

import UserManagementPage from './components/pages/admin/users/UserManagementPage';
import ProfilePage from './components/pages/user/ProfilePage';
import CustomersPage from './components/pages/admin/customers/CustomersPage';
import NomenclaturesPage from './components/pages/admin/nomenclatures/NomenclaturesPage';
import * as buffer from "buffer";
import * as Constants from './constants'
import Utils from './components/utils/Utils';
import CountriesPage from './components/pages/admin/nomenclatures/CountriesPage';
import NomenclaturePage from './components/pages/admin/nomenclatures/NomenclaturePage';
import GenericMessagesProvider from './contexts/messages/genericMessageContext';
import { AppContextProvider } from './contexts/appContext';
import ChangePassword from './components/pages/user/ChangePassword';
import ForgottenPasswordRequest from './components/pages/public/ForgottenPasswordRequest';
import AddUser from './components/pages/admin/users/AddUser';
import ForgottenPasswordChangePassword from './components/pages/public/ForgottenPasswordChangePassword';
import SystemSettingsPage from './components/pages/admin/settings/SystemSettingsPage';
import CustomerMatrixPage from './components/pages/admin/settings/CustomerMatrixPage';
import SystemSettingPage from './components/pages/admin/settings/SystemSettingPage';
import CustomerPage from './components/pages/admin/customers/CustomerPage';
import HomePage from './components/pages/admin/HomePage';
import ReportsPage from './components/pages/admin/reports/ReportsPage';
import ProductsPage from './components/pages/admin/products/ProductsPage'; 
import RetailsPage from './components/pages/admin/customers/RetailsPage';
import RetailPage from './components/pages/admin/customers/RetailPage';
window.Buffer = buffer.Buffer;

function App() {
  const [userInfo, setUserInfo] = useState(null);	 
  const [isLoading, setLoading] = useState(true);
  
    
  useEffect(() => {	
        const fetchUserInfo = async () => {
            setUserInfo(await Utils.getUserData());   
            setLoading(false);         
        };
        fetchUserInfo();
	}, []);

  if(isLoading){
    return (
      <BrowserRouter>
      <div className="App">        
        <HeaderComponent />
        <div className="form-items">
          <div className='form-body-fields-wrapper'>
            <div id='profileComponentLoader' className='component-loader' style={{top: '200px'}}></div>        
          </div>
        </div>
        <FooterComponent />
      </div>
      </BrowserRouter>
    );
  } else if(!isLoading){      
    return (
      <BrowserRouter>
        <div className='App'>
          <HeaderComponent />
          <AppContextProvider>
          <GenericMessagesProvider>
            <div className='content'>
              <div className='content-body'>
              <Routes>
                <Route exact path="/" element={<LoginPage />} />
                <Route exact path={Constants.LOGIN_PAGE_URL} element={<LoginPage />} />
                <Route path={Constants.PROFILE_PAGE_URL} element={<ProfilePage />} />
                <Route path={Constants.EDIT_PROFILE_PAGE_URL} element={<UpdateUser />} />
                <Route path={Constants.CHANGE_PASSWORD_PAGE_URL} element={<ChangePassword />} />
                <Route path={Constants.FORGOTTEN_PASSWORD_PAGE_URL} element={<ForgottenPasswordRequest />} />
                <Route path={Constants.FORGOTTEN_PASSWORD_CHANGE_PASSWORD_PAGE_URL + ':code'} element={<ForgottenPasswordChangePassword />} />
                <Route path="/register" element={<RegistrationPage />} />
                {/* Check if user is authenticated and admin before rendering admin-only routes */}
                {userInfo?.isAdmin && (
                  <>
                    <Route path={Constants.HOME_PAGE_URL} element={<HomePage />} />
                    <Route path={Constants.REPORTS_PAGE_URL} element={<ReportsPage />} />
                    <Route path={Constants.PRODUCTS_PAGE_URL} element={<ProductsPage />} />
                    <Route path={Constants.RETAILS_PAGE_URL} element={<RetailsPage />} />
                    <Route path={Constants.RETAILS_PAGE_URL + Constants.ADD_URL} element={<RetailPage />} />
                    <Route path={Constants.RETAILS_PAGE_URL + Constants.READ_URL + ':retailId'} element={<RetailPage />} />
                    <Route path={Constants.RETAILS_PAGE_URL + Constants.UPDATE_URL + ':retailId'} element={<RetailPage />} />
                    <Route path={Constants.CUSTOMERS_PAGE_URL} element={<CustomersPage />} />
                    <Route path={Constants.CUSTOMERS_PAGE_URL + Constants.ADD_URL} element={<CustomerPage />} />
                    <Route path={Constants.CUSTOMERS_PAGE_URL + Constants.READ_URL + ':customerId'} element={<CustomerPage />} />
                    <Route path={Constants.CUSTOMERS_PAGE_URL + Constants.UPDATE_URL + ':customerId'} element={<CustomerPage />} />
                    <Route path="/admin/user-management" element={<UserManagementPage />} />
                    <Route path="/admin/user-management/add" element={<AddUser />} />
                    <Route path="/admin/user-management/read/:userId" element={<UpdateUser />} />
                    <Route path="/admin/user-management/update/:userId" element={<UpdateUser />} />
                    <Route path="/admin/nomenclatures" element={<NomenclaturesPage />} />
                    {Constants.NOMENCLATURE_PAGES_DATA.map((nomenclature) => (
                      <Route path={Constants.NOMENCLATURES_PAGE_URL + '/' + nomenclature.url} element={<NomenclaturesPage />} key={nomenclature.url} />
                    ))}       
                    {Constants.NOMENCLATURE_PAGES_DATA.map((nomenclature) => (
                      <Route path={Constants.NOMENCLATURES_PAGE_URL + '/' + nomenclature.url + Constants.ADD_URL} element={<NomenclaturePage />} key={nomenclature.url}/>
                    ))}  
                     {Constants.NOMENCLATURE_PAGES_DATA.map((nomenclature) => (
                      <Route path={Constants.NOMENCLATURES_PAGE_URL + '/' + nomenclature.url + Constants.READ_URL + ':nomenclatureId'} element={<NomenclaturePage />} key={nomenclature.url}/>
                    ))}                
                     {Constants.NOMENCLATURE_PAGES_DATA.map((nomenclature) => (
                      <Route path={Constants.NOMENCLATURES_PAGE_URL + '/' + nomenclature.url + Constants.UPDATE_URL + ':nomenclatureId'} element={<NomenclaturePage />} key={nomenclature.url}/>
                    ))}                    
                    <Route path="/admin/nomenclatures/countries" element={<CountriesPage />} />
                    <Route path="/admin/settings/system" element={<SystemSettingsPage />} />
                    <Route path="/admin/settings/system/read/:systemSettingId" element={<SystemSettingPage />} />
                    <Route path="/admin/settings/system/update/:systemSettingId" element={<SystemSettingPage />} />
                    <Route path="/admin/settings/customer-matrix" element={<CustomerMatrixPage />} />                    
                  </>
                )}
                <Route path="*" element={<Navigate to="/login?redirect=true" />} />‰
              </Routes>
              </div>
              <div id='pageContentFader' className='content-fader'></div>
              <div id='pageContentLoader' className='content-loader'></div>
            </div>
          </GenericMessagesProvider>
          </AppContextProvider>
          <FooterComponent />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;