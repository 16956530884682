import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/dist/themes/base/jquery-ui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5';
import {useNavigate} from "react-router-dom";
import Utils from '../../../utils/Utils';
import * as Constants from '../../../../constants';
import UserService from '../../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faStickyNote, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useGenericMessages } from '../../../../contexts/messages/genericMessageContext';
import DownloadListComponent from '../../../common/DownloadListComponent';
import { isEmpty, isInt } from 'validator';

function UserManagementPage() {
	const { addGenericMessage, clearGenericMessages } = useGenericMessages();
	const navigate = useNavigate();
	const [filterData, setFilterData] = useState({
		filterId: '',
		filterEmail: '',
		filterName: '',
		filterRoles: [],
		filterRole: '',
		filterCustomers: [],
		filterCustomer: '',
		filterStatusList: [],
		filterStatus: ''
	});
	const ORDER_COLUMN = 0;
	const filterNS = 'users';

	useEffect(() => {		
		fetchFilterData();	
		
		const dataUrl = document.getElementById('headerLinkUserManagement')?.dataset.url;
		if (Constants.USER_MANAGEMENT_PAGE_URL !== dataUrl) {
			navigate(dataUrl);
			return;			
		}

		Utils.setActiveNav('headerLinkUserManagement');				
		$( "#usersTabs" ).tabs({
			active: Utils.getActiveTab('usersTabs')
		});		

		$.extend($.fn.dataTableExt.oSort, {
			"extract-date-pre": function(value) {
				const datetime = value.split(' ');
				const date = datetime[0].split('.');
				return Date.parse(date[1] + '/' + date[0] + '/' + date[2] + ' ' + datetime[1]);
			},
			"extract-date-asc": function(a, b) {
				return ((a < b) ? -1 : ((a > b) ? 1 : 0));
			},
			"extract-date-desc": function(a, b) {
				return ((a < b) ? 1 : ((a > b) ? -1 : 0));
			}
		});	
		
		const dataTable = initDataTable();
		
		return () => {
			try {
				dataTable.destroy();	
			} catch (error) {}			
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps		
	}, [navigate]);
		
	const fetchFilterData = async () => {
		try {
			const response = await UserService.getUsersFilters(await Utils.getToken(), filterNS);
			if (response?.statusCode === 200) {
				setFilterData(response);
				updateFilterData('filterId', Utils.getFilterValue(filterNS, 'filterId'));
				updateFilterData('filterEmail', Utils.getFilterValue(filterNS, 'filterEmail'));
				updateFilterData('filterName', Utils.getFilterValue(filterNS, 'filterName'));
				updateFilterData('filterRole', Utils.getFilterValue(filterNS, 'filterRole'));
				updateFilterData('filterCustomer', Utils.getFilterValue(filterNS, 'filterCustomer'));
				updateFilterData('filterStatus', Utils.getFilterValue(filterNS, 'filterStatus'));
			}
		} catch (error) {
		  console.error('Error fetching filter data:', error);
			if (Utils.isNetworkError(error)) {
				addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
				UserService.logout();
				navigate(Constants.LOGIN_PAGE_URL);
				return;
			}
			UserService.handleSessionTimeOutError(error);
		}
	};

	const initDataTable = async () => {
		const token = await Utils.getToken();
		try {			
			if ($.fn.DataTable.isDataTable("#datatable")) {
				$('#datatable').DataTable().clear().destroy();
			}
			$.fn.dataTable.ext.errMode = 'throw';
			const dataTable = $('#datatable').DataTable({
				serverSide : true,
				processing : true,
				searching: false,
				autoWidth: false,
				order: [[ Utils.getDataTableValue(filterNS, 'orderColumn') ?? ORDER_COLUMN, Utils.getDataTableValue(filterNS, 'order') ??  Constants.DATATABLE_ORDER_ASC ]],
				lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
				displayStart: Utils.getDataTableValue(filterNS, 'start') ?? Constants.DATATABLE_START, 
	    		pageLength: Utils.getDataTableValue(filterNS, 'pageLength') ?? Constants.DATATABLE_PAGE_LENGTH,
				ajax : {
					url : Constants.USERS_LIST_URL,
					type : 'POST',
					beforeSend: function(xhr) {
						xhr.setRequestHeader("Authorization", "Bearer " + token);
					},
					statusCode: {
						403: function() { 	
							UserService.sessionTimeOut();							
						}					
					},
					error: function(XMLHttpRequest, textStatus, errorThrown) {
						console.log(textStatus);
					},
					dataSrc: function ( json ) {
						Utils.setDataTableValue(filterNS, 'start', json.start);							
						Utils.setDataTableValue(filterNS, 'pageLength', json.pageLength);
						Utils.setDataTableValue(filterNS, 'orderColumn', json.orderColumn);
						Utils.setDataTableValue(filterNS, 'order', json.order);
						return json.data;
					},
					data: {
						filterId: Utils.getFilterValue(filterNS, 'filterId'),
						filterEmail: Utils.getFilterValue(filterNS, 'filterEmail'),
						filterName: Utils.getFilterValue(filterNS, 'filterName'),
						filterRole: Utils.getFilterValue(filterNS, 'filterRole'),
						filterCustomer: Utils.getFilterValue(filterNS, 'filterCustomer'),
						filterStatus: Utils.getFilterValue(filterNS, 'filterStatus')						
					}
				},
				columnDefs: [
					{
						"targets": 0, //id.
						"className": "text-right",
						"createdCell": (td, cellData, rowData, row, col) =>							
							createRoot(td).render(	
								<button className='table-cell-id' onClick={() => {return handleReadAction(cellData)}}>{cellData}</button>
							) 
					}, 
					{
						"targets": 1, //name.
						"className": "text-center"            
					},
					{
						"targets": 2, //email.
						"className": "text-center"                                             
					},
					{
						"targets": 3, //role.
						"className": "text-center"                                             
					},
					{
						"targets": 4, //customer.
						"className": "text-center"                                             
					},					
					{
						"targets": 5, //status.
						"className": "text-center"                                             
					},
					{
						"targets": 6, //date_created.
						"className": "text-center",
						"type": "extract-date",
						// "width": "180px",
						"render": function ( data, type, row ) { 
							return Utils.formatDBDate(data);
						}                                              
					},
					{
						"targets": 7, //date_modified.
						"className": "text-center",
						"type": "extract-date",
						// "width": "180px",
						"render": function ( data, type, row ) { 
							return Utils.formatDBDate(data);
						}                                             
					},	
					{						
						"createdCell": (td, cellData, rowData, row, col) =>							
							createRoot(td).render(	
								<div className='list-icons'>
									<FontAwesomeIcon onClick={() => {handleReadAction(cellData)}} className='circle-icon list-icon-read' icon={faStickyNote} />							
									<FontAwesomeIcon onClick={() => {handleEditAction(cellData)}} className='circle-icon list-icon-edit' icon={faPencil} />							
									<FontAwesomeIcon onClick={() => {handleDeleteAction(cellData, rowData[1])}} className={computeDeleteIconStyle(cellData)} icon={faTrashAlt} />							
								</div>
							)
						,
						"className": "text-right dt-nowrap list-cell-actions",
						"targets": 8,
						"width": "180px",
						"orderable": false
					}// operations				
				]				
			});
			return dataTable;
		} catch (error) {
		  console.error('Error applying filters:', error);
		}
	}

	const computeDeleteIconStyle = (id) => {
		return `circle-icon list-icon-${(Constants.GLOBAL_ADMIN_USER_ID !== id) ? 'delete' : 'disabled'}`;		
	}
	const handleReadAction = (id) => {
		clearGenericMessages();
		navigate(Constants.READ_USER_PAGE_URL + id);
	}
	const handleEditAction = (id) => {
		clearGenericMessages();
		navigate(Constants.UPDATE_USER_PAGE_URL + id);
	}
	const handleDeleteAction = async (id, name) => {
		try {
			if (Constants.GLOBAL_ADMIN_USER_ID === id) {
				alert('You are not authorized for this operation!');
				return false;
			}
			// Prompt for confirmation before deleting the user
			const confirmDelete = window.confirm('Are you sure you want to delete "' + name + '"?');
	  
			if (confirmDelete) {
				Utils.showPageLoader();
			  	const response = await UserService.deleteUser(id, await Utils.getToken());
				addGenericMessage(Utils.buildMessage(response, 5));
				Utils.hidePageLoader();
				return await initDataTable();
			}
		  } catch (error) {
			console.error('Error deleting user:', error);
			if (Utils.isNetworkError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
                UserService.logout(); 
                navigate(Constants.LOGIN_PAGE_URL);
                return;
            }			
			if (!UserService.handleSessionTimeOutError(error)) {
				addGenericMessage(Utils.buildErrorMessage({message: 'An error occurred while deleting user!'}));
			}
		  }
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		Utils.setFilterValue(filterNS, name, value);
		setFilterData((prevFilterData) => ({
			...prevFilterData,
			[name]: value
		}));			
	};

	const updateFilterData = (name, value) => {
		setFilterData((prevFilterData) => ({
			...prevFilterData,
			[name]: value
		}));	
	}

	const handleFilterSubmit = async (e) => {
		e.preventDefault();
		if (!isEmpty(filterData.filterId + '') && !isInt(filterData.filterId + '')) {
			alert('Please enter number for "№" field.');
			return;
		}
		return await initDataTable();
	};
	
	const prepareParametersForListDownload = () => {
		const orderColumn = Utils.getDataTableValue(filterNS, 'orderColumn') ?? ORDER_COLUMN;
		const order = Utils.getDataTableValue(filterNS, 'order') ?? Constants.DATATABLE_ORDER_ASC;
		return `orderColumn=${orderColumn}&order=${order}&filterId=${filterData.filterId ?? ''}&filterEmail=${filterData.filterEmail ?? ''}&filterName=${filterData.filterName ?? ''}&filterRole=${filterData.filterRole ?? ''}&filterCustomer=${filterData.filterCustomer ?? ''}&filterStatus=${filterData.filterStatus ?? ''}`;
	};

	const navigateToAddUserPage = () => {
		clearGenericMessages();
		navigate(Constants.ADD_USER_PAGE_URL);
	};

	const setActiveTab = (number) => {  
		Utils.toLocalStorage('usersTabs', number);
    };

  	return (
		<div>
			<div className="users main-wrapper list-page">
				<div className="dt-add-button-wrapper"> 
					<div className='tabs-action-buttons filter-search-button'><button onClick={() => { navigateToAddUserPage() }} className="ibtn ibtn-full" alt=" Add User " title=" Add User " type='button'> + Add User </button></div>
					<DownloadListComponent url={Constants.USERS_DOWNLOAD_LIST_URL} parameters={prepareParametersForListDownload()} fileName='listReport_Users' />
				</div> 
				<div id="usersTabs">
					<ul>
						<li><a href={'#tabs-' + Constants.TAB_USERS_ALL} onClick={() => { setActiveTab(Constants.TAB_USERS_ALL) }} title="All Users">Users</a></li>
					</ul>
					<div id={'tabs-' + Constants.TAB_USERS_ALL}>
						<div>
							<div className="card-body">
							<form onSubmit={handleFilterSubmit}>
								<div className="search-filter">
									<div className="filter-field">№ <input name="filterId" value={filterData.filterId || ''} onChange={handleInputChange} type="text" className="filter-field-xxs"/><span/></div>
									<div className="filter-field">Email <input name="filterEmail" value={filterData.filterEmail || ''} onChange={handleInputChange} type="text" className="filter-field"/><span/></div>
									<div className="filter-field">Name <input name="filterName" value={filterData.filterName || ''} onChange={handleInputChange} type="text" className="filter-field-xl" placeholder='or phone or position'/><span/></div>																
									
									<div className="filter-field">Role
										<select name="filterRole" value={filterData.filterRole || ''} onChange={handleInputChange}>
											<option key="" value="">All</option>
											{filterData.filterRoles.map((role) => (
												<option key={role} value={role}>{role}</option>
											))} 
										</select><span/>
									</div>	
									<div className="filter-field">Customer
										<select name="filterCustomer" value={filterData.filterCustomer || ''} onChange={handleInputChange}>
											<option key="" value="">All</option>
											{filterData.filterCustomers.map((customer) => ( (customer.id >= 0) &&
												<option key={customer.id} value={customer.id}>{customer.name}</option>
											))} 
										</select><span/>
									</div>		
									<div className="filter-field">Status
										<select name="filterStatus" value={filterData.filterStatus || ''} onChange={handleInputChange}>
											<option key="" value="">All</option>
											{filterData.filterStatusList.map((status) => ( (status.id >= 0) &&
												<option key={status.id} value={status.id}>{status.name}</option>
											))} 
										</select><span/>
									</div>	
									<div className='filter-search-button'><button className="ibtn ibtn-full" type="submit">Search</button></div>
								</div>	
							</form>
								<table className="table table-bordered" id="datatable">
									<thead>
										<tr>
											<th className="text-center">№</th>
											<th>Name</th>
											<th>Email</th>
											<th>Role</th>
											<th>Customer</th>											
											<th>Status</th>
											<th>Date Created</th>
											<th>Last Update</th>	
											<th className='list-header-actions-label'>Actions</th>
										</tr>
									</thead>
									<tbody></tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>	
	);
}

export default UserManagementPage;