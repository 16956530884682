import React, { useRef, useState, useEffect } from 'react';
import $ from 'jquery';
import isEmail from 'validator/es/lib/isEmail';
import { useNavigate } from 'react-router-dom';
import UserService from '../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons'
import Utils from '../../utils/Utils';
import * as Constants from '../../../constants';
import { useGenericMessages } from '../../../contexts/messages/genericMessageContext';

function ForgottenPasswordRequest() {
  const { addGenericMessage, clearGenericMessages } = useGenericMessages();  
  const navigate = useNavigate();
  const inputRef = useRef(null);  
  const [formData, setFormData] = useState({
    email: ''    
  });

  useEffect(() => {
    inputRef.current.focus();
    hideLoader();
  }, []); 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevformData) => ({
      ...prevformData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {        
    e.preventDefault();
    clearGenericMessages(); 
    if (!isEmail(formData.email)) {
      addGenericMessage(Utils.buildErrorMessage({message: 'Please enter valid email.'}, 10));
      inputRef.current.focus();
      inputRef.current.style.border = '1px solid #cc0000';
      scrollToTop();
      return;
    }         
    showLoader();
    try {
        const response = await UserService.sendChangePasswordRequest(formData.email.trim());
        if (response?.statusCode === 200) {
          addGenericMessage(Utils.buildMessage(response, 30));
          navigate(Constants.LOGIN_PAGE_URL);        
          return;
        }
        hideLoader();
        addGenericMessage(Utils.buildMessage(response, 10));
        scrollToTop();
    } catch (error) {
      console.error('Error request change password:', error);
      hideLoader();
      if (Utils.isNetworkError(error)) {
        addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
        navigate(Constants.LOGIN_PAGE_URL);
        return;
      }      
      if (!UserService.handleSessionTimeOutError(error)) {
				addGenericMessage(Utils.buildErrorMessage({message: 'Error sending change password request!'}));
        scrollToTop();
			}
    }
  };

  const showLoader = () => {
      $('#loginComponentLoader').show();
      $('#loginComponentFader').show();
  };

  const hideLoader = () => {
      $('#loginComponentLoader').hide();
      $('#loginComponentFader').hide();
  };

  const handleMouseOver = (e, id) => {        
    $(`#field${id}Icon`).css('color', '#2c95ff');
  };
  const handleMouseOut = (e, id) => {
      $(`#field${id}Icon`).css('color', '#999');
  };
  
  const handleCancel = (e) => {
      navigate(Constants.LOGIN_PAGE_URL);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    }); 
  }

  return (
    <div>
        <div id="forgottenPasswordPageContainer" className='login-page-container forgotten-password-page-container'>   
            <div className='form-body without-side'>
                <div className="row">
                    <div className="img-holder">
                        <div className="bg"></div>
                        <div className="info-holder">
                            <img src="/images/login_bg_graphic.svg" alt="" />
                        </div>
                    </div>
                    <div className="form-holder">
                        <div className="form-content">
                            <div className="form-items">
                                <div className="form-icon"> 
                                    <div className="icon-holder">
                                        <FontAwesomeIcon icon={faKey} />
                                    </div>
                                </div>
                                <h3 className="form-title-center">Forgotten Password</h3>
                                <div className='form-body-fields-wrapper'>
                                  <form onSubmit={handleSubmit}>
                                    <div id='loginComponentLoader' className='component-loader'></div>
                                    <div id='loginComponentFader' className='component-fader'></div>
                                    <div className='form-body-fields'>
                                        <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={(e) => handleMouseOver(e, 'Email')} onMouseOut={(e) => handleMouseOut(e, 'Email')}><FontAwesomeIcon id="fieldEmailIcon" icon={faEnvelope} />Email *:<br/><div className='field-value' style={{paddingTop: '4px'}}><input ref={inputRef} className="form-control" type="text" name="email" value={formData?.email} onChange={handleInputChange} required placeholder='Enter your email' autoComplete='E-mail Address'/></div></div>
                                        </div>
                                        <div className="form-button">
                                            <button className="ibtn ibtn-col1" type="submit">Submit</button>
                                            <button className="ibtn-empty ibtn-col2" onClick={(e) => handleCancel(e)}>Home</button>
                                        </div>
                                    </div>
                                  </form>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ForgottenPasswordRequest;