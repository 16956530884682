import axios from "axios";
import * as Constants from '../../constants';

class SettingsService {    

    static async getSystemSettingById(id, token) {
        try {
            const response = await axios.get(Constants.SETTINGS_SYSTEM_GET_SETTING_BY_ID + id,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async editSystemSetting(id, data, token) {
        try {
            const response = await axios.put(Constants.BASE_URL + Constants.SETTINGS_SYSTEM_UPDATE_SETTING_PAGE_URL + id, data, {
                headers: {Authorization: `Bearer ${token}`}
            })
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default SettingsService;
