'use client';

import React, { useState, useEffect } from 'react';
import Utils from '../utils/Utils';

function FooterComponent() {
    const [userInfo, setUserInfo] = useState(null);
    
    useEffect(() => {	
        const fetchUserInfo = async () => {
            setUserInfo(await Utils.getUserData());
          };
          fetchUserInfo();		
	}, []);
    
    return (
        <div>
            <footer className={userInfo ? 'footer' : 'footer footer-anonymous'}>
                <div className="rights"> <div className="p12"> <div>Copyright &copy; {new Date().getFullYear()}  All rights reserved!</div> </div> </div>
            </footer>
        </div>
    );
};

export default FooterComponent;