'use client';

import React from 'react';
import {useNavigate} from "react-router-dom";
import * as Constants from '../../../../constants';
import Utils from '../../../utils/Utils';

function TabsComponent() {
    const navigate = useNavigate();

    const setActivePage = (link) => {
        document.getElementById('headerLinkNomenclatures').dataset.url = link;
        Utils.toSessionStorage('headerLinkNomenclatures', link);
        navigate(link);
    }
    return (
        <ul>
            <li><a href={'#tabs-' + Constants.TAB_NOMENCLATURES_CUSTOMER_TYPES} onClick={() => { setActivePage(Constants.NOMENCLATURES_PAGE_URL + '/' + Constants.NOMENCLATURE_CUSTOMER_TYPES_CONTEXT); }} title="All Customer Types">Customer Types</a></li>
            <li><a href={'#tabs-' + Constants.TAB_NOMENCLATURES_BUSINESS_CATEGORIES} onClick={() => { setActivePage(Constants.NOMENCLATURES_PAGE_URL + '/' + Constants.NOMENCLATURE_BUSINESS_CATEGORIES_CONTEXT); }} title="All Business Categories">Business Categories</a></li>
            <li><a href={'#tabs-' + Constants.TAB_NOMENCLATURES_STORE_PLACEMENTS} onClick={() => { setActivePage(Constants.NOMENCLATURES_PAGE_URL + '/' + Constants.NOMENCLATURE_STORE_PLACEMENTS_CONTEXT); }} title="All Store Placements">Store Placements</a></li>
            <li><a href={'#tabs-' + Constants.TAB_NOMENCLATURES_ONLINE_RATIOS} onClick={() => { setActivePage(Constants.NOMENCLATURES_PAGE_URL + '/' + Constants.NOMENCLATURE_ONLINE_RATIOS_CONTEXT); }} title="All Online Ratios">Online Ratios</a></li>
            <li><a href={'#tabs-' + Constants.TAB_NOMENCLATURES_SUPPLIERS} onClick={() => { setActivePage(Constants.NOMENCLATURES_PAGE_URL + '/' + Constants.NOMENCLATURE_SUPPLIERS_CONTEXT); }} title="All Suppliers">Suppliers</a></li>
            <li><a href={'#tabs-' + Constants.TAB_NOMENCLATURES_CUSTOMER_COUNTRIES} onClick={() => { setActivePage(Constants.NOMENCLATURES_COUNTRIES_PAGE_URL); }} title="All Countries">Countries</a></li>
        </ul>
    );
};

export default TabsComponent;