const makeKey = async (key) => {
    return await crypto.subtle.importKey(
        "raw",
        Buffer.from(key, "base64"),
        {
            name: "AES-GCM",
            length: 256,
        },
        true,
        ["encrypt", "decrypt"]
    );
};

export const encryptSymmetric = async (plaintext) => {
    const key = process.env.REACT_APP_USER_SESSION_ENCRYPTION_KEY;
    const iv = crypto.getRandomValues(new Uint8Array(12));
    const encodedPlaintext = new TextEncoder().encode(plaintext);
    const secretKey = await makeKey(key); // using the 
  
    const ciphertext = await crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv,
      },
      secretKey,
      encodedPlaintext
    );
  
    return {
      ciphertext: Buffer.from(ciphertext).toString("base64"),
      iv: Buffer.from(iv).toString("base64"),
    };
};

export const decryptSymmetric = async (ciphertext, iv) => {
    const key = process.env.REACT_APP_USER_SESSION_ENCRYPTION_KEY;
    const secretKey = await makeKey(key);
    const cleartext = await crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: Buffer.from(iv, "base64"),
      },
      secretKey,
      Buffer.from(ciphertext, "base64")
    );
    return new TextDecoder().decode(cleartext);
};

export const encrypterSaveToLocalStorage = async (name, data) => {
    const stringified_data = JSON.stringify(data);
    const encrypted_data = await encryptSymmetric(stringified_data);
    localStorage.setItem(name, JSON.stringify(encrypted_data));
};

export const encrypterGetFromLocalStorage = async (name) => {
    const raw_data = localStorage.getItem(name);
    if (!raw_data) return null;
    const encrypted_data = JSON.parse(raw_data);
    const decrypted_data = await decryptSymmetric(
      encrypted_data.ciphertext,
      encrypted_data.iv
    );
  //console.log(decrypted_data);
    return JSON.parse(decrypted_data);    
};