import axios from "axios";
import * as Constants from '../../constants';
import Utils from "../utils/Utils";

class UserService {
    static async login(email, password) {
        try {
            const response = await axios.post(Constants.LOGIN_URL, {email, password});
            console.log(response.data.token); 
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async register(userData, token) {
        try {
            const response = await axios.post(`${Constants.BASE_URL}/auth/register`, userData, 
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

     static async getUsersFilters(token, filterNS) {
        try {
            const response = await axios.get(`${Constants.BASE_URL}/admin/users/filters`,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            if (response.data) {
                response.data.filterId = Utils.getFilterValue(filterNS, 'filterId') || '';
                response.data.filterEmail = Utils.getFilterValue(filterNS, 'filterEmail') || '';
                response.data.filterName  = Utils.getFilterValue(filterNS, 'filterName') || '';
                response.data.filterRole = Utils.getFilterValue(filterNS, 'filterRole') || '';
                response.data.filterCustomer = Utils.getFilterValue(filterNS, 'filterCustomer') || '';
                response.data.filterStatus = Utils.getFilterValue(filterNS, 'filterStatus') || '';                
            }
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async getAllUsers(token) {
        try {
            const response = await axios.get(Constants.USERS_LIST_URL,
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async getProfile(token) {
        try {
            const response = await axios.get(Constants.PROFLE_URL,
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async getProfileForEdit(token) {
        try {
            const response = await axios.get(`${Constants.PROFLE_URL}?forEdit=true`,
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async updateMyProfile(userData, token) {
        try {
            const response = await axios.put(Constants.EDIT_PROFLE_URL, userData,
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async changePassword(userData, token) {
        try {
            const response = await axios.put(Constants.CHANGE_PASSWORD_URL, userData,
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async sendChangePasswordRequest(email) {
        try {
            const response = await axios.post(Constants.SEND_CHANGE_PASSWORD_REQUEST_URL, {email});
            return response.data;
        } catch (error) {
            throw error;
        }
    };  
    
    static async getUserByForgottenPasswordCode(code) {
        try {
            const response = await axios.get(Constants.GET_USER_BY_FORGOTTEN_PASSWORD_CODE_REQUEST_URL + code);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async forgottenPasswordChangePassword(userData) {
        try {
            const response = await axios.put(Constants.FORGOTTEN_PASSWORD_CHANGE_PASSWORD_REQUEST_URL, userData);
            return response.data;
        } catch (error) {
            throw error;
        }
    };


    static async getUserById(userId, token) {
        try {
            const response = await axios.get(`${Constants.USERS_LIST_URL}/${userId}`,
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async addUser(userData, token) {
        try {
            const response = await axios.post(Constants.ADD_USER_URL, userData, 
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };


    static async addUserGetCustomers(token) {
        try {
            const response = await axios.get(Constants.ADD_USER_GET_CUSTOMERS_URL,
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };


    static async updateUser(userId, userData, token) {
        try {
            const response = await axios.put(Constants.EDIT_USER_URL + userId, userData,
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async deleteUser(userId, token) {
        try {
            const response = await axios.delete(Constants.DELETE_USER_URL + userId,
            {
                headers: {Authorization: `Bearer ${token}`}
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    /** Authentication Checker */
    static async isAuthenticated() {
        const token = await Utils.getToken();
        return !!token;
    };

    static logout() {
        Utils.clearStorage();       
    };
    
    static handleSessionTimeOutError(error) {
        if (Utils.isStatusCode403(error)) {
            this.sessionTimeOut();
            return true;
        } 
        return false;
    };
    static sessionTimeOut() {
        this.logout();
        document.location.href = Constants.SESSION_TIMEOUT_PAGE_URL; 
    };
    // This is a HARD logout!
    static logoutUser() {
        this.logout();
        document.location.href = Constants.LOGIN_PAGE_URL; 
    };
}

export default UserService;
