import * as Constants from '../../constants';
import { encrypterSaveToLocalStorage } from '../../encrypter';
import { encrypterGetFromLocalStorage } from '../../encrypter';

class Utils {

    static storeUserData = async (userData) => {
        if (!userData) {
            return;
        }
        const userSessionData = {
            id: userData.id,
            token: userData.token,
            role: userData.role,
            name: userData.name,
            phone: userData.phone,
            address: userData.address,
            position: userData.position,
            isUser: this.isUser(userData.role), 
            isGlobalAdmin: this.isGlobalAdmin(userData.role), 
            isVIPTradingAdmin: this.isVIPTradingAdmin(userData.role), 
            isAdmin: this.isAdmin(userData.role),
            isCustomerAdmin: this.isCustomerAdmin(userData.role)
        };
        await encrypterSaveToLocalStorage(Constants.STORAGE_KEY_USER_DATA, userSessionData);        
    };

    static getUserData = async () => {
        return await encrypterGetFromLocalStorage(Constants.STORAGE_KEY_USER_DATA);
    };     
    static clearGenericMessages () {
        const cls = document.getElementsByClassName('message-close-icon');
		if (cls?.length > 0) {
			for (let element of cls) {				
                try {
                element.click();  
                } catch (error) {}				
			}			
        }
    }; 
    static hasGenericMessage () {
        const cls = document.getElementsByClassName('message-close-icon');
		return cls?.length > 0;
    }; 
    static toLocalStorage(key, value) {
        localStorage.setItem(key, value);
    };
    static fromLocalStorage(key) {
        return localStorage.getItem(key);
    };   
    static clearStorage() {
        localStorage.clear();
        sessionStorage.clear();
    };
    static toSessionStorage(key, value) {
        sessionStorage.setItem(key, value);
    };
    static fromSessionStorage(key) {
        return sessionStorage.getItem(key);
    }; 
    static buildMessage(response, timeout = 0) { 
        return {
            message: response?.message ?? (response?.error ?? 'Internal Server Error.'),
            statusCode: response?.statusCode ?? 500,
            timeout: timeout
        } 
    };
    static buildSuccessMessage(response, timeout = 0) {        
        return {
            message: response?.message ?? response?.error,
            statusCode: 200,
            timeout: timeout
        } 
    };
    static buildErrorMessage(response, timeout = 0) {        
        return {
            message: response?.message ?? response?.error,
            statusCode: 500,
            timeout: timeout
        } 
    };
    static getActiveTab(key, active) {
        const activeTab = this.fromLocalStorage(key);
        if (isNaN(activeTab)) {
            return active ?? 0;
        }
        return activeTab;
    };
    static getFilterValue(ns, key) {
        // console.log(`filterValue[${key}]=${this.fromLocalStorage(`filters_${ns}_${key}`)}`);
        return this.fromLocalStorage(`filters_${ns}_${key}`);
    };
    static setFilterValue(ns, key, value) {
        // console.log(`filterValue[${key}]=${value}`);
        return this.toLocalStorage(`filters_${ns}_${key}`, value);
    };
    static getDataTableValue(ns, key) {
        // console.log(`dataTableValue[${key}]=${this.fromLocalStorage(`datatable_${ns}_${key}`)}`);
        // if (this.fromLocalStorage(`datatable_${ns}_${key}`) === 'undefined') return null;
        return this.fromLocalStorage(`datatable_${ns}_${key}`);
    };
    static setDataTableValue(ns, key, value) {
        // console.log(`filterValue[${key}]=${value}`);
        return this.toLocalStorage(`datatable_${ns}_${key}`, value !== 'undefined' ? value : null);
    };
    static getToken = async () => {
        const userSessionData = await encrypterGetFromLocalStorage(Constants.STORAGE_KEY_USER_DATA);
        return userSessionData?.token ?? null;
        //return localStorage.getItem(Constants.STORAGE_KEY_TOKEN);
    };
    static getUserRole = async () => {
        const userSessionData = await encrypterGetFromLocalStorage(Constants.STORAGE_KEY_USER_DATA);
        return userSessionData?.role ?? null;
        //return localStorage.getItem(Constants.STORAGE_KEY_ROLE);
    }
    static getUserName = async () => {
        const userSessionData = await encrypterGetFromLocalStorage(Constants.STORAGE_KEY_USER_DATA);
        return userSessionData?.name ?? null;
        //return localStorage.getItem(Constants.STORAGE_KEY_NAME);
    }
    static isSystemCustomer(customerId) {
        return Constants.SYSTEM_CUSTOMER_ID === customerId;
    }
    static isGlobalAdmin(role) {
        return Constants.GLOBAL_ADMIN_ROLE === role;
    }
    static isVIPTradingAdmin(role) {
        return Constants.VIP_TRADING_ADMIN_ROLE === role;
    }
    static isCustomerAdmin(role) {
        return Constants.CUSTOMER_ADMIN_ROLE === role;
    }
    static isAdmin(role) {
        return (
            Constants.GLOBAL_ADMIN_ROLE === role || 
            Constants.VIP_TRADING_ADMIN_ROLE === role);
    }
    static isUser(role) {
        return Constants.USER_ROLE === role; 
    }
    static clearNav() {
        const nav = document.getElementsByClassName('header-nav-item-active');
        if (nav) {
            for(let i = 0; i < nav.length; i=i+1) {
                nav[i].className = 'header-nav-item';
            }
        }
    }
    static setActiveNav(id) {
        this.clearNav();
        document.getElementById(id).className='header-nav-item-active';
    }

    static isStatusCode403(error) {
        return error?.response?.status === 403;
    }

    static isNetworkError(error) {
        return error?.code === Constants.AXIOS_CODE_NETWORK_ERROR;
    }

    static showPageLoader() {
        document.getElementById('pageContentFader').style.display = 'block';
        document.getElementById('pageContentLoader').style.display = 'block';
    };

    static hidePageLoader() {
        document.getElementById('pageContentFader').style.display = 'none';
        document.getElementById('pageContentLoader').style.display = 'none';
    };

    // Format 2024-08-02T21:00:00.000+00:00
    static parseDBDate(date) {
        if (date === undefined || date == null) return;        
        date = String(date).replace('T', ' ');
        const index = String(date).indexOf('.');
        if (index !== -1) {
            date = String(date).substring(0 ,index);
        }
        return [date];
    }

    // Format dd.mm.yyyy HH:ss:mm
    static formatDBDate(date) {
        if (date === undefined || date == null || date.trim().length === 0) return;  
        const plus = String(date).indexOf('+');
        if (plus !== -1) {
            date = String(date).substring(0, plus) + ':00';
        } 
        return [String(date).substring(8 ,10) + '.' 
            + String(date).substring(5, 7) + '.' 
            + String(date).substring(0, 4) + ' '
            + String(date).substring(11, 19)]; 
    }

    static getDateAndTimeForFile() {
        const today = new Date();
        const month = (today.getMonth() + 1 > 9 ? '' : '0') + (today.getMonth() + 1);
        const year = today.getFullYear();
        const date = (today.getDate() > 9 ? '' : '0') + today.getDate();
        const hours = (today.getHours() > 9 ? '' : '0') + today.getHours();
        const minutes = (today.getMinutes() > 9 ? '' : '0') + today.getMinutes();
        const seconds = (today.getSeconds() > 9 ? '' : '0') + today.getSeconds();
        return `${year}_${month}_${date}_${hours}_${minutes}_${seconds}`;
    }

    static getNomenclatureName(url) {
        const context = this.getNomenclatureContext(url);
        let nomenclatureName = 'N/A';
        Constants.NOMENCLATURE_PAGES_DATA.filter((nomenclature) => { return nomenclature.url === context; }).map((nomenclature) => (
            nomenclatureName = nomenclature.name
        ));                
        return nomenclatureName;
    };

    static getNomenclatureType(url) {
        const context = this.getNomenclatureContext(url);
        let nomenclatureType = null;
        Constants.NOMENCLATURE_PAGES_DATA.filter((nomenclature) => { return nomenclature.url === context; }).map((nomenclature) => (
            nomenclatureType = nomenclature.type
        ));        
        return nomenclatureType;
    };

    static getNomenclatureContext(url) {        
        let context =  url.substring(url.indexOf(Constants.NOMENCLATURES_PAGE_URL) + Constants.NOMENCLATURES_PAGE_URL.length + 1);
        if (context.indexOf('/') !== -1) {
            context = context.substring(0, context.indexOf('/'));
        }
        return context;
    };

    static getNomenclatureUrlWithContext(url) {        
        return `${Constants.NOMENCLATURES_PAGE_URL}/${this.getNomenclatureContext(url)}`;
    };

    static getNomenclatureTabId(url) {      
        const context = this.getNomenclatureContext(url);
        let tabId = 0;
        Constants.NOMENCLATURE_PAGES_DATA.filter((nomenclature) => { return nomenclature.url === context; }).map((nomenclature) => (
            tabId = nomenclature.tabId
        ));        
        return tabId;
    };

}

export default Utils;
