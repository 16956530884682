import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Utils from '../utils/Utils';
import * as Constants from '../../constants';

function NavigationComponent() {
    const [userInfo, setUserInfo] = useState(null);	 
    const customersDataUrl = Utils.fromSessionStorage('headerLinkCustomers');
    const nomenclaturesDataUrl = Utils.fromSessionStorage('headerLinkNomenclatures');
    const settingsDataUrl = Utils.fromSessionStorage('headerLinkSettings');

    useEffect(() => {	                
        const fetchUserInfo = async () => {
            setUserInfo(await Utils.getUserData());
        };
        fetchUserInfo();
	}, []);

    const clearState = (e) => {
       Utils.clearGenericMessages();
    }
   
    return (
        <div className="header-nav">                
        <nav>                
            <ul>                    
                {userInfo?.isAdmin && <li><Link id="headerLinkHome" onClick={() => { clearState() }} to={Constants.HOME_PAGE_URL} className='header-nav-item'>Home</Link></li>}                    
                {userInfo?.isAdmin && <li><Link id="headerLinkReports" onClick={() => { clearState() }} to={Constants.REPORTS_PAGE_URL} data-url={Constants.REPORTS_PAGE_URL} className='header-nav-item'>Reports</Link></li>}                    
                {userInfo?.isAdmin && <li><Link id="headerLinkCustomers" onClick={() => { clearState() }} to={Constants.CUSTOMERS_PAGE_URL} data-url={customersDataUrl ?? Constants.CUSTOMERS_PAGE_URL} className='header-nav-item'>Customers</Link></li>}                    
                {userInfo?.isAdmin && <li><Link id="headerLinkProducts" onClick={() => { clearState() }} to={Constants.PRODUCTS_PAGE_URL} data-url={Constants.PRODUCTS_PAGE_URL} className='header-nav-item'>Products</Link></li>}                    
                {userInfo?.isAdmin && <li><Link id="headerLinkUserManagement" onClick={() => { clearState() }} to={Constants.USER_MANAGEMENT_PAGE_URL} data-url={Constants.USER_MANAGEMENT_PAGE_URL} className='header-nav-item'>User Management</Link></li>}                    
                {userInfo?.isAdmin && <li><Link id="headerLinkNomenclatures" onClick={() => { clearState() }} to={Constants.NOMENCLATURES_PAGE_URL + '/' + Constants.NOMENCLATURE_CUSTOMER_TYPES_CONTEXT} className='header-nav-item' data-url={nomenclaturesDataUrl ?? (Constants.NOMENCLATURES_PAGE_URL + '/' + Constants.NOMENCLATURE_CUSTOMER_TYPES_CONTEXT)}>Nomenclatures</Link></li>}                    
                {userInfo?.isAdmin && <li><Link id="headerLinkSettings" to={Constants.SETTINGS_SYSTEM_PAGE_URL} data-url={settingsDataUrl ?? Constants.SETTINGS_SYSTEM_PAGE_URL} className='header-nav-item'>Settings</Link></li>}                    
                <li className="header-nav-logo">B2B Portal</li>
            </ul>
        </nav>
        </div>
    );
}

export default NavigationComponent;