import React, { useState, useEffect } from 'react';

import { createRoot } from 'react-dom/client';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/dist/themes/base/jquery-ui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5';
import { useNavigate, useLocation } from "react-router-dom";
import Utils from '../../../utils/Utils';
import * as Constants from '../../../../constants';
import TabsComponent from './TabsComponent';
import UserService from '../../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faStickyNote, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useGenericMessages } from '../../../../contexts/messages/genericMessageContext';
import NomenclatureSerice from '../../../service/NomenclatureService';
import DownloadListComponent from '../../../common/DownloadListComponent';
import { isEmpty, isInt } from 'validator';

function NomenclaturesPage() {
	const { addGenericMessage, clearGenericMessages } = useGenericMessages();
	const navigate = useNavigate();
	const location = useLocation();
	const [filterData, setFilterData] = useState({
		filterId: '',
		filterName: ''
	});
	const ORDER_COLUMN = 1;
	const nomenclatureName = Utils.getNomenclatureName(location.pathname);
	const tabId = Utils.getNomenclatureTabId(location.pathname);
	const filterType = Utils.getNomenclatureType(location.pathname);
	const filterNS = `nomenclatures_${Utils.getNomenclatureContext(location.pathname)}`;

	useEffect(() => {
		console.log(`location=${location.pathname}`);
		const dataUrl = document.getElementById('headerLinkNomenclatures')?.dataset.url;
		if (location.pathname !== dataUrl) {
			console.log('navigateeee [' + dataUrl + '][' + location.pathname + ']');
			navigate(dataUrl);
			return;
		}
		Utils.setActiveNav('headerLinkNomenclatures');
		$("#tabs").tabs({
			active: tabId,
			activate: function (event, ui) {/*console.log('#tabs-' + ui.newTab.index());*/}			
		});

		$.extend($.fn.dataTableExt.oSort, {
			"extract-date-pre": function (value) {
				const datetime = value.split(' ');
				const date = datetime[0].split('.');
				return Date.parse(date[1] + '/' + date[0] + '/' + date[2] + ' ' + datetime[1]);
			},
			"extract-date-asc": function (a, b) {
				return ((a < b) ? -1 : ((a > b) ? 1 : 0));
			},
			"extract-date-desc": function (a, b) {
				return ((a < b) ? 1 : ((a > b) ? -1 : 0));
			}
		});

		const dataTable = initDataTable();

		setFilterData(
			{
				filterId: Utils.getFilterValue(filterNS, 'filterId'),
				filterName: Utils.getFilterValue(filterNS, 'filterName'),
			});
		return () => {
			try {
				dataTable.destroy();
			} catch (error) { }
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	const initDataTable = async () => {
		const token = await Utils.getToken();
		try {
			if ($.fn.DataTable.isDataTable("#datatable")) {
				$('#datatable').DataTable().clear().destroy();
			}
			$.fn.dataTable.ext.errMode = 'throw';
			const dataTable = $('#datatable').DataTable({
				serverSide: true,
				processing: true,
				searching: false,
				autoWidth: false,
				order: [[Utils.getDataTableValue(filterNS, 'orderColumn') ?? ORDER_COLUMN, Utils.getDataTableValue(filterNS, 'order') ?? Constants.DATATABLE_ORDER_ASC]],
				lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
				displayStart: Utils.getDataTableValue(filterNS, 'start') ?? Constants.DATATABLE_START,
				pageLength: Utils.getDataTableValue(filterNS, 'pageLength') ?? Constants.DATATABLE_PAGE_LENGTH,
				infoCallback: function (settings, start, end, max, total, pre) {
					settings.api = this.api();
				},
				drawCallback: function (settings) {
					//var api = this.api();
					
				},
				ajax: {
					url: Constants.NOMENCLATURES_LIST_URL,
					type: 'POST',
					beforeSend: function (xhr) {
						xhr.setRequestHeader("Authorization", "Bearer " + token);
						$('#tabs-' + tabId).css('display','block');
					},
					statusCode: {
						403: function () {
							UserService.sessionTimeOut();
						}
					},
					error: function (XMLHttpRequest, textStatus, errorThrown) {						
						console.log('error=' + JSON.stringify(XMLHttpRequest));
						if (0 === XMLHttpRequest.status) {
							addGenericMessage(Utils.buildErrorMessage({ message: 'No server connection.' }, 5));
							UserService.logout();
							navigate(Constants.LOGIN_PAGE_URL);
							return;
						}						
					},
					dataSrc: function (json) {
						Utils.setDataTableValue(filterNS, 'start', json.start);
						Utils.setDataTableValue(filterNS, 'pageLength', json.pageLength);
						Utils.setDataTableValue(filterNS, 'orderColumn', json.orderColumn);
						Utils.setDataTableValue(filterNS, 'order', json.order);
						return json.data;
					},
					data: {
						filterType: filterType,
						filterId: Utils.getFilterValue(filterNS, 'filterId'),
						filterName: Utils.getFilterValue(filterNS, 'filterName'),
					}
				},
				columnDefs: [
					{
						"targets": 0, //id.
						"className": "text-right",
						"width": "100px",
						"createdCell": (td, cellData, rowData, row, col) =>							
							createRoot(td).render(	
								<button className='table-cell-id' onClick={() => {return handleReadAction(cellData)}}>{cellData}</button>
							) 
					}, 
					{
						"targets": 1, //name.
						"className": "text-left"
					},
					{
						"targets": 2, //description.
						"className": "text-left"
					},
					{
						"targets": 3, //date_created.
						"className": "text-center",
						"type": "extract-date",
						// "width": "180px",
						"render": function (data, type, row) {
							return Utils.formatDBDate(data);
						}
					},
					{
						"targets": 4, //date_modified.
						"className": "text-center",
						"type": "extract-date",
						// "width": "180px",
						"render": function (data, type, row) {
							return Utils.formatDBDate(data);
						}
					},
					{
						"createdCell": (td, cellData, rowData, row, col) =>
							createRoot(td).render(
								<div className='list-icons'>
									<FontAwesomeIcon onClick={() => { handleReadAction(cellData) }} className='circle-icon list-icon-read' icon={faStickyNote} />
									<FontAwesomeIcon onClick={() => { handleEditAction(cellData) }} className='circle-icon list-icon-edit' icon={faPencil} />
									<FontAwesomeIcon onClick={() => { handleDeleteAction(cellData, rowData[1]) }} className='circle-icon list-icon-delete' icon={faTrashAlt} />
								</div>
							)
						,
						"className": "text-right dt-nowrap list-cell-actions",
						"targets": 5,
						"width": "180px",
						"orderable": false
					}// operations
				]
			});
			return dataTable;
		} catch (error) {
			console.error('Error applying filters:', error);
			//alert(error);
		}
	}

	const handleReadAction = (id) => {
		clearGenericMessages();
		navigate(location.pathname + Constants.READ_URL + id);
	}
	const handleEditAction = (id) => {
		clearGenericMessages();
		navigate(location.pathname + Constants.UPDATE_URL + id);
	}
	const handleDeleteAction = async (id, name) => {
		try {
			// Prompt for confirmation before deleting the user
			const confirmDelete = window.confirm(`Are you sure you want to delete "${name}"?`);

			if (confirmDelete) {
				Utils.showPageLoader();
				const response = await NomenclatureSerice.deleteNomenclature(id, await Utils.getToken());
				clearGenericMessages();
				addGenericMessage(Utils.buildMessage(response, 5));
				Utils.hidePageLoader();
				return await initDataTable();
			}
		} catch (error) {
			console.error(`Error deleting ${nomenclatureName}:`, error);
			clearGenericMessages();
			if (Utils.isNetworkError(error)) {
				addGenericMessage(Utils.buildErrorMessage({ message: 'No server connection.' }, 5));
				UserService.logout();
				navigate(Constants.LOGIN_PAGE_URL);
				return;
			}
			if (!UserService.handleSessionTimeOutError(error)) {
				addGenericMessage(Utils.buildErrorMessage({ message: `An error occurred while deleting ${nomenclatureName}!` }));
			}
		}
	}
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		Utils.setFilterValue(filterNS, name, value);
		setFilterData((prevFilterData) => ({
			...prevFilterData,
			[name]: value
		}));
	};

	const handleFilterSubmit = async (e) => {
		e.preventDefault();
		if (!isEmpty(filterData.filterId + '') && !isInt(filterData.filterId + '')) {
			alert('Please enter number for "№" field.');
			return;
		}
		Utils.setDataTableValue(filterNS, 'start', Constants.DATATABLE_START);
		return await initDataTable();
	};

	const prepareParametersForListDownload = () => {
		const orderColumn = Utils.getDataTableValue(filterNS, 'orderColumn') ?? ORDER_COLUMN;
		const order = Utils.getDataTableValue(filterNS, 'order') ?? Constants.DATATABLE_ORDER_ASC;
		return `orderColumn=${orderColumn}&order=${order}&filterType=${filterType}&filterId=${filterData.filterId ?? ''}&filterName=${filterData.filterName ?? ''}`;
	}

	// USE for LARGE FILES!!!
	// const handleDownloadListLink = async (e) => {
	// 	const orderColumn = Utils.getDataTableValue(filterNS, 'orderColumn') ?? 1;
	// 	const order = Utils.getDataTableValue(filterNS, 'order') ?? Constants.DATATABLE_ORDER_ASC;
	// 	// For the token: use 
	// 	// const [token, setToken] = useState(null);
	// 	// in useEffect()
	// 	// setToken(Utils.getToken());
	// 	e.target.href = `${Constants.DOWNLOAD_LIST_REPORT_CUSTOMER_CATEGORIES_URL}type=xlsx&orderColumn=${orderColumn}&order=${order}&token=${token}`;
	// }

	const navigateToAddNomenclaturePage = (e) => {
		clearGenericMessages();
		navigate(location.pathname + Constants.ADD_URL);
	}

	return (
		<div>
			<div className="nomenclatures main-wrapper list-page">
				<div className="dt-add-button-wrapper">
					<div className='tabs-action-buttons filter-search-button'><button onClick={() => { navigateToAddNomenclaturePage() }} className="ibtn ibtn-full" alt={` Add ${nomenclatureName} `} title={` Add ${nomenclatureName} `} type='button'> + Add {nomenclatureName} </button></div>
					<DownloadListComponent url={Constants.NOMENCLATURES_DOWNLOAD_LIST_URL} parameters={prepareParametersForListDownload()} fileName={`listReport_${nomenclatureName.replaceAll(' ','')}`} />
					{/* <div className='tabs-action-buttons download-report-button'><button onClick={handleDownloadListAction} className="ibtn ibtn-full" alt=" Download List " title=" Download List " type='button'><FontAwesomeIcon icon={faDownload} /></button> */}
					{/* <div className='tabs-action-buttons download-report-button'><a href='http' onClick={handleDownloadListLink} download>click me</a></div> */}
				</div>
				<div id="tabs">
					<TabsComponent />
					<div id={'tabs-' + tabId}>
						<div>
							<div className="card-body">
								<form onSubmit={handleFilterSubmit}>
									<div className="search-filter">
										<div className="filter-field">№ <input name="filterId" value={filterData.filterId || ''} onChange={handleInputChange} type="text" className="filter-field-xxs" /><span /></div>
										<div className="filter-field">Name <input name="filterName" value={filterData.filterName || ''} onChange={handleInputChange} type="text" className="filter-field-xl" placeholder='' /><span /></div>
										<div className='filter-search-button'><button className="ibtn ibtn-full" type="submit">Search</button></div>
									</div>
								</form>
								<table className="table table-bordered" id="datatable">
									<thead>
										<tr>
											<th className="text-center">№</th>
											<th>Name</th>
											<th>Description</th>
											<th>Date Created</th>
											<th>Last Update</th>
											<th className='list-header-actions-label'>Actions</th>
										</tr>
									</thead>
									<tbody></tbody>
								</table>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	);
}

export default NomenclaturesPage;