import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import UserService from '../service/UserService';
import NavigationComponent from './NavigationComponent';
import Utils from '../utils/Utils';

function HeaderComponent() {    
    const [userInfo, setUserInfo] = useState(null);	  
    
    useEffect(() => {	
        const fetchUserInfo = async () => {
            const info = await Utils.getUserData();
            setUserInfo(info);
          };
          fetchUserInfo();		
	}, []);
      
    const handleLogout = async () => {        
        await UserService.logout(); 
    };

    return (
        <header>
            {userInfo?.name && <div className='header-links'><Link to="/profile">Welcome, {userInfo?.name}</Link> | {userInfo && <Link id="logoutLink" to="/" reloadDocument onClick={handleLogout}>Logout</Link>}</div>}
            <a href="/" className="logo"><img src="/images/logoVIPTrading.svg" alt="vip trading 30 years logo" /></a>
            <NavigationComponent />
        </header>
    );
};

export default HeaderComponent;