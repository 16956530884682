'use client';

import React from 'react';
import {useNavigate} from "react-router-dom";
import * as Constants from '../../../../constants';
import Utils from '../../../utils/Utils';

function TabsComponent() {
    const navigate = useNavigate();

    const setActivePage = (link) => {
        document.getElementById('headerLinkCustomers').dataset.url = link;
        Utils.toSessionStorage('headerLinkCustomers', link);
        navigate(link);
    }
    return (
        <ul>
            <li><a href={'#tabs-' + Constants.TAB_CUSTOMERS_CUSTOMERS} onClick={() => { setActivePage(Constants.CUSTOMERS_PAGE_URL); }} title="All Customers">Customers</a></li>
            <li><a href={'#tabs-' + Constants.TAB_CUSTOMERS_RETAILS} onClick={() => { setActivePage(Constants.RETAILS_PAGE_URL); }} title="All Retails">Retails</a></li>
        </ul>
    );
};

export default TabsComponent;