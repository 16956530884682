import React, { useRef, useState, useEffect } from 'react';
import PasswordChecklist from "react-password-checklist"
import $ from 'jquery';
import isEmail from 'validator/es/lib/isEmail';
import { useNavigate} from 'react-router-dom';
import UserService from '../../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faHouse, faUser, faIdCard, faInstitution, faMortarBoard, faUserGroup, faKey, faEyeSlash, faEye, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import Utils from '../../../utils/Utils';
import * as Constants from '../../../../constants';
import { useGenericMessages } from '../../../../contexts/messages/genericMessageContext';

function AddUser() {
  const { addGenericMessage, clearGenericMessages } = useGenericMessages();
  const [password, setPassword] = useState('');
	const [passwordAgain, setPasswordAgain] = useState('');
  const [validPasswords, setValidPasswords] = useState(false);
  const [passwordType, setPasswordType] = useState({
    password: 'password',
    confirm: 'password'
  }); 
  const [passwordIcon, setPasswordIcon] = useState({
    password: faEyeSlash,
    confirm: faEyeSlash
  }); 
  const [userInfo, setUserInfo] = useState(null);	 
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const emailRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: Constants.USER_ROLE,
    phone: '',
    address: '',
    position: '',
    customerId: '',
    status: Constants.USER_STATUS_ACTIVE,
    password: '',
    confirmPassword: '',    
    customer: { id: ''}
  });
  const [customersData, setCustomersData] = useState({});

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userData = await Utils.getUserData();
      setUserInfo(userData);  
      await fetchCustomersData();
      inputRef.current.focus();
    };
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  const fetchCustomersData = async () => {    
    try {
      const response = await UserService.addUserGetCustomers(await Utils.getToken());
      hideLoader();
      if (response?.statusCode === 200) {
        setCustomersData(response.customers);
        if (response?.customers) {
          // Set 'default' value.
          formData.customerId = response.customers[0].id;
        }
      } else {
        clearGenericMessages();
        addGenericMessage(Utils.buildMessage(response, 10));      
      }
    } catch (error) {
      console.error('Error fetching customers data:', error);
      hideLoader();
      clearGenericMessages();
      if (Utils.isNetworkError(error)) {
          addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
          UserService.logout();
          navigate(Constants.LOGIN_PAGE_URL);
          return;
      }
      if (!UserService.handleSessionTimeOutError(error)) {
        addGenericMessage(Utils.buildErrorMessage({message: 'Error fetching customers data.'}, 10));
        navigate(Constants.USER_MANAGEMENT_PAGE_URL);
      } 
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(e.target.value);
    } else if (name === 'confirmPassword') {
      setPasswordAgain(e.target.value);
    }
    setFormData((prevformData) => ({
      ...prevformData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {   
    e.preventDefault();
    clearGenericMessages();
    let error = false;
    if (!isEmail(formData.email)) {
      addGenericMessage(Utils.buildErrorMessage({message: 'Please enter valid email.'}, 10));
      emailRef.current.focus();
      emailRef.current.style.border = '1px solid #cc0000'; 
      error = true;    
    }
    if (!validPasswords) {         
      addGenericMessage(Utils.buildErrorMessage({message: 'Please enter passwords that match security criterias.'}, 10));      
      error = true;
    }  
    if (formData?.password !== formData?.confirmPassword) {
      addGenericMessage(Utils.buildErrorMessage({message: '"Password" and "Confirm Password" should match.'}, 10));
      error = true;
    }
    if (error) {
      scrollToTop();
      return;
    }
    showLoader();
    try {
        formData.customer.id = formData.customerId;
        const response = await UserService.addUser(formData, await Utils.getToken());
        hideLoader();        
        addGenericMessage(Utils.buildMessage(response, 10));
        navigate(Constants.USER_MANAGEMENT_PAGE_URL);
        scrollToTop();    
    } catch (error) {
      console.error('Error add user:', error);
      hideLoader();
      if (Utils.isNetworkError(error)) {
        addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
        UserService.logout(); 
        navigate(Constants.LOGIN_PAGE_URL);
        return;
      }      
      if (!UserService.handleSessionTimeOutError(error)) {
				addGenericMessage(Utils.buildErrorMessage({message: 'Error add user!'}));
        scrollToTop();
			}
    }
  };

  const handleBack = async (e) => {
    clearGenericMessages();
    navigate(Constants.USER_MANAGEMENT_PAGE_URL);
  };
  
  const showLoader = () => {
      $('#profileComponentLoader').show();
      $('#profileComponentFader').show();
  };

  const hideLoader = () => {
      $('#profileComponentLoader').hide();
      $('#profileComponentFader').hide();
  };

  const handleMouseOver = (id) => {        
    $(`#field${id}Icon`).css('color', '#2c95ff');
  };
  const handleMouseOut = (id) => {
      $(`#field${id}Icon`).css('color', '#999');      
  };

  const handleToggle = (id) => {
    switch (id) {
      case 'password':
        updatePasswordField(id, passwordType.password === 'password');        
        break;     
      case 'confirm':
        updatePasswordField(id, passwordType.confirm === 'password');
        break;
      default:
        break;
    }
  };

  const updatePasswordField = (id, show = false) => {
    if (show) {
      setPasswordType((prevPassData) => ({
        ...prevPassData,
        [id]: 'text'
      }));
      setPasswordIcon((prevPassData) => ({
        ...prevPassData,
        [id]: faEye
      }));  
    } else {
      setPasswordType((prevPassData) => ({
        ...prevPassData,
        [id]: 'password'
      }));
      setPasswordIcon((prevPassData) => ({
        ...prevPassData,
        [id]: faEyeSlash
      })); 
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    }); 
  }

  return (
    <div>
        <div id="addUserPageContainer" className='form-page-container-l'>   
            <div className='form-body without-side'>
                <div className="row">
                    <div className="img-holder">
                        <div className="bg"></div>
                        <div className="info-holder">
                            <img src="/images/login_bg_graphic.svg" alt="" />
                        </div>
                    </div>
                    <div className="form-holder">
                        <div className="form-content">
                            <div className="form-items">
                                <div className="form-icon"> 
                                    <div className="icon-holder">
                                        <FontAwesomeIcon icon={faUser} />
                                    </div>
                                </div>
                                <h3 className="form-title-center">Add User</h3>
                                <div className='form-body-fields-wrapper'>
                                  <form onSubmit={handleSubmit}>
                                    <div id='profileComponentLoader' className='component-loader'></div>
                                    <div id='profileComponentFader' className='component-fader'></div>
                                    <div className='form-body-fields form-body-2columns'>
                                        <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Name')} onMouseOut={() => handleMouseOut('Name')}><FontAwesomeIcon id="fieldNameIcon" icon={faIdCard} />Name *:<br/><div className='field-value'><input ref={inputRef} className="form-control" type="text" name="name" value={formData?.name} onChange={handleInputChange} required/></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Email')} onMouseOut={() => handleMouseOut('Email')}><FontAwesomeIcon id="fieldEmailIcon" icon={faEnvelope} />Email *:<br/><div className='field-value'><input ref={emailRef} className="form-control" type="text" name="email" value={formData?.email} onChange={handleInputChange} required autoComplete='new-email'/></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Password')} onMouseOut={() => handleMouseOut('Password')}><FontAwesomeIcon id="fieldPasswordIcon" icon={faKey} />Password *:<br/><div className='field-value'><input className="form-control" type={passwordType.password} name="password" value={formData?.password} onChange={handleInputChange} required placeholder='Enter your password' autoComplete="new-password"/><span className="field-password-eye" onClick={(e) => handleToggle('password')}><FontAwesomeIcon id="fieldPasswordEyeIcon" icon={passwordIcon.password} /></span></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('ConfirmPassword')} onMouseOut={() => handleMouseOut('ConfirmPassword')}><FontAwesomeIcon id="fieldConfirmPasswordIcon" icon={faKey} />Confirm Password *:<br/><div className='field-value'><input className="form-control" type={passwordType.confirm} name="confirmPassword" value={formData?.confirmPassword} onChange={handleInputChange} required placeholder='Enter your password' autoComplete="new-password"/><span className="field-password-eye" onClick={(e) => handleToggle('confirm')}><FontAwesomeIcon id="fieldConfirmPasswordEyeIcon" icon={passwordIcon.confirm} /></span></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Phone')} onMouseOut={() => handleMouseOut('Phone')}><FontAwesomeIcon id="fieldPhoneIcon" icon={faPhone} />Phone:<br/><div className='field-value'><input className="form-control" type="text" name="phone" value={formData?.phone} onChange={handleInputChange}/></div></div>
                                            { password &&
                                            <PasswordChecklist
                                              rules={["minLength","specialChar","number","capital","match"]}
                                              minLength={8}
                                              value={password}
                                              valueAgain={passwordAgain}
                                              invalidColor='#FF3333'                                              
                                              onChange={(isValid) => {setValidPasswords(isValid)}}
                                            />
                                              }
                                        </div>
                                        <div className='form-body-col2'>
                                            
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Address')} onMouseOut={() => handleMouseOut('Address')}><FontAwesomeIcon id="fieldAddressIcon" icon={faHouse} />Address:<br/><div className='field-value'><textarea className="form-control" name="address" value={formData?.address} onChange={handleInputChange}/></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Position')} onMouseOut={() => handleMouseOut('Position')}><FontAwesomeIcon id="fieldPositionIcon" icon={faMortarBoard} />Position:<br/><div className='field-value'><input className="form-control" type="text" name="position" value={formData?.position} onChange={handleInputChange}/></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Role')} onMouseOut={() => handleMouseOut('Role')}><FontAwesomeIcon id="fieldRoleIcon" icon={faUserGroup} />Role *:<br/>
                                              {
                                                userInfo?.isAdmin && 
                                                <div className='field-value'>
                                                  <select name="role" className='form-control' value={formData.role} onChange={handleInputChange}>
                                                    {userInfo?.isGlobalAdmin &&
                                                    <option value={Constants.GLOBAL_ADMIN_ROLE}>{Constants.GLOBAL_ADMIN_ROLE}</option>
                                                    }
                                                    <option value={Constants.VIP_TRADING_ADMIN_ROLE}>{Constants.VIP_TRADING_ADMIN_ROLE}</option>
                                                    {/* <option value={Constants.CUSTOMER_ADMIN_ROLE}>{Constants.CUSTOMER_ADMIN_ROLE}</option> */}
                                                    <option value={Constants.USER_ROLE}>{Constants.USER_ROLE}</option>            
                                                  </select>
                                                </div>
                                              }                                                                                         
                                            </div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Customer')} onMouseOut={() => handleMouseOut('Customer')}><FontAwesomeIcon id="fieldCustomerIcon" icon={faInstitution} />Customer *:<br/>
                                              {
                                                userInfo?.isAdmin && customersData != null && 
                                                <div className='field-value'>
                                                  <select name="customerId" className='form-control' value={formData.customerId} onChange={handleInputChange}>
                                                    {Object.values(customersData).map((customer) => (
                                                      <option key={customer.id} value={customer.id}>{customer.name}</option>
                                                    ))}                  
                                                  </select> 
                                                </div>
                                              } 
                                            </div>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Status')} onMouseOut={() => handleMouseOut('Status')}><FontAwesomeIcon id="fieldStatusIcon" icon={faToggleOn} />Status *:<br/>
                                                <div className='field-value' style={{paddingTop: '9px'}}>
                                                  <select name="status" className='form-control' value={formData.status} onChange={handleInputChange} style={{height: '37px'}}>
                                                    <option value={Constants.USER_STATUS_INACTIVE}>Inactive</option>
                                                    <option value={Constants.USER_STATUS_ACTIVE}>Active</option>            
                                                  </select>
                                                </div>
                                            </div>                                           
                                        </div>
                                        <div className="form-button">
                                        <button className="ibtn ibtn-col1" type="submit">Save</button>
                                        <button className="ibtn-empty ibtn-col2" onClick={(e) => handleBack(e)}>Back</button>
                                        </div>
                                    </div>
                                  </form>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default AddUser;