import axios from "axios";
import * as Constants from '../../constants';
import Utils from "../utils/Utils";

class RetailService {

    static async getRetailsFilters(token, filterNS) {
        try {
            const response = await axios.get(`${Constants.RETAILS_LIST_URL}/filters`,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            if (response.data) {
                response.data.filterId = Utils.getFilterValue(filterNS, 'filterId') || '';
                response.data.filterName = Utils.getFilterValue(filterNS, 'filterName') || '';
                response.data.filterCustomer = Utils.getFilterValue(filterNS, 'filterCustomer') || '' ;
                response.data.filterCountry = Utils.getFilterValue(filterNS, 'filterCountry') || '' ;
                response.data.filterAddress = Utils.getFilterValue(filterNS, 'filterAddress') || '' ;
            }
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async getRetailById(id, token) {
        try {
            const response = await axios.get(`${Constants.RETAILS_LIST_URL}/${id}`,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async getRetailFormData(token) {
        try {
            const response = await axios.get(`${Constants.RETAILS_LIST_URL}/form-data`,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async addEditRetail(id, data, token) {
        try {
            const url = id ? `${Constants.UPDATE_RETAIL_PAGE_URL + id}` : Constants.ADD_RETAIL_PAGE_URL; 
            const headers = {
                headers: {Authorization: `Bearer ${token}`}
            };
            const response = id ? await axios.put(`${Constants.BASE_URL + url}`, data, headers) : await axios.post(`${Constants.BASE_URL + url}`, data, headers);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static async deleteRetail(id, token) {
        try {
            const response = await axios.delete(`${Constants.BASE_URL + Constants.DELETE_RETAIL_PAGE_URL + id}`,
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            return response.data;
        } catch (error) {
            throw error;
        }
    };
}

export default RetailService;
