import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from '../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faHouse, faUser, faIdCard, faInstitution, faClock, faMortarBoard, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import Utils from '../../utils/Utils';
import * as Constants from '../../../constants';
import { useGenericMessages } from '../../../contexts/messages/genericMessageContext';

function ProfilePage() {
    const { addGenericMessage, clearGenericMessages } = useGenericMessages();
    const [profileInfo, setProfileInfo] = useState({});
    const [customerInfo, setCustomerInfo] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        Utils.clearNav();       	
		fetchProfileInfo();	
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchProfileInfo = async () => {
        try {
            const response = await UserService.getProfile(await Utils.getToken());
            setProfileInfo(response);            
            setCustomerInfo(response.customer);
            hideLoader();
        } catch (error) {
            console.error('Error fetching profile information:', error);
            hideLoader();
            clearGenericMessages();
            if (Utils.isNetworkError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
                UserService.logout();
                navigate(Constants.LOGIN_PAGE_URL);
                return;
            }
            if (!UserService.handleSessionTimeOutError(error)) {
                addGenericMessage(Utils.buildErrorMessage({message: 'Error fetching profile information.'}, 5));
            }
        }
    };

    const hideLoader = () => {
        document.getElementById('profileComponentLoader').style.display = 'none';
        document.getElementById('profileComponentFader').style.display = 'none';
    };
    const handleMouseOver = (e, id) => {        
        document.getElementById(`field${id}Icon`).style.color = '#2C95FF';    
    };
    const handleMouseOut = (e, id) => {
        document.getElementById(`field${id}Icon`).style.color = '#999';
    };    
    const navigateToEditPage = (e) => {
        navigate(`${Constants.EDIT_PROFILE_PAGE_URL}`);
    };

    return (
        <div>
            <div id="userProfilePageContainer" className='profile-page-container'>   
                <div className='form-body without-side'>
                    <div className="row">
                        <div className="img-holder">
                            <div className="bg"></div>
                            <div className="info-holder">
                                <img src="/images/login_bg_graphic.svg" alt="" />
                            </div>
                        </div>
                        <div className="form-holder">
                            <div className="form-content">
                                <div className="form-items">
                                    <div className="form-icon"> 
                                        <div className="icon-holder">
                                            <FontAwesomeIcon icon={faUser} />
                                        </div>
                                    </div>
                                    <h3 className="form-title-center">Profile Information</h3>
                                    <div className='form-body-fields-wrapper'>
                                        <div id='profileComponentLoader' className='component-loader'></div>
                                        <div id='profileComponentFader' className='component-fader'></div>
                                        <div className='form-body-fields'>
                                            <div className='form-body-col1'>
                                                <div onMouseOver={(e) => handleMouseOver(e, 'Name')} onMouseOut={(e) => handleMouseOut(e, 'Name')}><FontAwesomeIcon id="fieldNameIcon" icon={faIdCard} />Name:<br/><div className='field-value'>{profileInfo.name}</div></div>
                                                <div onMouseOver={(e) => handleMouseOver(e, 'Email')} onMouseOut={(e) => handleMouseOut(e, 'Email')}><FontAwesomeIcon id="fieldEmailIcon" icon={faEnvelope} />Email:<br/><div className='field-value'>{profileInfo.email}</div></div>
                                                <div onMouseOver={(e) => handleMouseOver(e, 'Phone')} onMouseOut={(e) => handleMouseOut(e, 'Phone')}><FontAwesomeIcon id="fieldPhoneIcon" icon={faPhone} />Phone:<br/><div className={profileInfo?.phone ? 'field-value' : 'field-value-not-filled' }>{profileInfo?.phone ?? 'not filled' }</div></div>
                                                <div onMouseOver={(e) => handleMouseOver(e, 'Address')} onMouseOut={(e) => handleMouseOut(e, 'Address')}><FontAwesomeIcon id="fieldAddressIcon" icon={faHouse} />Address:<br/><div className={profileInfo?.address ? 'field-value' : 'field-value-not-filled' }>{profileInfo.address ?? 'not filled'}</div></div>
                                                <div onMouseOver={(e) => handleMouseOver(e, 'Position')} onMouseOut={(e) => handleMouseOut(e, 'Position')}><FontAwesomeIcon id="fieldPositionIcon" icon={faMortarBoard} />Position:<br/><div className={profileInfo?.position ? 'field-value' : 'field-value-not-filled' }>{profileInfo.position ?? 'not filled'}</div></div>
                                            </div>
                                            <div className='form-body-col2'>
                                                <div onMouseOver={(e) => handleMouseOver(e, 'Role')} onMouseOut={(e) => handleMouseOut(e, 'Role')}><FontAwesomeIcon id="fieldRoleIcon" icon={faUserGroup} />Role:<br/><div className='field-value'>{profileInfo.role}</div></div>
                                                <div onMouseOver={(e) => handleMouseOver(e, 'Customer')} onMouseOut={(e) => handleMouseOut(e, 'Customer')}><FontAwesomeIcon id="fieldCustomerIcon" icon={faInstitution} />Customer:<br/><div className='field-value'>{customerInfo.name}</div></div>
                                                <div onMouseOver={(e) => handleMouseOver(e, 'CreationDate')} onMouseOut={(e) => handleMouseOut(e, 'CreationDate')}><FontAwesomeIcon id="fieldCreationDateIcon" icon={faClock} />Creation Date: <br/><div className='field-value-italic'>{Utils.formatDBDate(profileInfo.createdAt)}</div></div>
                                                <div onMouseOver={(e) => handleMouseOver(e, 'LastUpdate')} onMouseOut={(e) => handleMouseOut(e, 'LastUpdate')}><FontAwesomeIcon id="fieldLastUpdateIcon" icon={faClock} />Last Update:<br/><div className='field-value-italic'>{Utils.formatDBDate(profileInfo.updatedAt)}</div></div>
                                            </div>
                                            <div className="form-button">
                                                <button className="ibtn ibtn-full" onClick={(e) => navigateToEditPage()} type="submit">Edit Profile</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;