'use client';

import React from 'react';
import {useNavigate} from "react-router-dom";
import * as Constants from '../../../../constants';

function TabsComponent() {
    const navigate = useNavigate();

    const setActivePage = (link) => {
        document.getElementById('headerLinkNomenclatures').dataset.url = link;
        navigate(link);
    }
    return (
        <ul>
            <li><a href={'#tabs-' + Constants.TAB_SETTINGS_SYSTEM} onClick={() => { setActivePage(Constants.SETTINGS_SYSTEM_PAGE_URL); }} title="All System Settings">System Settings</a></li>
            {/* <li><a href={'#tabs-' + Constants.TAB_SETTINGS_CUSTOMER_MATRIX} onClick={() => { setActivePage(Constants.SETTINGS_CUSTOMER_MATRIX_PAGE_URL); }} title="All Customer Matrix">Customer Matrix</a></li> */}
        </ul>
    );
};

export default TabsComponent;