import { createContext, useState } from "react";
export const AppContext = createContext();
export const AppContextProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({ genericMessages: { forceClose: false } });
  return (
    <AppContext.Provider value={{ globalState, setGlobalState }}>
      {" "}
      {children}{" "}
    </AppContext.Provider>
  );
};