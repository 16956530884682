import React, { useRef, useState, useEffect } from 'react';
import $ from 'jquery';
import isEmail from 'validator/es/lib/isEmail';
import { useParams, useNavigate, useLocation} from 'react-router-dom';
import UserService from '../../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faHouse, faUser, faIdCard, faInstitution, faTag, faBook, faInfoCircle, faChevronDown, faBuilding, faGlobe, faScaleBalanced, faSitemap, faBalanceScale, faDollar, faPercent, faListAlt, faLineChart, faChartBar, faCompress, faToggleOn, faClock, faIdBadge } from '@fortawesome/free-solid-svg-icons'
import Utils from '../../../utils/Utils';
import * as Constants from '../../../../constants';
import { useGenericMessages } from '../../../../contexts/messages/genericMessageContext';
import CustomerService from '../../../service/CustomerService';

function CustomerPage() {
  const { addGenericMessage, clearGenericMessages } = useGenericMessages();    
  const navigate = useNavigate();
  const { customerId } = useParams();
  const inputRef = useRef(null);
  const contactNameRef = useRef(null);
  const contactEmailRef = useRef(null);
  const location = useLocation();
  const [isReadMode, setReadMode] = useState(false);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const sections = ['General','CustomerAddress','SaleInfo','Reporting','ContactPerson','Workflow','Metadata'];
  const [chevronIcon, setChevronIcon] = useState({
    general: true,
    customerAddress: true,
    saleInfo: true,
    reporting: true,
    contactPerson: true,
    workflow: true,
    metadata: false
  });      
 
  const [formData, setFormData] = useState({
    name: '',
    vat: '',
    customerTypeId: '',
    customerType: { id: ''},
    businessCategoryId: '',
    businessCategory: { id: ''},
    countryId: '',
    country: { id: ''},
    city: '',    
    address: '',
    supplierType: '',    
    supplierId: '',
    supplier: { id: ''},
    salesMethod: '',
    paymentMethod: '',
    creditLimitCurrency: '',    
    discount: '',
    bankAccount: '',
    storePlacementId: '',
    storePlacement: { id: ''},
    onlineRatioId: '',
    onlineRatio: { id: ''},
    sellOutReportingFrequency: '',
    inventoryReportingFrequency: '',
    forcastSubmission: '',
    reportingInterface: '',
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonPhone: '',
    contactPersonAddress: '',  
    contactPerson: {
      name: '',
      email: '',
      phone: '',
      address: ''
    },
    status: ''
  });
  const [nomenclaturesData, setNomenclaturesData] = useState({});

  useEffect(() => {
    Utils.setActiveNav('headerLinkCustomers');
    if (location.pathname.indexOf('read') !== -1) {
        setReadMode(true);
    }	
    if (!isReadMode) {      
        inputRef.current.focus();
    }
    fetchFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps       
  }, []);

  const fetchFormData = async () => {   
    try {
      showLoader();
      const response = await CustomerService.getCustomerFormData(await Utils.getToken());
      hideLoader();
      if (response?.statusCode === 200) {
        const {customerTypes, businessCategories, countries, supplierTypes, salesMethods, paymentMethods, storePlacements, onlineRatios, creditLimitCurrencies, sellOutReportingFrequencies, inventoryReportingFrequencies, forcastSubmissions, reportingInterfaces, statuses} = response;
        setNomenclaturesData({customerTypes, businessCategories, countries, supplierTypes, salesMethods, paymentMethods, storePlacements, onlineRatios, creditLimitCurrencies, sellOutReportingFrequencies, inventoryReportingFrequencies, forcastSubmissions, reportingInterfaces, statuses});  
        if (response?.suppliers) {          
          setAllSuppliers(response.suppliers);
        }
        if (customerId) {
          fetchCustomerData(customerId, response?.suppliers);                        
        }   
      } else {
          clearGenericMessages();
          addGenericMessage(Utils.buildMessage(response, 10));
          navigate(Constants.CUSTOMERS_PAGE_URL);
      }
    } catch (error) {
        console.error(`Error fetching customer form data:`, error);
        hideLoader();
        if (Utils.isNetworkError(error)) {
            addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
            UserService.logout(); 
            navigate(Constants.LOGIN_PAGE_URL);
            return;
        }  
        if (!UserService.handleSessionTimeOutError(error)) {
            addGenericMessage(Utils.buildErrorMessage({message: `Error fetching customer form data.`}, 5));
        }            
    }
  };

  const fetchCustomerData = async (customerId, loadedSuppliers) => {
    try {
      showLoader();
      const response = await CustomerService.getCustomerById(customerId, await Utils.getToken());
      hideLoader();
      if (response?.statusCode === 200) {          
          if (response?.customerType?.id) {
            response.customerTypeId = response.customerType.id;
          }
          if (response?.businessCategory?.id) {
            response.businessCategoryId = response.businessCategory.id;
          }
          if (response?.country?.id) {
            response.countryId = response.country.id;
          }
          if (response?.supplier?.id) {
            response.supplierId = response.supplier.id;
          }
          if (response?.storePlacement?.id) {
            response.storePlacementId = response.storePlacement.id;
          }
          if (response?.onlineRatio?.id) {
            response.onlineRatioId = response.onlineRatio.id;
          }         
          if (response?.contactPerson) {
            response.contactPersonName = response.contactPerson?.name;
            response.contactPersonEmail = response.contactPerson?.email;
            response.contactPersonPhone = response.contactPerson?.phone;
            response.contactPersonAddress = response.contactPerson?.address;
          }             
          setFormData(response);
          handleSupplierType(response.supplierType, loadedSuppliers);
          formData.supplierId = response.supplierId;
      } else {
          clearGenericMessages();
          addGenericMessage(Utils.buildMessage(response, 10));
          navigate(Constants.CUSTOMERS_PAGE_URL);
      }
    } catch (error) {
        console.error(`Error fetching customer information:`, error);
        hideLoader();
        if (Utils.isNetworkError(error)) {
            addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
            UserService.logout(); 
            navigate(Constants.LOGIN_PAGE_URL);
            return;
        }  
        if (!UserService.handleSessionTimeOutError(error)) {
            addGenericMessage(Utils.buildErrorMessage({message: `Error fetching customer information.`}, 5));
        }            
    }
  };

  const handleInputChange = (e) => {
    if (isReadMode) return false;
    const { name, value } = e.target;
    if ('supplierType' === name) {
      handleSupplierType(value, null);
      setFormData((prevformData) => ({
        ...prevformData,
        supplierId: ''
      }));      
    }    
    setFormData((prevformData) => ({
      ...prevformData,
      [name]: value
    }));
  };

  const handleSupplierType = (value, loadedSuppliers) => {
    updateSuppliers(null);
    const suppliers = loadedSuppliers ? loadedSuppliers : allSuppliers;
    if (suppliers) {
      let filteredSuppliers = null;
      if ('Direct' === value) {          
        filteredSuppliers = suppliers.filter((supplier) => { return supplier.name !== Constants.SUPPLIER_DISTRIBUTOR_NAME_VIP_TRADING; });
      } else if ('Distributor' === value) {
        filteredSuppliers = suppliers.filter((supplier) => { return supplier.name === Constants.SUPPLIER_DISTRIBUTOR_NAME_VIP_TRADING; });
      }
      updateSuppliers(filteredSuppliers);
    }
  };

  const updateSuppliers = (data) => {
    setNomenclaturesData((prevData) => ({
      ...prevData,
      suppliers: data
    }));
  }

  const handleSubmit = async (e) => {   
    if (isReadMode) return false; 
    e.preventDefault();
    clearGenericMessages();        
    try {
        formData.customerType.id = formData.customerTypeId;
        formData.businessCategory.id = formData.businessCategoryId;
        formData.country.id = formData.countryId;
        formData.supplier.id = formData.supplierId;
        formData.storePlacement.id = formData.storePlacementId;
        formData.onlineRatio.id = formData.onlineRatioId;
        if (formData?.contactPersonEmail && !isEmail(formData.contactPersonEmail)) {
          addGenericMessage(Utils.buildErrorMessage({message: 'Please enter valid "Contact Person" email.'}, 10));
          contactEmailRef.current.focus();
          scrollToTop();
          return;
        }
        if ((formData?.contactPersonName && !formData?.contactPersonEmail)
          || (formData?.contactPersonEmail && !formData?.contactPersonName)
          || ((formData?.contactPersonPhone || formData?.contactPersonAddress) 
          && (!formData?.contactPersonName || !formData?.contactPersonEmail))) {
            addGenericMessage(Utils.buildErrorMessage({message: 'Please populate both fields "Name" and "Email" for "Contact Person".'}, 10));
            if (!formData?.contactPersonEmail) {
              contactEmailRef.current.focus();
            } else {
              contactNameRef.current.focus();
            }
            scrollToTop();
            return;
        }       
        formData.contactPerson = {};
        formData.contactPerson.name = formData.contactPersonName;
        formData.contactPerson.email = formData.contactPersonEmail;
        formData.contactPerson.phone = formData.contactPersonPhone;
        formData.contactPerson.address = formData.contactPersonAddress;
        showLoader();
        const response = await CustomerService.addEditCustomer(customerId, formData, await Utils.getToken());
        hideLoader();
        addGenericMessage(Utils.buildMessage(response, 10));
        if (response?.statusCode === 200) {
          navigate(Constants.CUSTOMERS_PAGE_URL);
        }
        scrollToTop();    
    } catch (error) {
      console.error('Error add/edit customer:', error);
      hideLoader();
      if (Utils.isNetworkError(error)) {
        addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
        UserService.logout(); 
        navigate(Constants.LOGIN_PAGE_URL);
        return;
      }      
      if (!UserService.handleSessionTimeOutError(error)) {
				addGenericMessage(Utils.buildErrorMessage({message: 'Error add/edd customer!'}));
        scrollToTop();
			}
    }
  };

  const handleCancel = async (e) => {
    clearGenericMessages();
    navigate(Constants.CUSTOMERS_PAGE_URL);
  };

  const handleEdit = async (e) => {
    clearGenericMessages();
    setReadMode(false);
    navigate(Constants.CUSTOMERS_PAGE_URL + Constants.UPDATE_URL + customerId);
  };
  
  const showLoader = () => {
    $('#customerLoader').show();
    $('#customerFader').show();
  };

  const hideLoader = () => {
    $('#customerLoader').hide();
    $('#customerFader').hide();
  };

  const handleMouseOver = (id) => {        
    $(`#field${id}Icon`).css('color', '#2c95ff');
    if (sections.includes(id)) {
      $(`#field${id}Icon`).css('color', '#fff');
      $(`#field${id}IconChevron`).css('color', '#fff');
    }  
  };

  const handleMouseOut = (id) => {
    $(`#field${id}Icon`).css('color', '#999');
    if (sections.includes(id)) {
      $(`#field${id}IconChevron`).css('color', '#2c95ff');      
      const open = isChevronOpen(id);  
      if (open) {
        $('#field' + id + 'Icon').css('color', '#fff');
        $(`#field${id}IconChevron`).css('color', '#fff');
      }
    }  
  };

  const toggleSection = (name) => {
    $(`#${name}SectionContents`).slideToggle();
    const open = isChevronOpen(name);  
    console.log(`open=${open}`);
    if (open) {
      $(`#${name}SectionLabel`).attr('class', 'form-fields-section-label');
      $(`#field${name}IconChevron`).css('color', '#fff');
      $(`#field${name}IconChevron`).css('transform', 'rotate(180deg)');      
    } else {
      $(`#${name}SectionLabel`).attr('class', 'form-fields-section-label form-fields-section-label-active');
      $(`#field${name}IconChevron`).css('color', '#fff');
      $(`#field${name}IconChevron`).css('transform', '');
    }            
    setChevron(name, !open);  
  };
 
  const isChevronOpen = (name) => {
    switch (name) {
      case 'General':
        return chevronIcon.general;
      case 'CustomerAddress':
        return chevronIcon.customerAddress;
      case 'SaleInfo':
        return chevronIcon.saleInfo;
      case 'Reporting':
        return chevronIcon.reporting;
      case 'ContactPerson':
        return chevronIcon.contactPerson;
      case 'Workflow':
        return chevronIcon.workflow;
      case 'Metadata':
        return chevronIcon.metadata;
      default:
        return true;
    }  
  };

  const setChevron = (name, status) => {
    switch (name) {
      case 'General':
        chevronIcon.general = status;
        break;
      case 'CustomerAddress':
        chevronIcon.customerAddress = status;
        break;
      case 'SaleInfo':
        chevronIcon.saleInfo = status;
        break;
      case 'Reporting':
        chevronIcon.reporting = status;
        break;
      case 'ContactPerson':
        chevronIcon.contactPerson = status;
        break;
      case 'Workflow':
        chevronIcon.workflow = status;
        break;  
      case 'Metadata':
        chevronIcon.metadata = status;
        break;
      default:
        break;
    }
    setChevronIcon(chevronIcon);  
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    }); 
  };

  return (
    <div>
        <div id="CustomerPageContainer" className='form-page-container-l customer'>   
            <div className='form-body without-side'>
                <div className="row">
                    <div className="img-holder">
                        <div className="bg"></div>
                        <div className="info-holder">
                            <img src="/images/login_bg_graphic.svg" alt="" />
                        </div>
                    </div>
                    <div className="form-holder">
                        <div className="form-content">
                            <div className="form-items">
                                <div className="form-icon"> 
                                    <div className="icon-holder">
                                        <FontAwesomeIcon icon={faInstitution} />
                                    </div>
                                </div>
                                <h3 className="form-title-center form-title-center-sections">{customerId ? (isReadMode ? 'Preview' : 'Edit') : 'Add'}  Customer</h3>
                                <div className='form-body-fields-wrapper'>
                                  <form onSubmit={handleSubmit}>
                                    <div id='customerLoader' className='component-loader' style={{display: 'none'}}></div>
                                    <div id='customerFader' className='component-fader' style={{display: 'none'}}></div>
                                    <div className='form-body-fields form-body-2columns'>
                                      
                                      {/* <!-- GENERAL --> */}
                                      <div className='form-body-section'>
                                        <div id="GeneralSectionLabel" className='form-fields-section-label form-fields-section-label-active' onClick={() => { toggleSection('General') }}  onMouseOver={() => handleMouseOver('General')} onMouseOut={() => handleMouseOut('General')}><strong><FontAwesomeIcon id="fieldGeneralIcon" icon={faInfoCircle} />General </strong><span className='section-chevron-icon'><FontAwesomeIcon id="fieldGeneralIconChevron" icon={faChevronDown} /></span></div>
                                        <div id="GeneralSectionContents" className='form-fields-section-contents'>
                                          <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Name')} onMouseOut={() => handleMouseOut('Name')}><FontAwesomeIcon id="fieldNameIcon" icon={faIdCard} />Name / Company *:<br/><div className='field-value'><input ref={inputRef} className="form-control" type="text" name="name" value={formData?.name || ''} onChange={handleInputChange} required readOnly={isReadMode}/></div></div>
                                          </div>
                                          <div className='form-body-col2'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('VAT')} onMouseOut={() => handleMouseOut('VAT')}><FontAwesomeIcon id="fieldVATIcon" icon={faBook} />VAT :<br/><div className='field-value'><input className="form-control" type="text" name="vat" value={formData?.vat || ''} onChange={handleInputChange}/></div></div>
                                          </div>
                                          <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('CustomerType')} onMouseOut={() => handleMouseOut('CustomerType')}><FontAwesomeIcon id="fieldCustomerTypeIcon" icon={faTag} />Type *:<br/>
                                              <div className='field-value'>
                                                <select name="customerTypeId" className='form-control' value={formData?.customerTypeId || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.customerTypes && nomenclaturesData.customerTypes.map((type) => (
                                                    <option key={type.id} value={type.id}>{type.name}</option>
                                                  ))}                                                 
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='form-body-col2'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('BusinessCategory')} onMouseOut={() => handleMouseOut('BusinessCategory')}><FontAwesomeIcon id="fieldBusinessCategoryIcon" icon={faTag} />Business Category *:<br/>
                                              <div className='field-value'>
                                                <select name="businessCategoryId" className='form-control' value={formData?.businessCategoryId || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.businessCategories && nomenclaturesData.businessCategories.map((category) => (
                                                    <option key={category.id} value={category.id}>{category.name}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {/* <!-- CUSTOMER ADDRESS --> */}
                                      <div className='form-body-section'>
                                        <div id="CustomerAddressSectionLabel" className='form-fields-section-label form-fields-section-label-active' onClick={() => { toggleSection('CustomerAddress') }}  onMouseOver={() => handleMouseOver('CustomerAddress')} onMouseOut={() => handleMouseOut('CustomerAddress')}><strong><FontAwesomeIcon id="fieldCustomerAddressIcon" icon={faHouse} />Customer Address </strong><span className='section-chevron-icon'><FontAwesomeIcon id="fieldCustomerAddressIconChevron" icon={faChevronDown} /></span></div>
                                        <div id="CustomerAddressSectionContents" className='form-fields-section-contents'>
                                          <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Country')} onMouseOut={() => handleMouseOut('Country')}><FontAwesomeIcon id="fieldCountryIcon" icon={faGlobe} />Country *:<br/>
                                              <div className='field-value'>
                                                <select name="countryId" className='form-control' value={formData?.countryId || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.countries && nomenclaturesData.countries.map((country) => (
                                                    <option key={country.id} value={country.id}>{country.title}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='form-body-col2'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('City')} onMouseOut={() => handleMouseOut('City')}><FontAwesomeIcon id="fieldCityIcon" icon={faBuilding} />City :<br/><div className='field-value'><input className="form-control" type="text" name="city" value={formData?.city || ''} onChange={handleInputChange}/></div></div>
                                          </div>
                                          <div className='form-body'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Address')} onMouseOut={() => handleMouseOut('Address')}><FontAwesomeIcon id="fieldAddressIcon" icon={faHouse} />Address:<br/><div className='field-value'><textarea className="form-control" name="address" value={formData?.address || ''} onChange={handleInputChange} style={{minHeight: '62px'}}/></div></div>
                                          </div>                                          
                                        </div>
                                      </div>      

                                      {/* <!-- SALE INFO --> */}
                                      <div className='form-body-section'>
                                        <div id="SaleInfoSectionLabel" className='form-fields-section-label form-fields-section-label-active' onClick={() => { toggleSection('SaleInfo') }}  onMouseOver={() => handleMouseOver('SaleInfo')} onMouseOut={() => handleMouseOut('SaleInfo')}><strong><FontAwesomeIcon id="fieldSaleInfoIcon" icon={faScaleBalanced} />Sale Information </strong><span className='section-chevron-icon'><FontAwesomeIcon id="fieldSaleInfoIconChevron" icon={faChevronDown} /></span></div>
                                        <div id="SaleInfoSectionContents" className='form-fields-section-contents'>
                                          <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('SupplierType')} onMouseOut={() => handleMouseOut('SupplierType')}><FontAwesomeIcon id="fieldSupplierTypeIcon" icon={faSitemap} />Supplier Type *:<br/>
                                              <div className='field-value'>
                                                <select name="supplierType" className='form-control' value={formData?.supplierType || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.supplierTypes && nomenclaturesData.supplierTypes.map((supplierType) => (
                                                    <option key={supplierType} value={supplierType}>{supplierType}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='form-body-col2'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Supplier')} onMouseOut={() => handleMouseOut('Supplier')}><FontAwesomeIcon id="fieldSupplierIcon" icon={faInstitution} />Supplier *:<br/>
                                              <div className='field-value'>
                                                <select name="supplierId" className='form-control' value={formData.supplierId || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.suppliers && nomenclaturesData.suppliers.map((supplier) => (
                                                    <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('SalesMethod')} onMouseOut={() => handleMouseOut('SalesMethod')}><FontAwesomeIcon id="fieldSalesMethodIcon" icon={faBalanceScale} />Sales Method *:<br/>
                                              <div className='field-value'>
                                                <select name="salesMethod" className='form-control' value={formData.salesMethod || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.salesMethods && nomenclaturesData.salesMethods.map((salesMethod) => (
                                                    <option key={salesMethod} value={salesMethod}>{salesMethod}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='form-body-col2'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('PaymentMethod')} onMouseOut={() => handleMouseOut('PaymentMethod')}><FontAwesomeIcon id="fieldPaymentMethodIcon" icon={faDollar} />Payment Method *:<br/>
                                              <div className='field-value'>
                                                <select name="paymentMethod" className='form-control' value={formData.paymentMethod || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.paymentMethods && nomenclaturesData.paymentMethods.map((paymentMethod) => (
                                                    <option key={paymentMethod} value={paymentMethod}>{paymentMethod}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('StorePlacement')} onMouseOut={() => handleMouseOut('StorePlacement')}><FontAwesomeIcon id="fieldStorePlacementIcon" icon={faGlobe} />Store Placement *:<br/>
                                              <div className='field-value'>
                                                <select name="storePlacementId" className='form-control' value={formData.storePlacementId || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.storePlacements && nomenclaturesData.storePlacements.map((storePlacement) => (
                                                    <option key={storePlacement.id} value={storePlacement.id}>{storePlacement.name}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='form-body-col2'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('OnlineRatio')} onMouseOut={() => handleMouseOut('OnlineRatio')}><FontAwesomeIcon id="fieldOnlineRatioIcon" icon={faPercent} />Online Ratio *:<br/>
                                              <div className='field-value'>
                                                <select name="onlineRatioId" className='form-control' value={formData.onlineRatioId || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.onlineRatios && nomenclaturesData.onlineRatios.map((onlineRatio) => (
                                                    <option key={onlineRatio.id} value={onlineRatio.id}>{onlineRatio.name}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          
                                          <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Discount')} onMouseOut={() => handleMouseOut('Discount')}><FontAwesomeIcon id="fieldDiscountIcon" icon={faPercent} />Discount :<br/><div className='field-value'><input className="form-control" type="text" name="discount" value={formData?.discount || ''} onChange={handleInputChange}/></div></div>                                            
                                          </div>
                                          <div className='form-body-col2'> 
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('CreditLimitCurrency')} onMouseOut={() => handleMouseOut('CreditLimitCurrency')}><FontAwesomeIcon id="fieldCreditLimitCurrencyIcon" icon={faDollar} />Credit Limit Currency :<br/>
                                              <div className='field-value'>
                                                <select name="creditLimitCurrency" className='form-control' value={formData.creditLimitCurrency || ''} onChange={handleInputChange}>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.creditLimitCurrencies && nomenclaturesData.creditLimitCurrencies.map((creditLimitCurrency) => (
                                                    <option key={creditLimitCurrency} value={creditLimitCurrency}>{creditLimitCurrency}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>                                           
                                          <div className='form-body'>                                            
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('BankAccount')} onMouseOut={() => handleMouseOut('BankAccount')}><FontAwesomeIcon id="fieldBankAccountIcon" icon={faInstitution} />Bank Account:<br/><div className='field-value'><input className="form-control" type="text" name="bankAccount" value={formData?.bankAccount || ''} onChange={handleInputChange} maxLength={100}/></div></div>
                                          </div>                                          
                                        </div>
                                      </div>      

                                      {/* <!-- REPORTING --> */}
                                      <div className='form-body-section'>
                                        <div id="ReportingSectionLabel" className='form-fields-section-label form-fields-section-label-active' onClick={() => { toggleSection('Reporting') }}  onMouseOver={() => handleMouseOver('Reporting')} onMouseOut={() => handleMouseOut('Reporting')}><strong><FontAwesomeIcon id="fieldReportingIcon" icon={faChartBar} />Reporting </strong><span className='section-chevron-icon'><FontAwesomeIcon id="fieldReportingIconChevron" icon={faChevronDown} /></span></div>
                                        <div id="ReportingSectionContents" className='form-fields-section-contents'>
                                          <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('SellOutReporting')} onMouseOut={() => handleMouseOut('SellOutReporting')}><FontAwesomeIcon id="fieldSellOutReportingIcon" icon={faBalanceScale} />Sell Out Reporting *:<br/>
                                              <div className='field-value'>
                                                <select name="sellOutReportingFrequency" className='form-control' value={formData.sellOutReportingFrequency || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.sellOutReportingFrequencies && nomenclaturesData.sellOutReportingFrequencies.map((sellOutReportingFrequency) => (
                                                    <option key={sellOutReportingFrequency} value={sellOutReportingFrequency}>{sellOutReportingFrequency}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='form-body-col2'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('InventoryReporting')} onMouseOut={() => handleMouseOut('InventoryReporting')}><FontAwesomeIcon id="fieldInventoryReportingIcon" icon={faListAlt} />Inventory Reporting *:<br/>
                                              <div className='field-value'>
                                                <select name="inventoryReportingFrequency" className='form-control' value={formData.inventoryReportingFrequency || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.inventoryReportingFrequencies && nomenclaturesData.inventoryReportingFrequencies.map((inventoryReportingFrequency) => (
                                                    <option key={inventoryReportingFrequency} value={inventoryReportingFrequency}>{inventoryReportingFrequency}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('ForcastSubmission')} onMouseOut={() => handleMouseOut('ForcastSubmission')}><FontAwesomeIcon id="fieldForcastSubmissionIcon" icon={faLineChart} />Forcast Submission *:<br/>
                                              <div className='field-value'>
                                                <select name="forcastSubmission" className='form-control' value={formData.forcastSubmission || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.forcastSubmissions && nomenclaturesData.forcastSubmissions.map((forcastSubmission) => (
                                                    <option key={forcastSubmission} value={forcastSubmission}>{forcastSubmission}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='form-body-col2'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('ReportingInterface')} onMouseOut={() => handleMouseOut('ReportingInterface')}><FontAwesomeIcon id="fieldReportingInterfaceIcon" icon={faCompress} />Reporting Interface*:<br/>
                                              <div className='field-value'>
                                                <select name="reportingInterface" className='form-control' value={formData.reportingInterface || ''} onChange={handleInputChange} required>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.reportingInterfaces && nomenclaturesData.reportingInterfaces.map((reportingInterface) => (
                                                    <option key={reportingInterface} value={reportingInterface}>{reportingInterface}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>                                       
                                        </div>
                                      </div> 

                                      {/* <!-- CONTACT PERSON --> */}
                                      <div className='form-body-section'>
                                        <div id="ContactPersonSectionLabel" className='form-fields-section-label form-fields-section-label-active' onClick={() => { toggleSection('ContactPerson') }}  onMouseOver={() => handleMouseOver('ContactPerson')} onMouseOut={() => handleMouseOut('ContactPerson')}><strong><FontAwesomeIcon id="fieldContactPersonIcon" icon={faUser} />Contact Person </strong><span className='section-chevron-icon'><FontAwesomeIcon id="fieldContactPersonIconChevron" icon={faChevronDown} /></span></div>
                                        <div id="ContactPersonSectionContents" className='form-fields-section-contents'>
                                          <div className='form-body'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('ContactPersonName')} onMouseOut={() => handleMouseOut('ContactPersonName')}><FontAwesomeIcon id="fieldContactPersonNameIcon" icon={faIdCard} />Name:<br/><div className='field-value'><input ref={contactNameRef} className="form-control" type="text" name="contactPersonName" value={formData?.contactPersonName || ''} onChange={handleInputChange}/></div></div>
                                          </div>
                                          <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('ContactPersonEmail')} onMouseOut={() => handleMouseOut('ContactPersonEmail')}><FontAwesomeIcon id="fieldContactPersonEmailIcon" icon={faEnvelope} />Email:<br/><div className='field-value'><input ref={contactEmailRef} className="form-control" type="text" name="contactPersonEmail" value={formData?.contactPersonEmail || ''} onChange={handleInputChange}/></div></div>
                                          </div>
                                          <div className='form-body-col2'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('ContactPersonPhone')} onMouseOut={() => handleMouseOut('ContactPersonPhone')}><FontAwesomeIcon id="fieldContactPersonPhoneIcon" icon={faPhone} />Phone:<br/><div className='field-value'><input className="form-control" type="text" name="contactPersonPhone" value={formData?.contactPersonPhone || ''} onChange={handleInputChange}/></div></div>
                                          </div>
                                          <div className='form-body'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('ContactPersonAddress')} onMouseOut={() => handleMouseOut('ContactPersonAddress')}><FontAwesomeIcon id="fieldContactPersonAddressIcon" icon={faHouse} />Address:<br/><div className='field-value'><textarea className="form-control" name="contactPersonAddress" value={formData?.contactPersonAddress || ''} onChange={handleInputChange} style={{minHeight: '62px'}}/></div></div>
                                          </div>                                                                               
                                        </div>
                                      </div>                                       

                                      {/* <!-- Workflow --> */}
                                      { customerId &&
                                      <div className='form-body-section'>
                                        <div id="WorkflowSectionLabel" className='form-fields-section-label form-fields-section-label-active' onClick={() => { toggleSection('Workflow') }}  onMouseOver={() => handleMouseOver('Workflow')} onMouseOut={() => handleMouseOut('Workflow')}><strong><FontAwesomeIcon id="fieldWorkflowIcon" icon={faToggleOn} />Workflow </strong><span className='section-chevron-icon'><FontAwesomeIcon id="fieldWorkflowIconChevron" icon={faChevronDown} /></span></div>
                                        <div id="WorkflowSectionContents" className='form-fields-section-contents'>
                                          <div className='form-body'>
                                            <div className='field-label field-label-no-border' onMouseOver={() => handleMouseOver('Status')} onMouseOut={() => handleMouseOut('Status')}><FontAwesomeIcon id="fieldStatusIcon" icon={faToggleOn} />Status *:<br/>
                                              <div className='field-value' style={{paddingTop: '9px'}}>
                                                <select name="status" className='form-control' value={formData.status} onChange={handleInputChange} style={{height: '37px'}}>
                                                  <option value="">-- Not selected --</option>
                                                  {nomenclaturesData?.statuses && nomenclaturesData.statuses.map((status) => (
                                                    <option key={status.id} value={status.id}>{status.name}</option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>      
                                          </div>
                                        </div>
                                      </div>  
                                      }

                                      {/* <!-- Metadata --> */}
                                      { customerId &&
                                      <div className='form-body-section'>
                                        <div id="MetadataSectionLabel" className='form-fields-section-label' onClick={() => { toggleSection('Metadata') }}  onMouseOver={() => handleMouseOver('Metadata')} onMouseOut={() => handleMouseOut('Metadata')}><strong><FontAwesomeIcon id="fieldMetadataIcon" icon={faInfoCircle} />Metadata </strong><span className='section-chevron-icon'><FontAwesomeIcon id="fieldMetadataIconChevron" icon={faChevronDown} style={{transform: 'rotate(180deg)'}} /></span></div>
                                        <div id="MetadataSectionContents" className='form-fields-section-contents' style={{display: 'none'}}>
                                          <div className='form-body'>
                                            <div onMouseOver={(e) => handleMouseOver('RecordId')} onMouseOut={(e) => handleMouseOut('RecordId')} style={{paddingBottom: '10px'}}><FontAwesomeIcon id="fieldRecordIdIcon" icon={faIdBadge} />Record Id: <br/><div className=''>{formData?.id}</div></div>
                                            <div onMouseOver={(e) => handleMouseOver('CreationDate')} onMouseOut={(e) => handleMouseOut('CreationDate')} style={{paddingBottom: '10px'}}><FontAwesomeIcon id="fieldCreationDateIcon" icon={faClock} />Creation Date: <br/><div className='field-value-italic'>{Utils.formatDBDate(formData?.createdAt)}</div></div>
                                            <div onMouseOver={(e) => handleMouseOver('LastUpdate')} onMouseOut={(e) => handleMouseOut('LastUpdate')}><FontAwesomeIcon id="fieldLastUpdateIcon" icon={faClock} />Last Update:<br/><div className='field-value-italic'>{Utils.formatDBDate(formData?.updatedAt)}</div></div>
                                          </div>
                                        </div>
                                      </div>  
                                      }
                                       
                                      { !isReadMode &&  
                                      <div className="form-button">
                                        <button className="ibtn ibtn-col1" type="submit">Save</button>
                                        <button className="ibtn-empty ibtn-col2" onClick={(e) => handleCancel(e)}>Back</button>
                                      </div>
                                      }
                                      { isReadMode &&   
                                      <div className="form-button">
                                          <button className="ibtn ibtn-col1" type="submit" onClick={(e) => handleEdit(e)}>Edit</button>
                                          <button className="ibtn-empty ibtn-col2" onClick={(e) => handleCancel(e)}>Back</button>
                                      </div>
                                      }
                                    </div>
                                  </form>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default CustomerPage;