import React, {useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import Utils from '../../../utils/Utils';

function ProductsPage() {

    useEffect(() => {     
        Utils.setActiveNav('headerLinkProducts');  
    }, []);
   
    return (
        <div>
            <div id="loginPageContainer" className='login-page-container'>
                <div className='form-body without-side'>
                    <div className="row">
                        <div className="img-holder">
                            <div className="bg"></div>
                            <div className="info-holder">
                                <img src="/images/login_bg_graphic.svg" alt="" />
                            </div>
                        </div>
                        <div className="form-holder">
                            <div className="form-content">
                                <div className="form-items">
                                    <div className="form-icon"> 
                                        <div className="icon-holder">
                                            <FontAwesomeIcon icon={faWrench} />
                                        </div>
                                    </div>
                                    <h3 className="form-title-center">We are sorry, but this page is under construction.<br/><br/>Please use the main navigation to work with the other pages.</h3>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductsPage;