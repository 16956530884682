import React, { useRef, useState, useEffect } from 'react';
import PasswordChecklist from "react-password-checklist"
import $ from 'jquery';
import isEmail from 'validator/es/lib/isEmail';
import { useNavigate, useParams } from 'react-router-dom';
import UserService from '../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faKey, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Utils from '../../utils/Utils';
import * as Constants from '../../../constants';
import { useGenericMessages } from '../../../contexts/messages/genericMessageContext';

function ForgottenPasswordChangePassword() {
  const { addGenericMessage, clearGenericMessages } = useGenericMessages();
  const [userInfo, setUserInfo] = useState(null);	
  const [password, setPassword] = useState('');
	const [passwordAgain, setPasswordAgain] = useState('');
  const [validPasswords, setValidPasswords] = useState(false);
  const [passwordType, setPasswordType] = useState({
    new: 'password',
    confirm: 'password'
  }); 
  const [passwordIcon, setPasswordIcon] = useState({
    new: faEyeSlash,
    confirm: faEyeSlash
  }); 
  const navigate = useNavigate();
  const { code } = useParams();
  const inputRef = useRef(null);
  const newPasswordRef = useRef();  
  const [formData, setFormData] = useState({
    code: '',
    email: '',
    newPassword: '',
    confirmPassword: ''
  });

  useEffect(() => {
    if (!code) {
      clearGenericMessages();
      addGenericMessage(Utils.buildErrorMessage({message: 'No code data found.'}, 10));      
      navigate(Constants.LOGIN_PAGE_URL);
      return;
    }
    const fetchUserInfo = async () => {
      await fetchUserData();
      inputRef.current.focus();
    };
    fetchUserInfo();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]); 

  const fetchUserData = async () => {    
    try {
      const response = await UserService.getUserByForgottenPasswordCode(code);
      hideLoader();
      if (response?.statusCode === 200) {
        setUserInfo(response);            
      } else {
        clearGenericMessages();
        addGenericMessage(Utils.buildMessage(response, 10));   
        navigate(Constants.LOGIN_PAGE_URL);
        return;   
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      hideLoader();
      clearGenericMessages();
      if (Utils.isNetworkError(error)) {
          addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
          navigate(Constants.LOGIN_PAGE_URL);
          return;
      }
      addGenericMessage(Utils.buildErrorMessage({message: 'Error fetching code data.'}, 10));
      navigate(Constants.LOGIN_PAGE_URL);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'newPassword') {
      setPassword(e.target.value);
    } else if (name === 'confirmPassword') {
      setPasswordAgain(e.target.value);
    }
    setFormData((prevformData) => ({
      ...prevformData,
      [name]: value
    }));
  };

  const clearForm = () => {
    // Clear the form fields after successful save.
    setFormData({
      code: '',      
      email: formData.email,
      newPassword: '',
      confirmPassword: ''
    });
    setPassword('');
    setPasswordAgain('');
  }

  const handleSubmit = async (e) => {        
    e.preventDefault();
    clearGenericMessages();  
    if (!isEmail(formData.email)) {
      addGenericMessage(Utils.buildErrorMessage({message: 'Please enter valid email.'}, 10));
      inputRef.current.focus();
      inputRef.current.style.border = '1px solid #cc0000';
      scrollToTop();
      return;
    }    
    if (!validPasswords) {         
      addGenericMessage(Utils.buildErrorMessage({message: 'Please enter passwords that match security criterias.'}, 10));      
      return;
    }  
    if (formData?.newPassword !== formData?.confirmPassword) {
      addGenericMessage(Utils.buildErrorMessage({message: '"New Password" and "Confirm Password" should match.'}, 10));
      return;
    }
    if (formData?.email !== userInfo.email) {
      addGenericMessage(Utils.buildErrorMessage({message: 'The given email does not match the one entered in the change password request.<br/>Please update the email.'}, 15));
      return;
    }
    formData.code = userInfo.code;
    showLoader();
    try {
        const response = await UserService.forgottenPasswordChangePassword(formData);
        if (response?.statusCode === 200) {
          addGenericMessage(Utils.buildMessage(response, 10));
          navigate(Constants.LOGIN_PAGE_URL);        
          return;
        }
        clearForm();        
        hideLoader();
        addGenericMessage(Utils.buildMessage(response, 10));
        scrollToTop();
    } catch (error) {
      console.error('Error changing password:', error);
      hideLoader();
      clearForm();
      if (Utils.isNetworkError(error)) {
        addGenericMessage(Utils.buildErrorMessage({message: 'No server connection.'}, 5));
        UserService.logout(); 
        navigate(Constants.LOGIN_PAGE_URL);
        return;
      }      
      addGenericMessage(Utils.buildErrorMessage({message: 'Error changing password!'}));
      scrollToTop();
    }
  };

  const showLoader = () => {
      $('#profileComponentLoader').show();
      $('#profileComponentFader').show();
  };

  const hideLoader = () => {
      $('#profileComponentLoader').hide();
      $('#profileComponentFader').hide();
  };

  const handleMouseOver = (e, id) => {        
    $(`#field${id}Icon`).css('color', '#2c95ff');
  };
  const handleMouseOut = (e, id) => {
      $(`#field${id}Icon`).css('color', '#999');
  };
  
  const handleCancel = (e) => {
      navigate(Constants.LOGIN_PAGE_URL);
  };

  const handleToggle = (id) => {
    switch (id) {
      case 'new':
        updatePasswordField(id, passwordType.new === 'password');
        break;
      case 'confirm':
        updatePasswordField(id, passwordType.confirm === 'password');
        break;
      default:
        break;
    }
  };

  const updatePasswordField = (id, show = false) => {
    if (show) {
      setPasswordType((prevPassData) => ({
        ...prevPassData,
        [id]: 'text'
      }));
      setPasswordIcon((prevPassData) => ({
        ...prevPassData,
        [id]: faEye
      }));  
    } else {
      setPasswordType((prevPassData) => ({
        ...prevPassData,
        [id]: 'password'
      }));
      setPasswordIcon((prevPassData) => ({
        ...prevPassData,
        [id]: faEyeSlash
      })); 
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    }); 
  }

  return code && (
    <div>
        <div id="forgottenPasswordChangePasswordPageContainer" className='form-page-container-l change-password-page-container'>   
            <div className='form-body without-side'>
                <div className="row">
                    <div className="img-holder">
                        <div className="bg"></div>
                        <div className="info-holder">
                            <img src="/images/login_bg_graphic.svg" alt="" />
                        </div>
                    </div>
                    <div className="form-holder">
                        <div className="form-content">
                            <div className="form-items">
                                <div className="form-icon"> 
                                    <div className="icon-holder">
                                        <FontAwesomeIcon icon={faLock} />
                                    </div>
                                </div>
                                <h3 className="form-title-center">Change Your Password</h3>
                                <div className='form-body-fields-wrapper'>
                                  <form onSubmit={handleSubmit}>
                                    <div id='profileComponentLoader' className='component-loader'></div>
                                    <div id='profileComponentFader' className='component-fader'></div>
                                    <div className='form-body-fields'>
                                        <div className='form-body-col1'>
                                            <div className='field-label field-label-no-border' onMouseOver={(e) => handleMouseOver(e, 'Email')} onMouseOut={(e) => handleMouseOut(e, 'Email')}><FontAwesomeIcon id="fieldEmailIcon" icon={faEnvelope} />Your email *:<br/><div className='field-value'><input ref={inputRef} className="form-control" type="text" name="email" value={formData?.email || ''} onChange={handleInputChange} required placeholder='Enter your email' autoComplete="current-email"/></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={(e) => handleMouseOver(e, 'NewPassword')} onMouseOut={(e) => handleMouseOut(e, 'NewPassword')}><FontAwesomeIcon id="fieldNewPasswordIcon" icon={faKey} />New Password *:<br/><div className='field-value'><input ref={newPasswordRef} className="form-control" type={passwordType.new || ''} name="newPassword" value={formData?.newPassword} onChange={handleInputChange} required placeholder='Enter your new password' autoComplete="new-password"/><span className="field-password-eye" onClick={(e) => handleToggle('new')}><FontAwesomeIcon id="fieldNewPasswordEyeIcon" icon={passwordIcon.new} /></span></div></div>
                                            <div className='field-label field-label-no-border' onMouseOver={(e) => handleMouseOver(e, 'ConfirmPassword')} onMouseOut={(e) => handleMouseOut(e, 'ConfirmPassword')}><FontAwesomeIcon id="fieldConfirmPasswordIcon" icon={faKey} />Confirm New Password *:<br/><div className='field-value'><input className="form-control" type={passwordType.confirm || ''} name="confirmPassword" value={formData?.confirmPassword} onChange={handleInputChange} required placeholder='Enter your new password' autoComplete="new-password"/><span className="field-password-eye" onClick={(e) => handleToggle('confirm')}><FontAwesomeIcon id="fieldConfirmPasswordEyeIcon" icon={passwordIcon.confirm} /></span></div></div>                                            
                                            { password &&
                                            <PasswordChecklist
                                              rules={["minLength","specialChar","number","capital","match"]}
                                              minLength={8}
                                              value={password}
                                              valueAgain={passwordAgain}
                                              invalidColor='#FF3333'                                              
                                              onChange={(isValid) => {setValidPasswords(isValid)}}
                                            />
                                              }
                                        </div>
                                        <div className="form-button">
                                            <button className="ibtn ibtn-col1" type="submit">Change</button>
                                            <button className="ibtn-empty ibtn-col2" onClick={(e) => handleCancel(e)}>Home</button>
                                        </div>
                                    </div>
                                  </form>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ForgottenPasswordChangePassword;