import { createContext, useContext, useRef, useState } from "react";
import { GenericMessage, GenericMessagesWrapper } from "./genericMessage";
import Utils from "../../components/utils/Utils";
import { AppContext } from "../appContext";

const GenericMessagesContext = createContext();
const GenericMessagesProvider = ({ children }) => {
  const [genericMessages, setGenericMessages] = useState([]);
  const { globalState } = useContext(AppContext);

  const addGenericMessage = (genericMessage) => {
    const id = Math.random().toString(36).slice(2, 9) + new Date().getTime().toString(36);
    setGenericMessages((prev) => [{ ...genericMessage, id: id }, ...prev]);
    return id;
  }

  const dismissGenericMessage = (id) => {
    try {
      const root = document.getElementById('messageBlock');
      const element = document.getElementsByClassName('message-block');
      
      if (root && element?.length > 0 && !globalState?.genericMessages?.forceClose) {        
        root.style.maxHeight = 0;
        element[0].style.opacity = 0;
        setTimeout(() => setGenericMessages((prev) => prev.filter((genericMessage) => genericMessage.id !== id)), 500);
        return;
      }      
      setGenericMessages((prev) => prev.filter((genericMessage) => genericMessage.id !== id));  
    } catch (error) {}    
  }

  return (
    <GenericMessagesContext.Provider value={{ genericMessages, addGenericMessage, dismissGenericMessage }}>
      <GenericMessagesWrapper>
      {
      genericMessages?.length && genericMessages.map((genericMessage, index) => (
          <GenericMessage key={genericMessage.id} {...genericMessage} count={index} handleDismiss={() => { dismissGenericMessage(genericMessage.id) }} />
        ))}
      </GenericMessagesWrapper>
      {children}
    </GenericMessagesContext.Provider>
  )
}

export const useGenericMessages = () => {
  const { globalState } = useContext(AppContext);
  const [genericMessageIds, setGenericMessageIds] = useState([]);
  const genericMessageIdsRef = useRef(genericMessageIds);
  const { addGenericMessage, dismissGenericMessage } = useContext(GenericMessagesContext);

  const addGenericMessageWithId = (genericMessage) => {
    const id = addGenericMessage(genericMessage);
    genericMessageIdsRef.current.push(id);
    setGenericMessageIds(genericMessageIdsRef.current);
  }

  const clearGenericMessages = () => {    
    globalState.genericMessages.forceClose = true;
    genericMessageIdsRef.current.forEach((id) => dismissGenericMessage(id));
    genericMessageIdsRef.current = [];
    setGenericMessageIds([]);
    Utils.clearGenericMessages();
    globalState.genericMessages.forceClose = false;
  }

  return { addGenericMessage: addGenericMessageWithId, clearGenericMessages };
}

export default GenericMessagesProvider;