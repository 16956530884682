'use client';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import * as Constants from '../../constants';
import { useGenericMessages } from '../../contexts/messages/genericMessageContext';
import DownloadListService from '../service/DownloadListService';
import Utils from '../utils/Utils';
import UserService from '../service/UserService';

function DownloadListComponent({ url, parameters, fileName }) {
    const { addGenericMessage } = useGenericMessages();
    const handleDownloadListAction = async (type) => {		
		try {  
			await DownloadListService.downloadList(
				url,
				type, 
				parameters, 
				fileName,
				await Utils.getToken()
			);
		} catch(error){
			console.error('Error fetching file:', error);
			if (!UserService.handleSessionTimeOutError(error)) {
				addGenericMessage(Utils.buildErrorMessage({message: 'Error fetching file!'}));	
			}
		};
	};

    return (        
        <div className='tabs-action-buttons download-report-button dropdown'>
            <button className="ibtn ibtn-full" alt=" Download List " title=" Download List " type='button'><FontAwesomeIcon icon={faBars} /></button>
            <div className="dropdown-content">
                <Link onClick={() => { handleDownloadListAction(Constants.FILE_TYPE_EXCEL) }} >Download Excel</Link>
                <Link onClick={() => { handleDownloadListAction(Constants.FILE_TYPE_CSV) }} >Download CSV</Link>
            </div>
        </div>
    );
};
export default DownloadListComponent;