import React, { useState, useEffect } from 'react';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/dist/themes/base/jquery-ui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5';
import {useNavigate} from "react-router-dom";
import Utils from '../../../utils/Utils';
import * as Constants from '../../../../constants';
import TabsComponent from './TabsComponent';
import UserSerice from '../../../service/UserService';
import DownloadListComponent from '../../../common/DownloadListComponent';
import { isEmpty, isInt } from 'validator';

function CountriesPage() {
	const navigate = useNavigate();
	const [filterData, setFilterData] = useState({
		filterId: '',
		filterName: '',
		filterAlpha2Code: ''
	});
	const ORDER_COLUMN = 1;
	const tabId = 5;
	const filterNS = 'nomenclatures_countries';

	useEffect(() => {		
		const dataUrl = document.getElementById('headerLinkNomenclatures')?.dataset.url;
		if (Constants.NOMENCLATURES_COUNTRIES_PAGE_URL !== dataUrl) {
			navigate(dataUrl);
			return;			
		}
		
		Utils.setActiveNav('headerLinkNomenclatures');				
		$( "#countriesTabs" ).tabs({
			active: tabId
		});			

		const dataTable = initDataTable();

		setFilterData(
		{
			filterId: Utils.getFilterValue(filterNS, 'filterId'),
			filterName: Utils.getFilterValue(filterNS, 'filterName'),
			filterAlpha2Code: Utils.getFilterValue(filterNS, 'filterAlpha2Code'),
		});
		return () => {
			try {
				dataTable.destroy();	
			} catch (error) {}			
		};
	}, [navigate]);

	const initDataTable = async () => {
		const token = await Utils.getToken();
		try {			
			if ($.fn.DataTable.isDataTable("#datatable")) {
				$('#datatable').DataTable().clear().destroy();
			}
			$.fn.dataTable.ext.errMode = 'throw';
			const dataTable = $('#datatable').DataTable({
				serverSide : true,
				processing : true,
				searching: false,
				order: [[ Utils.getDataTableValue(filterNS, 'orderColumn') ?? ORDER_COLUMN, Utils.getDataTableValue(filterNS, 'order') ??  Constants.DATATABLE_ORDER_ASC ]],
				lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
				displayStart: Utils.getDataTableValue(filterNS, 'start') ?? Constants.DATATABLE_START, 
	    		pageLength: Utils.getDataTableValue(filterNS, 'pageLength') ?? Constants.DATATABLE_PAGE_LENGTH,
				ajax : {
					url : Constants.NOMENCLATURES_COUNTRIES_LIST_URL,
					type : 'POST',
					beforeSend: function(xhr) {
						xhr.setRequestHeader("Authorization", "Bearer " + token);
					},
					statusCode: {
						403: function() { 							
							UserSerice.sessionTimeOut();
						}					
					},
					error: function(XMLHttpRequest, textStatus, errorThrown) {
						console.log(textStatus);
					},
					dataSrc: function ( json ) {
						Utils.setDataTableValue(filterNS, 'start', json.start);							
						Utils.setDataTableValue(filterNS, 'pageLength', json.pageLength);
						Utils.setDataTableValue(filterNS, 'orderColumn', json.orderColumn);
						Utils.setDataTableValue(filterNS, 'order', json.order);
						return json.data;
					},
					data: {
						filterId: Utils.getFilterValue(filterNS, 'filterId'),
						filterName: Utils.getFilterValue(filterNS, 'filterName'),
						filterAlpha2Code: Utils.getFilterValue(filterNS, 'filterAlpha2Code'),
					}
				},
				columnDefs: [
					{
						"targets": 0, //id.
						"className": "text-right"
					}, 
					{
						"targets": 1, //name.
						"className": "text-center"            
					},
					{
						"targets": 2, // alpha2Code.
						"className": "text-center"            
					}			
				]				
			});
			return dataTable;
		} catch (error) {
		  console.error('Error applying filters:', error);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		Utils.setFilterValue(filterNS, name, value);
		setFilterData((prevFilterData) => ({
			...prevFilterData,
			[name]: value
		}));			
	};

	const handleFilterSubmit = async (e) => {
		e.preventDefault();
		if (!isEmpty(filterData.filterId + '') && !isInt(filterData.filterId + '')) {
			alert('Please enter number for "№" field.');
			return;
		}
		return await initDataTable();
	};

	const prepareParametersForListDownload = () => {
		const orderColumn = Utils.getDataTableValue(filterNS, 'orderColumn') ?? ORDER_COLUMN;
		const order = Utils.getDataTableValue(filterNS, 'order') ?? Constants.DATATABLE_ORDER_ASC;
		return `orderColumn=${orderColumn}&order=${order}&filterId=${filterData.filterId ?? ''}&filterName=${filterData.filterName ?? ''}&filterAlpha2Code=${filterData.filterAlpha2Code ?? ''}`;
	};

  	return (
		<div>
			<div className="customers main-wrapper list-page">	
				<div className="dt-add-button-wrapper"> 
					<DownloadListComponent url={Constants.NOMENCLATURES_COUNTRIES_DOWNLOAD_LIST_URL} parameters={prepareParametersForListDownload()} fileName='listReport_Countries' />
				</div> 		
				<div id="countriesTabs">
					<TabsComponent />			
					<div id={'tabs-' + Constants.TAB_NOMENCLATURES_CUSTOMER_COUNTRIES}>
						<div>
							<div className="card-body">
							<form onSubmit={handleFilterSubmit}>
								<div className="search-filter">
									<div className="filter-field">№ <input name="filterId" value={filterData.filterId || ''} onChange={handleInputChange} type="text" className="filter-field-xxs"/><span/></div>
									<div className="filter-field">Name <input name="filterName" value={filterData.filterName || ''} onChange={handleInputChange} type="text" className="filter-field-xl" placeholder=''/><span/></div>																
									<div className="filter-field">Alpha2Code <input name="filterAlpha2Code" value={filterData.filterAlpha2Code || ''} onChange={handleInputChange} type="text" className="filter-field-xxs" placeholder=''/><span/></div>																
									<div className='filter-search-button'><button className="ibtn ibtn-full" type="submit">Search</button></div>
								</div>	
							</form>
								<table className="table table-bordered" id="datatable">
									<thead> 
										<tr>
											<th className="text-center">№</th>
											<th>Name</th>
											<th>Alpha2Code</th>
										</tr>
									</thead>
									<tbody></tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>	
	);
}

export default CountriesPage;