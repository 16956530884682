import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/dist/themes/base/jquery-ui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'datatables.net-bs5';
import {useNavigate} from "react-router-dom";
import Utils from '../../../utils/Utils';
import * as Constants from '../../../../constants';
import TabsComponent from './TabsComponent';
import UserService from '../../../service/UserService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { useGenericMessages } from '../../../../contexts/messages/genericMessageContext';
import { isEmpty, isInt } from 'validator';

function SystemSettingsPage() {
	const { clearGenericMessages } = useGenericMessages();
	const navigate = useNavigate();
	const [filterData, setFilterData] = useState({
		filterId: '',
		filterName: ''		
	});
	const filterNS = 'settings_system';

	useEffect(() => {		
		
		const dataUrl = document.getElementById('headerLinkSettings')?.dataset.url;
		if (Constants.SETTINGS_SYSTEM_PAGE_URL !== dataUrl) {
			navigate(dataUrl);
			return;			
		}
		
		Utils.setActiveNav('headerLinkSettings');				
		$( "#settingsSystemTabs" ).tabs({
			active: 0
		});	
		
		$.extend($.fn.dataTableExt.oSort, {
			"extract-date-pre": function(value) {
				const datetime = value.split(' ');
				const date = datetime[0].split('.');
				return Date.parse(date[1] + '/' + date[0] + '/' + date[2] + ' ' + datetime[1]);
			},
			"extract-date-asc": function(a, b) {
				return ((a < b) ? -1 : ((a > b) ? 1 : 0));
			},
			"extract-date-desc": function(a, b) {
				return ((a < b) ? 1 : ((a > b) ? -1 : 0));
			}
		});	

		const dataTable = initDataTable();

		setFilterData(
		{
			filterId: Utils.getFilterValue(filterNS, 'filterId'),
			filterName: Utils.getFilterValue(filterNS, 'filterName'),
			filterValue: Utils.getFilterValue(filterNS, 'filterValue'),
			filterDescription: Utils.getFilterValue(filterNS, 'filterDescription'),
		});
		return () => {
			try {
				dataTable.destroy();	
			} catch (error) {}			
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	const initDataTable = async () => {
		const token = await Utils.getToken();
		try {			
			if ($.fn.DataTable.isDataTable("#datatable")) {
				$('#datatable').DataTable().clear().destroy();
			}
			$.fn.dataTable.ext.errMode = 'throw';
			const dataTable = $('#datatable').DataTable({
				serverSide : true,
				processing : true,
				searching: false,
				autoWidth: false,
				order: [[ Utils.getDataTableValue(filterNS, 'orderColumn') ?? 1, Utils.getDataTableValue(filterNS, 'order') ??  Constants.DATATABLE_ORDER_ASC ]],
				lengthMenu: [[10, 25, 50, 100], [10, 25, 50, 100]],
				displayStart: Utils.getDataTableValue(filterNS, 'start') ?? Constants.DATATABLE_START, 
	    		pageLength: Utils.getDataTableValue(filterNS, 'pageLength') ?? Constants.DATATABLE_PAGE_LENGTH,
				infoCallback: function (settings, start, end, max, total, pre) {
					settings.api = this.api();
				},				
				drawCallback: function (settings) {
					//var api = this.api();
				},
				ajax : {
					url : Constants.SETTINGS_SYSTEM_LIST_URL,
					type : 'POST',
					beforeSend: function(xhr) {
						xhr.setRequestHeader("Authorization", "Bearer " + token);
					},
					statusCode: {
						403: function() { 							
							UserService.sessionTimeOut();
						}				
					},
					error: function(XMLHttpRequest, textStatus, errorThrown) {
						console.log(textStatus);
					},
					dataSrc: function ( json ) {
						Utils.setDataTableValue(filterNS, 'start', json.start);							
						Utils.setDataTableValue(filterNS, 'pageLength', json.pageLength);
						Utils.setDataTableValue(filterNS, 'orderColumn', json.orderColumn);
						Utils.setDataTableValue(filterNS, 'order', json.order);
						return json.data;
					},
					data: {
						filterId: Utils.getFilterValue(filterNS, 'filterId'),
						filterName: Utils.getFilterValue(filterNS, 'filterName'),
						filterValue: Utils.getFilterValue(filterNS, 'filterValue'),
						filterDescription: Utils.getFilterValue(filterNS, 'filterDescription'),
					}
				},				
				columnDefs: [
					{
						"targets": 0, //id.
						"className": "text-right",
						"width": "100px",
						"createdCell": (td, cellData, rowData, row, col) =>							
							createRoot(td).render(	
								<button className='table-cell-id' onClick={() => {return handleReadAction(cellData)}}>{cellData}</button>
							) 
					}, 
					{
						"targets": 1, //name.
						"className": "text-left"            
					},
					{
						"targets": 2, //value.
						"className": "text-left"            
					},
					{
						"targets": 3, //description.
						"className": "text-left"            
					},
					{
						"targets": 4, //date_created.
						"className": "text-center",
						"type": "extract-date",
						// "width": "180px",
						"render": function ( data, type, row ) { 
							return Utils.formatDBDate(data);
						}                                              
					},
					{
						"targets": 5, //date_modified.
						"className": "text-center",
						"type": "extract-date",
						// "width": "180px",
						"render": function ( data, type, row ) { 
							return Utils.formatDBDate(data);
						}                                             
					},			
					{						
							"createdCell": (td, cellData, rowData, row, col) =>							
								createRoot(td).render(	
									<div className='list-icons'>
										<FontAwesomeIcon onClick={() => {handleReadAction(cellData)}} className='circle-icon list-icon-read' icon={faStickyNote} />							
										<FontAwesomeIcon onClick={() => {handleEditAction(cellData)}} className='circle-icon list-icon-edit' icon={faPencil} />							
									</div>
								)
							,
						"className": "text-right dt-nowrap list-cell-actions",
						"targets": 6,
						"width": "180px",
						"orderable": false
					}// operations
				]				
			});
			return dataTable;
		} catch (error) {
		  console.error('Error applying filters:', error);
		  //alert(error);
		}
	}

	const handleReadAction = (id) => {
		clearGenericMessages();
		navigate(Constants.SETTINGS_SYSTEM_READ_SETTING_PAGE_URL + id);
	}
	const handleEditAction = (id) => {
		clearGenericMessages();
		navigate(Constants.SETTINGS_SYSTEM_UPDATE_SETTING_PAGE_URL + id);
	}	
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		Utils.setFilterValue(filterNS, name, value);
		setFilterData((prevFilterData) => ({
			...prevFilterData,
			[name]: value
		}));			
	};

	const handleFilterSubmit = async (e) => {
		e.preventDefault();
		if (!isEmpty(filterData.filterId + '') && !isInt(filterData.filterId + '')) {
			alert('Please enter number for "№" field.');
			return;
		}
		Utils.setDataTableValue(filterNS, 'start', Constants.DATATABLE_START);
		return await initDataTable();
	};

  	return (
		<div>			
			<div className="system-settings main-wrapper list-page">				
				<div id="settingsSystemTabs">
					<TabsComponent />
					<div id={'tabs-' + Constants.TAB_SETTINGS_SYSTEM}>
						<div>
							<div className="card-body">
							<form onSubmit={handleFilterSubmit}>
								<div className="search-filter">
									<div className="filter-field">№ <input name="filterId" value={filterData.filterId || ''} onChange={handleInputChange} type="text" className="filter-field-xxs"/><span/></div>
									<div className="filter-field">Name <input name="filterName" value={filterData.filterName || ''} onChange={handleInputChange} type="text" className="filter-field-xm" placeholder=''/><span/></div>																
									<div className="filter-field">Value <input name="filterValue" value={filterData.filterValue || ''} onChange={handleInputChange} type="text" className="filter-field-xl" placeholder=''/><span/></div>																
									<div className="filter-field">Description <input name="filterDescription" value={filterData.filterDescription || ''} onChange={handleInputChange} type="text" className="filter-field-xl" placeholder=''/><span/></div>																
									<div className='filter-search-button'><button className="ibtn ibtn-full" type="submit">Search</button></div>
								</div>	
							</form>
								<table className="table table-bordered" id="datatable">
									<thead>
										<tr>
											<th className="text-center">№</th>
											<th>Name</th>
											<th>Value</th>
											<th>Description</th>
											<th>Date Created</th>
											<th>Last Update</th>										
											<th className='list-header-actions-label'>Actions</th>
										</tr>
									</thead>
									<tbody></tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SystemSettingsPage;